import React, { Component } from "react";
import { allQuestions } from "../../questions.json";
import assignmentsService from "../../services/assignmentsService";
import questionService from "../../services/questionService";
import userService from "../../services/userService";
import authService from "../../services/authService";
import { element, number } from "prop-types";
import ViewModeAlert from "../viewModeAlert";
import Loading from "../common/loading";
import ChapterTile from "./ChapterTile.jsx";

class CourseMain extends Component {
  root = "/programming/intro";
  constructor(props) {
    super(props);
    const { course } = this.props.match.params;
    this.course = this.props.match.params.course;
    this.state = {
      showViewModeMessage: false,
      courseMetaData: null,
    };
  }

  isViewMode() {
    let user = authService.getCurrentUser();
    if (user) {
      return false;
    }
    return true;
  }

  isLoading() {
    if (this.isViewMode()) {
      if (this.state.courseMetaData) {
        return false;
      }
      return true;
    } else {
      if (this.state.courseMetaData && this.state.chapters) {
        return false;
      }
      return true;
    }
  }

  componentDidMount() {
    assignmentsService
      .getCourseMetadta(this.course)
      .then(({ data: response }) => {
        this.setState({
          courseMetaData: response,
        });
        console.log(response);
      })
      .catch((err) => {});

    if (!this.isViewMode()) {
      assignmentsService
        .getAssignments(this.course)
        .then(({ data: response }) => {
          // console.log(response)
          userService
            .getScoresForCourse(this.course)
            .then(({ data: responsePoints }) => {
              // console.log(response.assignments)
              let allchapters = response.assignments
                .map((p) => p.id.split("_")[0])
                .filter((item) => !isNaN(item));
              allchapters = [...new Set(allchapters)];
              allchapters.sort((a, b) => Number(a) - Number(b));
              let chapters = [];

              let allAssignments = response.assignments;

              allAssignments = allAssignments.map((assingment) => {
                let scoreObj = responsePoints.find(
                  (pointsItem) => pointsItem.chapter_id === assingment.id
                );
                // let scoreObj = null;
                assingment.score = scoreObj ? scoreObj.points : 0;
                console.log(assingment.score);
                return assingment;
              });

              allchapters.forEach((chapter) => {
                chapters[Number(chapter)] = response.assignments.filter(
                  (p) => p.id.split("_")[0] === chapter
                );
              });

              this.setState({
                chapters: chapters,
              });
            });
          return;
        })
        .catch((err) => {});
    }
  }

  toggleViewModeMessage = () => {
    this.setState({
      showViewModeMessage: !this.state.showViewModeMessage,
    });
  };

  onRegister = () => {
    this.props.history.push({
      pathname: "/auth",
      state: { prevPath: this.props.location.pathname },
    });
    this.setState({
      showViewModeMessage: false,
    });
  };

  render() {
    const { chapters, courseMetaData, showViewModeMessage } = this.state;
    return (
      <React.Fragment>
        <div className="content tutorial-landing">
          {!this.isLoading() && (
            <div
              className={`grid-box multiple-choice ${
                this.course === "recycling" ? "narrow-tile-container" : ""
              }`}
            >
              {!this.isViewMode()
                ? chapters &&
                  chapters.map((chapter, index) => {
                    // console.log("CHAPTERS HEEERE", chapters);
                    if (chapter)
                      return (
                        <ChapterTile
                          chapter={chapter}
                          chapterName={courseMetaData[index - 1].chapter}
                          index={index}
                          course={this.course}
                          root={this.root}
                          history={this.props.history}
                        />
                      );
                  })
                : courseMetaData &&
                  courseMetaData.map((chapter, index) => {
                    if (chapter)
                      return (
                        <ChapterTile
                          chapter={chapter}
                          chapterName={chapter.chapter}
                          index={index + 1}
                          course={this.course}
                          root={this.root}
                          history={this.props.history}
                          viewMode={true}
                          toggleViewModeMessage={this.toggleViewModeMessage.bind(
                            this
                          )}
                        />
                      );
                  })}
            </div>
          )}
          {showViewModeMessage && (
            <ViewModeAlert
              onRegister={this.onRegister}
              onCancel={this.toggleViewModeMessage}
            />
          )}
          {this.isLoading() && <Loading />}
        </div>
      </React.Fragment>
    );
  }
}

export default CourseMain;
