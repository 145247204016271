import React, { Component } from "react";
import authService from "../../services/authService";
import { Redirect, Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import HoverTooltip from "../common/hover-tooltip";
import classService from "../../services/classService";
import myStatusService from "../../services/myStatusService";
import Button from "../common/button";
import AddStudents from "./addStudents";

class ClassroomOverview extends Component {
  state = {
    classroom: {},
    classcode: this.props.match.params.code,
    user: {},
    showAlert: false,
    studentScores: [],
    userStatus: "",
    deleteError: "",
    showUsername: null,
    tooltipOpen: false,
    showDeleteConfirmation: false,
    showAISchoolSignupConfirmation: false,
    deletionConfirmationMessage: "Loading...",
    userDeletedMessage: false,
    isStudent: false,
    isCurrentSchoolYear: null,
    showAddStudent: false,
    isUpdatingOverview: false,
  };

  componentDidMount() {
    const user = authService.getCurrentUser();
    const isStudent = authService.isUserStudent();
    classService
      .getClassDetails(this.state.classcode)
      .then(({ data }) => {
        let res = data.data;
        // By default a classroom is not in the current school year
        let isCurrent = false;
        // If the classroom has a registered_on and it registered_on is in the current school year, then the classroom is current.
        // Not all classrooms have a registered_on date, since this was a feature added later on (2024-02)
        if (res.classroom.registered_on) {
          const registeredOn = new Date(res.classroom.registered_on);
          isCurrent =
            classService.getSchoolYear(new Date(Date.now())) ==
            classService.getSchoolYear(registeredOn);
          res.classroom.registered_on = registeredOn;
        }
        res.classroom.code = this.state.classcode;

        this.setState({
          user: user,
          classroom: res.classroom,
          studentScores: res.studentScores,
          isStudent,
          deletionConfirmationMessage: res.classroom.can_delete
            ? "Bist du dir sicher, dass du diesen Klassenraum inklusive Schüler:Innen löschen möchtest? Dies kann nicht rückgängig gemacht werden."
            : "Bist du dir sicher, dass du diesen Klassenraum löschen möchtest? Dies kann nicht rückgängig gemacht werden.",
          isCurrentSchoolYear: isCurrent,
        });
      })
      .catch((err) => {
        let error = "Es ist ein unerwarteter Fehler aufgetreten.";
        if (err.response && err.response.status === 400) {
          error = err.response.data.error;
        }
        this.setState({ errors: error });
      });

    myStatusService.getMyStatus().then(({ data: response }) => {
      response.sort((a, b) => a.order - b.order);
      // console.log(response);
      this.setState({
        userStatus: response,
      });
    });
  }

  toggleUsername = (idx) => {
    // console.log(idx);
    this.setState({
      showUsername: this.state.showUsername == idx ? null : idx,
    });
  };

  // Helper to copy username to clipboard
  copyUsername = (username) => {
    // console.log("Copying code "+ username);
    navigator.clipboard.writeText(username);
  };

  aiSchoolSignup = () => {
    this.setState({
      showAISchoolSignupConfirmation: true,
    });
  };

  onAISchoolSignupConfirm = () => {
    const class_code = this.state.classcode;
    const classroom = this.state.classroom;
    classService
      .signUpForAISchool(class_code)
      .then((response) => {
        console.log("Class signed up");
        classroom.is_ai_school = true;
        this.setState({ classroom: classroom });
      })
      .catch((e) => {
        console.log("delete failed ", e);
        this.setState({ deleteError: "Unable to sign up for AI School." });
      });
    this.closeAISchoolSignupConfirm();
  };

  closeAISchoolSignupConfirm = () => {
    this.setState({
      showAISchoolSignupConfirmation: false,
    });
  };

  deleteClass = () => {
    this.setState({
      showDeleteConfirmation: true,
    });
  };

  onDeleteConfirm = () => {
    const class_code = this.state.classcode;
    classService
      .deleteClass(class_code)
      .then((response) => {
        console.log("Class deleted");
        this.setState({ userDeletedMessage: true });
      })
      .catch((e) => {
        console.log("delete failed ", e);
        this.setState({ deleteError: "Unable to delete class" });
      });
  };

  closeDeleteMessage = () => {
    this.props.history.push("/classroom");
  };

  showAddStudent = () => {
    this.setState({
      showAddStudent: true,
    });
  };

  closeAddStudentAndUpdate = () => {
    this.setState({
      isUpdatingOverview: true,
    });
    classService
      .getClassDetails(this.state.classcode)
      .then(({ data }) => {
        // Fetch the updated student list
        let res = data.data;

        this.setState({
          studentScores: res.studentScores,
          showAddStudent: false,
          isUpdatingOverview: false,
        });
      })
      .catch((err) => {
        let error = "Es ist ein unerwarteter Fehler aufgetreten.";
        if (err.response && err.response.status === 400) {
          error = err.response.data.error;
        }
        this.setState({ errors: error });
      });
  };

  closeDeleteConfirmation = () => {
    this.setState({
      showDeleteConfirmation: false,
    });
  };

  render() {
    const {
      classroom,
      classcode,
      userStatus,
      errors,
      studentScores,
      showAddStudent,
      userDeletedMessage: classDeletedMessage,
      showDeleteConfirmation,
      showAISchoolSignupConfirmation,
      deletionConfirmationMessage,
      isStudent,
      isUpdatingOverview,
    } = this.state;

    if (isStudent) return <Redirect to="/not-found" />;

    return (
      <React.Fragment>
        <section className="content">
          {!errors && (
            <div className="container-fluid">
              <div className="container-limit wide-limit">
                {/* <h1 className=   */}
                <h2 className="header2 text-center">Klassenfortschritt </h2>
                <h3 className="header3 text-center">
                  {classroom.room_name} : {classcode}
                </h3>
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr className="main-header-row">
                        <th
                          scope="col"
                          className="overview-header"
                          key="filler0"
                        ></th>
                        <th
                          scope="col"
                          className="font-sub-header"
                          key="filler1"
                        ></th>
                        {userStatus &&
                          userStatus.map((statusItem) => (
                            <th
                              scope="col"
                              colSpan="2"
                              className="overview-header"
                            >
                              {statusItem.header}
                            </th>
                          ))}
                      </tr>
                      <tr className="second-header-row">
                        <th scope="col" colSpan="2" className="overview-header">
                          SchülerIn
                        </th>
                        {/* <th scope='col' className='font-sub-header'></th> */}
                        {userStatus &&
                          userStatus.map((statusItem) => (
                            <>
                              <th scope="col" className="overview-header">
                                Aufgaben
                              </th>
                              <th scope="col" className="overview-header">
                                Punkte
                              </th>
                            </>
                          ))}
                      </tr>
                      <tr className="third-header-row">
                        <th scope="col" className="overview-sub-header">
                          Name
                        </th>
                        <th scope="col" className="overview-sub-header">
                          Benutzername
                        </th>
                        {userStatus &&
                          userStatus.map((statusItem) => (
                            <>
                              <th scope="col" className="overview-sub-header ">
                                <b>{statusItem.total_tasks}</b>
                              </th>
                              <th scope="col" className="overview-sub-header ">
                                <b>{statusItem.total_possible_points}</b>
                              </th>
                            </>
                          ))}
                      </tr>
                    </thead>

                    <tbody>
                      {!studentScores && <p>Loading...</p>}
                      {studentScores &&
                        studentScores.map(
                          (
                            {
                              milestones_memory,
                              pioneer_women,
                              python,
                              mlbasics,
                              dog,
                              recycling,
                              ki_and_society,
                              ethics,
                              student,
                            },
                            idx
                          ) => (
                            <tr>
                              <th scope="row" className="font-sub-header">
                                {" "}
                                <span>{student.fullname}</span>
                              </th>
                              <th scope="row" className="overview-sub-header">
                                <div className={"copy-wrapper-small show"}>
                                  <span>{student.username}</span>{" "}
                                  <FontAwesomeIcon
                                    icon={faCopy}
                                    onClick={() =>
                                      this.copyUsername(student.username)
                                    }
                                    id={`copy-tool-${idx}`}
                                  ></FontAwesomeIcon>
                                  <HoverTooltip
                                    name={`copy-tool-${idx}`}
                                    message="Copy username"
                                  />
                                </div>
                              </th>
                              <td>{milestones_memory.attempted_tasks}</td>
                              <td>{milestones_memory.collected_points}</td>
                              <td>{pioneer_women.attempted_tasks}</td>
                              <td>{pioneer_women.collected_points}</td>
                              <td>{python.attempted_tasks}</td>
                              <td>{python.collected_points}</td>
                              <td>{mlbasics.attempted_tasks}</td>
                              <td>{mlbasics.collected_points}</td>
                              <td>{dog.attempted_tasks}</td>
                              <td>{dog.collected_points}</td>
                              <td>{recycling.attempted_tasks}</td>
                              <td>{recycling.collected_points}</td>
                              <td>{ki_and_society.attempted_tasks}</td>
                              <td>{ki_and_society.collected_points}</td>
                              <td>{ethics.attempted_tasks}</td>
                              <td>{ethics.collected_points}</td>
                            </tr>
                          )
                        )}
                    </tbody>
                  </table>
                </div>
                <div className="add-student-button-wrapper">
                  <Button
                    onClick={this.showAddStudent}
                    className="btn width-auto float-right"
                    label="Weitere Schüler hinzufügen"
                  ></Button>
                </div>

                {this.state.isCurrentSchoolYear && classroom.is_ai_school && (
                  <p>
                    Diese Klasse nimmt am diesjährigen Rennen um den Titel der{" "}
                    <Link to="/school-of-the-year" target="_blank">
                      KI-Schule des Jahres
                    </Link>{" "}
                    teil.
                  </p>
                )}

                <Button
                  onClick={this.deleteClass}
                  className="btn btn-danger my-b width-auto float-right"
                  label="Klasse löschen"
                ></Button>
                {this.state.isCurrentSchoolYear && !classroom.is_ai_school && (
                  <Button
                    onClick={this.aiSchoolSignup}
                    className="btn btn-primary my-b width-auto float-left"
                    label="Klasse fur KI Schule des Jahres registrieren"
                  ></Button>
                )}
              </div>
              {showDeleteConfirmation && (
                <div className="overlay-panel-container">
                  <div className="overlay-panel view-mode-message">
                    <div className="panel-content">
                      <div
                        className="message-title"
                        style={{ textAlign: "center", margin: "1rem" }}
                      >
                        {classDeletedMessage
                          ? `Deine Klasse wurde erfolgreich gelöscht. Wir hoffen dich bald wieder zu sehen!`
                          : deletionConfirmationMessage}
                      </div>
                      <div className="button-wrapper">
                        {classDeletedMessage ? (
                          <button
                            style={{
                              color: "#fff",
                              backgroundColor: "#0083d3",
                            }}
                            onClick={this.closeDeleteMessage}
                          >
                            Ok
                          </button>
                        ) : (
                          <>
                            <button
                              style={{
                                color: "#fff",
                                backgroundColor: "#0083d3",
                              }}
                              onClick={this.onDeleteConfirm}
                            >
                              Ja
                            </button>
                            <button onClick={this.closeDeleteConfirmation}>
                              Abbrechen
                            </button>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {showAISchoolSignupConfirmation && (
                <div className="overlay-panel-container">
                  <div className="overlay-panel view-mode-message">
                    <div className="panel-content">
                      <div
                        className="message-title"
                        style={{ textAlign: "center", margin: "1rem" }}
                      >
                        Mit der Registrierung der Klasse werden die
                        Schüler:Innen des Klassenraums beim Rennen um den Titel
                        der{" "}
                        <Link to="/school-of-the-year" target="_blank">
                          KI-Schule des Jahres
                        </Link>{" "}
                        für dieses Schuljahr berücksichtigt.
                      </div>
                      <div className="button-wrapper">
                        <>
                          <button
                            style={{
                              color: "#fff",
                              backgroundColor: "#0083d3",
                            }}
                            onClick={this.onAISchoolSignupConfirm}
                          >
                            Registrieren
                          </button>
                          <button onClick={this.closeAISchoolSignupConfirm}>
                            Abbrechen
                          </button>
                        </>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {/* Enable AddStudents if classroom belongs to current school year. */}
              {showAddStudent && (
                <div className="overlay-panel-container">
                  <div className="overlay-panel">
                    <div className="definition-header">Schüler hinzufügen</div>
                    <div className="panel-content">
                      <div className="add-student-info col-md-12">
                        <p className="text-justify">
                          Hier können weitere Schüler:innen zu dem Klassenraum
                          hinzugefügt werden. Beachten Sie: Einzelne
                          Schüler:innen können später nicht entfernt werden.
                        </p>
                      </div>
                      <div className="col-md-12">
                        <AddStudents
                          response={classroom}
                          historyProp={this.props.history}
                        />
                      </div>
                      <div className="close-add-student button-wrapper text-center">
                        <button
                          style={{ color: "#fff", backgroundColor: "#0083d3" }}
                          type="button"
                          onClick={this.closeAddStudentAndUpdate}
                          disabled={isUpdatingOverview}
                        >
                          {!isUpdatingOverview ? "Fertig" : "Bitte warten..."}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
          {errors && (
            <div className="error text-center">
              <span className="font-main-header text-danger">{errors}</span>
            </div>
          )}
        </section>
      </React.Fragment>
    );
  }
}

export default ClassroomOverview;
