import React from "react";
import { useEffect, useState } from "react";
import assignmentsService from "../../services/assignmentsService";
import Checkbox from "../common/checkbox";
import ScenarioMain from "./scenarioMain";
import InfoPanel from "./infoPanel";
import QuestionsPanel from "./questionsPanel";
import Loading from "../common/loading";
import Button from "../common/button";

const EthcisCourseContent = (props) => {
  let pathId = props.match.params.coursepath;

  const [personsList, setPersonsList] = useState([]);
  const [scenariosList, setScenariosList] = useState([]);
  const [scenario, setScenario] = useState(1);
  const [course, setcourse] = useState(undefined);
  const [allMembers, setAllMembers] = useState([]);
  const [showMemberSelection, setShowMemberSelection] = useState(false);
  const [selectedMembers, setSelectedMembers] = useState(undefined);
  const [scenarioEnd, setScenarioEnd] = useState(false);
  const [navigationPrompt, setNavigationPrompt] = useState(false);
  const [contentTitle, setContentTitle] = useState();
  const [showContentIntros, setShowContentIntros] = useState(true);
  const [contentPanels, setContentPanels] = useState(undefined);
  const [currentContentPanel, setCurrentContentPanel] = useState(undefined);
  const [showContentQuestions, setShowContentQuestions] = useState(false);
  const [contentQuestions, setContentQuestions] = useState(undefined);
  const [showContentEnd, setShowContentEnd] = useState(false);
  const [contentEnd, setContentEnd] = useState(undefined);
  const [showLoader, setShowLoader] = useState(true);
  const [isArgumentPositive, setIsArgumentPositive] = useState(false);

  useEffect(() => {
    setSelectedMembers();
    initEthicsCourse();
    return () => {};
  }, []);

  const initEthicsCourse = async () => {
    try {
      let ethicsCourseRes = await assignmentsService.getCourses("ethics");

      if (ethicsCourseRes.status === 200) {
        let tempPersons = JSON.parse(
          ethicsCourseRes.data.course.course.persons
        );
        let tempScenarios = JSON.parse(
          ethicsCourseRes.data.course.course.scenarios
        );
        setPersonsList(tempPersons);
        setScenariosList(tempScenarios);
        setAllMembers(new Array(tempPersons.People.length).fill(false));

        let pathScenarios = tempScenarios.allScenarios.find(
          (scenario) => scenario.pathId == pathId
        );
        console.log(pathScenarios);
        setcourse(pathScenarios);

        let tempFlow = [];
        let tempContentTitle = undefined;
        let tempContentEnd = undefined;
        ethicsCourseRes.data.course.course[`flow${pathId}`].map((fileItem) => {
          if (fileItem.file_type === "md") {
            if (fileItem.file_id === "end") {
              tempContentEnd = fileItem;
            } else tempFlow.push(fileItem);
          } else if (fileItem.file_id === "questions")
            setContentQuestions(JSON.parse(fileItem.content));
          else if (fileItem.file_id === "title") {
            tempContentTitle = fileItem.content;
            setContentTitle(fileItem.content);
          }
        });
        setContentPanels(tempFlow);
        //   tempFlow[0].title = tempContentTitle
        if (tempFlow && tempFlow.length && tempFlow[0])
          setCurrentContentPanel({ ...tempFlow[0], title: tempContentTitle });
        if (tempContentEnd)
          setContentEnd({ ...tempContentEnd, title: tempContentTitle });
      }
    } catch (error) {
      console.log("error", error);
    }
    setShowLoader(false);
  };

  const toggleMemberSelection = (position) => {
    let selectedMemberCount = allMembers.reduce((sum, currentState, index) => {
      return currentState ? sum + 1 : sum;
    });

    if (selectedMemberCount >= 3 && !allMembers[position])
      alert("Bitte nur 3 Mitglieder auswählen!");
    else {
      // alert(position)
      let memberCheckedStates = [...allMembers];
      memberCheckedStates[position] = !memberCheckedStates[position];
      setAllMembers(memberCheckedStates);
      console.log("updated checed states", memberCheckedStates);
    }
  };

  const selectPanelMembers = () => {
    let selectedMembers = personsList.People.filter((person, index) => {
      if (allMembers[index]) return person;
    });

    if (selectedMembers.length < 3) alert("Bitte wähle 3 Mitglieder aus!");
    else {
      setSelectedMembers(selectedMembers);
      setScenario(course && course.scenarios[0]);
      evaluateArguments(selectedMembers);
    }
  };

  const evaluateArguments = (panelMembers) => {
    console.log("panel members", panelMembers);
    console.log("course - scenarios", course);
    let pros = 0;
    let cons = 0;
    panelMembers.map((member) => {
      member.arguments.map((argument) => {
        if (argument.pathId == pathId) {
          if (argument.pro) pros = pros + 1;
          else cons = cons + 1;
        }
      });
    });
    course.scenarios.map((scenario) => {
      if (scenario.user.pro) pros = pros + 1;
      else cons = cons + 1;

      if (scenario.expert.pro) pros = pros + 1;
      else cons = cons + 1;
    });
    // console.log("pros - ", pros);
    // console.log("cons - ", cons);
    if (pros > cons) setIsArgumentPositive(true);
    else setIsArgumentPositive(false);
  };

  const nextScenario = () => {
    if (scenario.scenario >= course.scenarios.length) {
      if (contentEnd) setShowContentEnd(true);
      else {
        setNavigationPrompt(true);
      }
      // setSelectedMembers()
    }

    let nextScenario = course.scenarios.find(
      (courseScenario) => courseScenario.scenario == scenario.scenario + 1
    );
    if (nextScenario) {
      setScenario(nextScenario);
      setScenarioEnd(false);
    }
  };

  const prevScenario = () => {
    if (scenario.scenario <= 1) {
      // if(contentEnd)
      //     setShowContentEnd(true)
      // else
      //     setNavigationPrompt(true)
      setSelectedMembers();
      return;
    }

    let prevScenario = course.scenarios.find(
      (courseScenario) => courseScenario.scenario == scenario.scenario - 1
    );
    if (prevScenario) {
      setScenario(prevScenario);
      setScenarioEnd(true);
    }
  };

  const onPanelNext = () => {
    setShowContentEnd(true);
    setShowContentIntros(false);
  };

  const onPanelBack = () => {
    setShowContentEnd(false);
    setScenarioEnd(true);
    // setShowContentIntros(true)
  };

  return (
    <>
      {!showLoader && (
        <>
          <div className="ethics-content-wrapper">
            {!navigationPrompt &&
              !selectedMembers &&
              !showContentIntros &&
              !showContentEnd &&
              !showContentQuestions && (
                <>
                  <div
                    className="scenario-intro-wrapper"
                    style={{ width: "80%", justifySelf: "center" }}
                  >
                    <div className="scenario-intro-header">
                      <div className="header-img">
                        {pathId == 1 && (
                          <img src="/images/ethics/01_flow1_icone v2_hands.svg"></img>
                        )}
                        {pathId == 2 && (
                          <img src="/images/ethics/02_flow2_icone v2_Dron.svg"></img>
                        )}
                      </div>
                      <div className="header-title">
                        {course && (
                          <h2 className="primary-color-lightgreen-foreground">
                            {course.title}
                          </h2>
                        )}
                        <div>
                          Stelle dir jetzt eine eigene Arbeitsgruppe zusammen.
                          Welche Mitglieder findest du besonders interessant?
                          Wer passt gut zum von dir ausgewählten Thema? Wer
                          liefert spannende Argumente? Wähle drei von fünf
                          Mitgliedern aus. Achtung! Deine Auswahl wird die
                          Diskussion maßgeblich beeinflussen.
                        </div>
                      </div>
                    </div>
                  </div>
                  <ul className="panel-list">
                    {personsList &&
                      personsList.People &&
                      personsList.People.length > 0 &&
                      personsList.People.map((person, index) => {
                        let personImgUrl = String(person.imgUrl).replace(
                          "URL_FOR_IMAGES",
                          "/images/ethics"
                        );
                        return (
                          <>
                            <li>
                              <div className="panel-item-header">
                                {person.name}
                              </div>
                              <img
                                className="panel-item-img"
                                src={personImgUrl}
                              ></img>
                              <div
                                style={{
                                  width: "2.5rem",
                                  height: "2.5rem",
                                  justifySelf: "center",
                                }}
                              >
                                <label className="checkbox-container">
                                  <input
                                    className="checkbox"
                                    type="checkbox"
                                    id={`person${index}`}
                                    name="memberPanel"
                                    value={person.personId}
                                    checked={allMembers[index]}
                                    onChange={toggleMemberSelection.bind(
                                      this,
                                      index
                                    )}
                                  ></input>
                                  <span className="checkmark"></span>
                                </label>
                              </div>
                            </li>
                          </>
                        );
                      })}
                  </ul>
                  <div className="submit-parent-wrapper">
                    <div className="button-wrapper scenarios-selection-actions">
                      <Button
                        onClick={selectPanelMembers}
                        href="#"
                        label="Mitglieder auswählen"
                      />
                    </div>
                  </div>
                </>
              )}

            {showContentIntros && currentContentPanel && (
              <InfoPanel
                content={currentContentPanel}
                back={() => {}}
                next={() => {
                  setShowContentIntros(false);
                  setShowContentQuestions(true);
                }}
              />
            )}

            {showContentEnd && contentEnd && (
              <InfoPanel
                content={contentEnd}
                back={onPanelBack}
                next={() => {
                  setShowContentEnd(false);
                  setNavigationPrompt(true);
                }}
                isArgumentPositive={isArgumentPositive}
              />
            )}
            {showContentQuestions && contentQuestions && (
              <QuestionsPanel
                questions={contentQuestions}
                back={() => {
                  setShowContentIntros(true);
                  setShowContentQuestions(false);
                }}
                next={() => {
                  setShowContentQuestions(false);
                }}
                title={currentContentPanel.title}
                chapter={pathId}
              />
            )}

            {selectedMembers && !showContentEnd && !navigationPrompt && (
              <ScenarioMain
                scenario={scenario}
                pathId={pathId}
                pathHeader={course.title}
                members={selectedMembers}
                nextScenario={nextScenario}
                prevScenario={prevScenario}
                last={scenarioEnd}
              />
            )}
            {navigationPrompt && (
              <>
                <div className="main-wrapper">
                  <div className="message-prompt">
                    Willst du nochmals den Rat der Denkenden zu einem{" "}
                    <b>anderen Thema</b> tagen lassen, dann wähle erneut den
                    Them-O-Mat. <br /> <br />
                    Oder würdest du gerne beim gleichen Thema bleiben und ganz{" "}
                    <b>andere Mitglieder</b> zu deiner Arbeitsgruppe
                    hinzuziehen? <br /> <br />
                    Ebenso kannst du auch die <b>Rückreise nach Hause</b>{" "}
                    antreten! Wähle aus:
                  </div>
                  <div className="submit-parent-wrapper">
                    <div className="button-wrapper scenarios-end-actions">
                      {
                        <Button
                          onClick={(e) => {
                            props.history.push({
                              pathname: "/ethics/ethically-questionable/paths",
                            });
                          }}
                          href="#"
                          className=""
                          label="zurück zum Them-O-Mat"
                        /> /*Nächste Frage */
                      }
                      {
                        <Button
                          onClick={(e) => {
                            setNavigationPrompt(false);
                            setSelectedMembers();
                          }}
                          href="#"
                          className=""
                          label="neue Mitglieder"
                        /> /*Nächste Frage */
                      }
                      {
                        <Button
                          onClick={(e) => {
                            props.history.push({
                              pathname:
                                "/ethics/ethically-questionable/conclusion",
                            });
                          }}
                          href="#"
                          className=""
                          label="nach Hause"
                        /> /*Vorherige Frage */
                      }
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </>
      )}
      {showLoader && <Loading />}
    </>
  );
};

export default EthcisCourseContent;
