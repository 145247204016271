import React, { useEffect, useState, useHistory } from "react";

function ChapterTile({
  chapter,
  chapterName,
  index,
  course,
  root,
  history,
  viewMode,
  toggleViewModeMessage,
  urlSearchParams = new URLSearchParams(),
}) {
  // `chapter` has a different data structure depending on `viewMode`.
  // If `viewMode` is true, then `chapter` has the data structure which is returned by
  // `assignmentService.getCourseMetadta`, which itself queries `${submission_api_url}/metadata/${course}`.
  // If `viewMode` is false, then `chapter` has a different data structure.
  // Consult `/frontend/src/components/course/CourseMain.jsx` for details.

  const queryString =
    urlSearchParams && urlSearchParams.size > 0
      ? `?${urlSearchParams.toString()}`
      : "";

  //TODO: refactor
  const onChapterClick = (element) => {
    if (element[0].video && element[0].video === true) {
      history.push({
        pathname: `${root}/chapter-video/course=${course}/id=${element[0].id}`,
        search: queryString,
      });
    }
    // Commented out because there is no intro page
    // else if (element[0].intro) {
    //   history.push({
    //     pathname: `${root}/intro-page/course=${course}/id=${element[0].id}`,
    //   })
    // }
    else if (element[0].course_data) {
      history.push({
        pathname: `${root}/course-content/course=${course}/id=${element[0].id}`,
        search: queryString,
      });
    } else if (element[0].questions && element[0].questions !== null) {
      let allQuestions = JSON.parse(element[0].questions);
      history.push({
        pathname: `${root}/code-questions/course=${course}/id=${element[0].id}/questionId=${allQuestions.allQuestions[0].id}`,
        search: queryString,
      });
    } else if (element[0].description && element[0].description !== null) {
      history.push({
        pathname: `${root}/code-submission/course=${course}/id=${element[0].id}`,
        search: queryString,
      });
    } else if (element[0].end) {
      history.push({
        pathname: `${root}/conclusion-page/course=${course}/id=${element[0].id}`,
        search: queryString,
      });
    }
  };

  function handleVideoInChapter(element) {
    let ids = element.id.split("_");
    let chapterId = Number(ids[0]);
    let subChapterId = Number(ids[1]);
    let currentChapter = chapter;
    let videoItem = currentChapter.filter((item) => {
      let ids = item.id.split("_");
      if (ids.includes("v") && Number(ids[1]) === subChapterId - 1) {
        return true;
      } else return false;
    });

    if (videoItem && videoItem[0]) return videoItem[0];
    else return element;
  }

  //TODO: refactor
  const onQuestionClick = (event, item) => {
    let root = "/programming/intro";
    let element = handleVideoInChapter(item);

    if (element.video && element.video === true) {
      history.push({
        pathname: `${root}/chapter-video/course=${course}/id=${element.id}`,
        search: queryString,
      });
    } else if (element.intro && element.intro !== null) {
      history.push({
        pathname: `${root}/intro/course=${course}/id=${element.id}`,
        search: queryString,
      });
    } else if (element.course_data) {
      history.push({
        pathname: `${root}/course-content/course=${course}/id=${element.id}`,
        search: queryString,
      });
    } else if (element.questions && element.questions !== null) {
      // let allQuestions = JSON.parse(element.questions);
      history.push({
        pathname: `${root}/code-questions/course=${course}/id=${element.id}/questionId=1`,
        search: queryString,
      });
    } else if (element.description && element.description !== null) {
      history.push({
        pathname: `${root}/code-submission/course=${course}/id=${element.id}`,
        search: queryString,
      });
    } else if (element.end && element.end !== null) {
      history.push({
        pathname: `${root}/conclusion-page/course=${course}/id=${element.id}`,
        search: queryString,
      });
    }

    if (!event) var event = window.event;
    event.cancelBubble = true;
    if (event.stopPropagation) event.stopPropagation();
  };

  if (!viewMode) {
    return (
      <div
        key={index}
        className={`${
          course === "recycling" ? "" : "dog-job-box"
        } job-box margin-top-10`}
        onClick={(e) => {
          onChapterClick(chapter);
        }}
      >
        <div className={`image-box`}>
          <img
            src={`/images/icons/subpaths/programming/intro/pythonCourse/${course}_${index}.svg`}
            alt=""
          />
        </div>
        <div className={`content-box`}>
          <span className={`chapter`}>{`KAPITEL ${index}`}</span>
          <span className={`title primary-color-lightblue-foreground`}>
            {chapterName}
          </span>
          <div className={`section`}>
            <span>Abschnitt</span>
            <span>Punkte</span>
          </div>
          <div className={`question-section`}>
            {chapter.map(
              (element, index1) =>
                (element.questions ||
                  element.intro ||
                  element.course_data ||
                  element.description ||
                  element.end) && (
                  <React.Fragment key={index1}>
                    <div
                      key={index1}
                      className={`question`}
                      onClick={(e) => {
                        onQuestionClick(e, element);
                      }}
                    >
                      {/* <div className={`line`}></div> */}
                      <span style={{ paddingRight: "10px" }}>
                        {element.title}{" "}
                      </span>
                      {(element.questions || element.description) && (
                        <span>{element.score}</span>
                      )}
                    </div>
                  </React.Fragment>
                )
            )}
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div
        className={`${
          course === "recycling" ? "" : "dog-job-box"
        } job-box margin-top-10`}
        onClick={toggleViewModeMessage}
      >
        <div className={`image-box`}>
          <img
            src={`/images/icons/subpaths/programming/intro/pythonCourse/${course}_${index}.svg`}
            alt=""
          />
        </div>
        <div className={`content-box`}>
          <span className={`chapter`}>{`KAPITEL ${index}`}</span>
          <span className={`title primary-color-lightblue-foreground`}>
            {chapter.chapter}
          </span>
          <div className={`section`}>
            <span>Abschnitt</span>
          </div>
          <div className={`question-section`}>
            {chapter.sub_chapters &&
              chapter.sub_chapters.map((element, index1) => (
                <React.Fragment>
                  <div key={index1} className={`question`}>
                    {/* <div className={`line`}></div> */}
                    <span style={{ paddingRight: "10px" }}>{element} </span>
                  </div>
                </React.Fragment>
              ))}
          </div>
        </div>
      </div>
    );
  }
}

export default ChapterTile;
