import React, { Component } from "react";
import authService from "../services/authService";
import { Link } from "react-router-dom";
import * as alert from "../alert.json";
import userService from "../services/userService";

class tutorials extends Component {
  state = {
    tutorials: [
      {
        course: "dog",
        title: "Erkenne Krankheiten bei Straßenhunden",
        description: "",
        subHeader: "TUTORIAL 1",
        link: "/programming/tutorials/course=dog",
        image: "dog.svg",
        totalTasks: 75,
        attemptedTasks: 0,
      },
      {
        course: "recycling",
        title: "Optimiere Müllsortierung",
        description: "",
        subHeader: "TUTORIAL 2",
        link: "/programming/tutorials/course=recycling",
        image: "recycling.svg",
        totalTasks: 36,
        attemptedTasks: 0,
      },
    ],
    viewMode: true,
    taskDog: 0,
    taskRecycling: 0,
  };

  componentDidMount() {
    let user = authService.getCurrentUser();

    if (!user) {
      this.setState({
        viewMode: true,
      });
    } else {
      let tasksDog = 0;
      let tasksRecycling = 0;
      this.state.tutorials.map((tutorial) => {
        userService
          .getAttemptedTasksForCourse(tutorial.course)
          .then(({ data: response }) => {
            if (!response) {
              return;
            }

            let sum = 0;
            if (tutorial.course === "dog") {
              response.map((subchapter) => {
                sum += subchapter.num_attempted;
              });
              this.setState({ taskDog: sum });
            } else if (tutorial.course === "recycling") {
              response.map((subchapter) => {
                sum += subchapter.num_attempted;
              });
              this.setState({ taskRecycling: sum });
            }
          });
      });
    }
  }

  removeAlert = () => {
    this.setState({
      showAlert: false,
    });
  };

  render() {
    const { taskDog, taskRecycling } = this.state;
    const user = authService.getCurrentUser();
    let viewMode = true;
    if (user) viewMode = false;
    return (
      <React.Fragment>
        <section className="main-content">
          <div className="tiles-container tutorials-tiles">
            {this.state.tutorials.length > 0 &&
              this.state.tutorials.map((tutorial, index) => (
                <Link
                  key={index}
                  to={tutorial.link}
                  className="subpath-tile tutorial"
                >
                  <div className="tile-wrapper">
                    <img
                      style={{ width: "100%" }}
                      src={`/images/icons/subpaths/programming/tutorials/${tutorial.image}`}
                    />
                    <div className="row2 primary-color-lightblue-background">
                      <div className="tile-sub-header">
                        {tutorial.subHeader}
                      </div>
                      <div className="tile-header fix-height-3">
                        {tutorial.title}
                      </div>
                      {!viewMode && (
                        <div className="tile-status">{`${
                          tutorial.course === "dog" ? taskDog : taskRecycling
                        }/${tutorial.totalTasks} Aufgaben bearbeitet`}</div>
                      )}
                    </div>
                  </div>
                </Link>
              ))}
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default tutorials;
