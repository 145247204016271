import React, { Component } from "react";
import Button from "../common/button";
import assignmentsService from "../../services/assignmentsService";
import { Progress } from "reactstrap";
import Markdown from "../common/markdown";
import AppContext from "../common/appContext";
import CodeEditor from "../course/CodeEditor";
import codeService from "../../services/codeService";
// Makes use of : https://github.com/react-syntax-highlighter/react-syntax-highlighter/blob/master/AVAILABLE_LANGUAGES_HLJS.MD
import SyntaxHighlighter from "react-syntax-highlighter";
import {
  vs2015,
  atelierDuneLight,
} from "react-syntax-highlighter/dist/esm/styles/hljs";
import Loading from "../common/loading";

class CourseContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.match.params.id,
      course: this.props.match.params.course,
      assignment: {},
      chapters: [],
      courseContent: [],
      showLoading: true,
      currentTaskNum: 0,
      maxQuestions: 0,
      isPyodideLoaded: false,
    };
    const { path } = this.props.match;
    this.rootPath = path.split("/").slice(0, -3).join("/");
    this.pyodide = null;
    this.pyodide_stdouterr = "";
  }

  componentDidMount() {
    console.log("componentDidMount");
    const { id, course } = this.state;

    assignmentsService
      .getAssignments(course)
      .then(({ data: response }) => {
        let assignment = response.assignments.find((p) => p.id === id);
        let courseContent = assignment.course_data;
        let index = 0;
        courseContent = courseContent.map((courseData) => {
          if (courseData.file_type !== "md") {
            if (courseData.file_type === "py") {
              if (courseData.file_id.includes("SOLUTION")) {
                courseData.isSolution = true;
              } else {
                courseData.pythonIndex = index;
                index++;
                // build solution filename and check if exists
                let solution = null;
                if (courseData.file_id.includes("EXERCISE")) {
                  let solutionFileName = courseData.file_id.replace(
                    "EXERCISE",
                    "SOLUTION"
                  );
                  let solutionList = courseContent.filter(
                    (courseItem) => courseItem.file_id === solutionFileName
                  );
                  solution = solutionList[0] ? solutionList[0].content : null;
                }
                courseData.solution = solution;
                courseData.isSolution = false;
              }
            }
            return courseData;
          }
          let contentMarkdown = courseData.content;
          let newMarkDown = contentMarkdown.replace(
            /URL_FOR_IMAGES\//g,
            "/images/" + course + "/"
          );
          courseData.content = newMarkDown;

          return courseData;
        });

        courseContent = courseContent.filter(
          (course) => !(course.file_type === "py" && course.isSolution)
        );
        // If there exists atleast one coding block, init pyodide
        if (index > 0) {
          console.log("initialzing and setting up pyodide async...");
          window
            .loadPyodide({
              indexURL: window.PYODIDE_BASE_URL,
              stdout: (line) => (this.pyodide_stdouterr += line + "\n"),
              stderr: (line) => (this.pyodide_stdouterr += line + "\n"),
            })
            .then((pyodide) => {
              this.pyodide = this.setup_pyodide(pyodide);
            });
        }
        // Initialize Progress Bar
        let { chapterQuestionsNum, currentTaskNum } =
          this.initProgressBar(response);

        this.setState({
          assignment,
          chapters: [].concat(response.assignments),
          courseContent,
          showLoading: false,
          maxQuestions: chapterQuestionsNum,
          currentTaskNum: currentTaskNum,
          isPyodideLoaded: false,
        });
      })
      .catch((err) => {
        // TODO: Handle Error
      });
  }

  initProgressBar(response) {
    let chapterQuestionsNum = 0;
    let currentTaskNum = 0;
    let currentSubChapter = this.state.id.slice(-2);
    let currentChapter = this.state.id.split("_")[0];

    response.assignments.forEach((item, index) => {
      let chapterId = item.id.split("_")[0];
      if (chapterId === currentChapter) {
        let subChapterId = item.id.slice(-2);
        let currentSubchapterQuestions = 0;
        if (item.questions) {
          currentSubchapterQuestions = JSON.parse(item.questions).allQuestions
            .length;
        }

        if (item.intro) {
          chapterQuestionsNum += 1;
          if (subChapterId <= currentSubChapter) {
            currentTaskNum += 1;
          }
        }

        if (item.course_data) {
          chapterQuestionsNum += 1;
          if (subChapterId < currentSubChapter) {
            currentTaskNum += 1;
          }
        }

        if (item.description) {
          chapterQuestionsNum += 1;
          if (subChapterId < currentSubChapter) {
            currentTaskNum += 1;
          }
        }

        if (item.end) {
          chapterQuestionsNum += 1;
          if (subChapterId < currentSubChapter) {
            currentTaskNum += 1;
          }
        }

        chapterQuestionsNum += currentSubchapterQuestions;
        if (subChapterId < currentSubChapter)
          currentTaskNum += currentSubchapterQuestions;

        if (subChapterId === currentSubChapter) {
          currentTaskNum = currentTaskNum;
        }
      }
    });
    return { chapterQuestionsNum, currentTaskNum };
  }

  onNextFromCourseContent = () => {
    const { chapters, assignment, course } = this.state;
    const queryParams = new URLSearchParams(this.props.location.search);

    if (assignment.questions && assignment.questions !== null) {
      let allQuestions = JSON.parse(assignment.questions);
      this.props.history.push({
        pathname: `${this.rootPath}/code-questions/course=${course}/id=${assignment.id}/questionId=${allQuestions.allQuestions[0].id}`,
        search: queryParams.toString(),
      });
    } else if (assignment.description && assignment.description !== null) {
      this.props.history.push({
        pathname: `${this.rootPath}/code-submission/course=${course}/id=${assignment.id}`,
        search: queryParams.toString(),
      });
    } else if (assignment.end && assignment.end !== null) {
      this.props.history.push({
        pathname: `${this.rootPath}/conclusion-page/course=${course}/id=${assignment.id}`,
        search: queryParams.toString(),
      });
    } else {
      let nextAssignment = chapters[chapters.indexOf(assignment) + 1];
      if (!nextAssignment) {
        if (queryParams.has("code_week")) {
          const path = this.rootPath.split("/")[0];
          const paket = queryParams.get("code_week");
          this.props.history.push({
            pathname: `${path}/code-week/paket${paket}`,
          });
        } else {
          this.props.history.push({
            pathname: `${this.rootPath}/course=${course}`,
          });
        }
        return;
      }
      if (assignment.id.split("_")[0] === nextAssignment.id.split("_")[0]) {
        if (nextAssignment.video) {
          this.props.history.push({
            pathname: `${this.rootPath}/chapter-video/course=${course}/id=${nextAssignment.id}`,
            search: queryParams.toString(),
          });
        } else if (nextAssignment.intro) {
          this.props.history.push({
            pathname: `${this.rootPath}/intro/course=${course}/id=${nextAssignment.id}`,
            search: queryParams.toString(),
          });
        } else if (nextAssignment.course_data) {
          this.props.history.push({
            pathname: `${this.rootPath}/course-content/course=${course}/id=${nextAssignment.id}`,
            search: queryParams.toString(),
          });
        } else if (nextAssignment.questions) {
          let allQuestions = JSON.parse(nextAssignment.questions);
          this.props.history.push({
            pathname: `${this.rootPath}/code-questions/course=${course}/id=${nextAssignment.id}/questionId=${allQuestions.allQuestions[0].id}`,
            search: queryParams.toString(),
          });
        } else if (
          nextAssignment.description &&
          nextAssignment.description !== null
        ) {
          this.props.history.push({
            pathname: `${this.rootPath}/code-submission/course=${course}/id=${nextAssignment.id}`,
            search: queryParams.toString(),
          });
        } else if (assignment.end && assignment.end !== null) {
          this.props.history.push({
            pathname: `${this.rootPath}/conclusion-page/course=${course}/id=${nextAssignment.id}`,
            search: queryParams.toString(),
          });
        } else {
          if (queryParams.has("code_week")) {
            const path = this.rootPath.split("/")[0];
            const paket = queryParams.get("code_week");
            this.props.history.push({
              pathname: `${path}/code-week/paket${paket}`,
            });
          } else {
            this.props.history.push({
              pathname: `${this.rootPath}/course=${course}`,
            });
          }
        }
      } else {
        if (queryParams.has("code_week")) {
          const path = this.rootPath.split("/")[0];
          const paket = queryParams.get("code_week");
          this.props.history.push({
            pathname: `${path}/code-week/paket${paket}`,
          });
        } else {
          this.props.history.push({
            pathname: `${this.rootPath}/course=${course}`,
          });
        }
      }
    }
  };

  // Adding back button for the introduction
  onBackButtonFromCourseContent = () => {
    const { chapters, assignment, course } = this.state;
    const queryParams = new URLSearchParams(this.props.location.search);
    let prevAssignment = chapters[chapters.indexOf(assignment) - 1];
    if (assignment.intro) {
      this.props.history.push({
        pathname: `${this.rootPath}/intro-page/course=${course}/id=${assignment.id}`,
        search: queryParams.toString(),
      });
    } else if (assignment.video) {
      this.props.history.push({
        pathname: `${this.rootPath}/chapter-video/course=${course}/id=${assignment.id}`,
        search: queryParams.toString(),
      });
    } else {
      // If there doesnt exist a previous assignment, then go back to course main
      if (!prevAssignment) {
        this.props.history.push({
          pathname: `${this.rootPath}/course=${course}`,
        });
      } else {
        // If current and previous assignments are form the same chapter
        if (assignment.id.split("_")[0] === prevAssignment.id.split("_")[0]) {
          if (prevAssignment.end) {
            this.props.history.push({
              pathname: `${this.rootPath}/conclusion-page/course=${course}/id=${prevAssignment.id}`,
              search: queryParams.toString(),
            });
          } else if (
            prevAssignment.description &&
            prevAssignment.description !== null
          ) {
            this.props.history.push({
              pathname: `${this.rootPath}/code-submission/course=${course}/id=${prevAssignment.id}`,
              search: queryParams.toString(),
            });
          } else if (prevAssignment.questions) {
            let allQuestions = JSON.parse(prevAssignment.questions);
            let questionNum = allQuestions.allQuestions.length - 1;
            this.props.history.push({
              pathname: `${this.rootPath}/code-questions/course=${course}/id=${prevAssignment.id}/questionId=${allQuestions.allQuestions[questionNum].id}`,
              search: queryParams.toString(),
            });
          } else if (prevAssignment.course_data) {
            this.props.history.push({
              pathname: `${this.rootPath}/course-content/course=${course}/id=${prevAssignment.id}`,
              search: queryParams.toString(),
            });
          } else if (prevAssignment.intro) {
            this.props.history.push({
              pathname: `${this.rootPath}/intro/course=${course}/id=${prevAssignment.id}`,
              search: queryParams.toString(),
            });
          } else if (prevAssignment.video) {
            this.props.history.push({
              pathname: `${this.rootPath}/chapter-video/course=${course}/id=${prevAssignment.id}`,
              search: queryParams.toString(),
            });
          } else {
            this.props.history.push({
              pathname: `${this.rootPath}/course=${course}`,
            });
          }
        } else {
          this.props.history.push({
            pathname: `${this.rootPath}/course=${course}`,
          });
        }
      }
    }
  };

  setup_pyodide = (pyodide) => {
    // The python code below overwrites the matplotlib.pyplot.show method,
    // if the matplotlib.pyplot (aka plt) module is getting imported.
    // The plt.show method then writes the image in an data URL to the python variable `_matplotlib_figure_data_url`.
    // Pyodide can access this data URL string and display the image in the browser.
    var setup_code = `
import sys as _sys
from importlib import import_module as _import_module
from importlib.abc import Loader as _Loader

class ImportInterceptor(_Loader):
    def __init__(self):
        self.overwritten = False

    def find_module(self, fullname, path=None):
        return self

    def load_module(self, fullname):
        _sys.meta_path = [x for x in _sys.meta_path[1:] if x is not self]
        module = _import_module(fullname)
        if fullname == 'matplotlib.pyplot' and not self.overwritten:
            self.overwritten = True
            import matplotlib.pyplot as _plt
            import base64, io

            def get_data_url_for_figure(plt):
                buf = io.BytesIO()
                fig = plt.gcf()
                fig.savefig(buf, format='png', bbox_inches="tight")
                plt.close(fig)
                buf.seek(0)
                data_url = 'data:image/png;base64,' + base64.b64encode(buf.read()).decode('UTF-8')
                return data_url

            def alternate_show(): 
                global _matplotlib_figure_data_url
                data_uri = get_data_url_for_figure(_plt)
                _matplotlib_figure_data_url = data_uri

            _plt.show = alternate_show
        _sys.meta_path = [self] + _sys.meta_path
        return module


if not hasattr(_sys,'frozen'):
    _sys.meta_path = [ImportInterceptor()] + _sys.meta_path
      `;
    pyodide.runPythonAsync(setup_code);
    this.setState({
      isPyodideLoaded: true,
    });
    return pyodide;
  };

  runScript = (code, setOutput, setError) => {
    let codeToRun = code.replaceAll('"""', "'''");
    this.pyodide.loadPackage([]).then(() => {
      this.pyodide
        .loadPackagesFromImports(codeToRun)
        .then(() => {
          this.pyodide.runPython(codeToRun);
          setOutput(
            this.pyodide_stdouterr,
            this.pyodide.globals.get("_matplotlib_figure_data_url")
          );
          this.pyodide.globals.set("_matplotlib_figure_data_url", null);
          this.pyodide_stdouterr = "";
        })
        .catch((e) => {
          console.log(e.message);
          setError(e);
        });
    });
  };

  loadSavedCode = (fileId, setStateCallBack, setError) => {
    const { course, id } = this.state;
    codeService
      .getCodeforAssignmentByFileId(course, id, [fileId])
      .then(({ data: response }) => {
        if (response && response.output && response.output.length > 0) {
          if (setStateCallBack) setStateCallBack(response.output[0].content);
        } else {
          setError("No saved data to load!");
        }
      })
      .catch((e) => {
        setError(e.message);
      });
  };

  saveCode = async (fileId, content) => {
    const { course, id } = this.state;
    return await codeService.saveCodeForField(course, id, fileId, content);
  };

  render() {
    const {
      assignment,
      id,
      course,
      chapters,
      showLoading,
      currentTaskNum,
      maxQuestions,
      courseContent,
    } = this.state;
    return (
      <AppContext.Consumer>
        {(context) => {
          if (context === undefined) {
            throw new Error("AppConsumer must be used within a AppProvider");
          }
          let chapter_header = "";
          if (course) {
            chapter_header = context.tutorials.find((p) => p.course === course)
              .chapters[id.split("_")[0] - 1];
          }
          return (
            <React.Fragment>
              <section className="content question">
                {!showLoading && (
                  <div className="container-limit my-limit">
                    <div className="intro-section">
                      {!assignment.intro && courseContent && (
                        <React.Fragment>
                          <div className="progressBar">
                            <div className="progress-table-bar">
                              <div className="progress-div">
                                <Progress
                                  value={currentTaskNum + 1}
                                  max={maxQuestions}
                                ></Progress>
                                <div className="progress-span">
                                  {currentTaskNum + 1}/{maxQuestions}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="task-item-lightheader mb-3">
                            Kapitel {id.split("_")[0] - 0}: {chapter_header}
                          </div>
                          <div className="task-item-mainheader">
                            {assignment ? assignment.title : ""}
                          </div>
                          <div className="intro-wrapper">
                            {courseContent &&
                              courseContent.map((contentItem) => {
                                const {
                                  content,
                                  file_id: fileId,
                                  file_type: fileType,
                                  isSolution,
                                  solution,
                                  pythonIndex,
                                } = contentItem;
                                if (fileType === "md") {
                                  return (
                                    <div className="intro">
                                      <Markdown
                                        className="intro"
                                        children={content}
                                      />
                                    </div>
                                  );
                                } else if (fileType === "py" && !isSolution) {
                                  return (
                                    <CodeEditor
                                      course={course}
                                      id={id}
                                      courseItem={contentItem}
                                      runCode={this.runScript}
                                      saveCode={this.saveCode}
                                      loadSavedCode={this.loadSavedCode}
                                      isPyodideLoaded={
                                        this.state.isPyodideLoaded
                                      }
                                    />
                                  );
                                }
                              })}
                          </div>
                          <div className="button-wrapper">
                            <div className="button-video">
                              {chapters[chapters.indexOf(assignment) - 1] && (
                                <Button
                                  href="#"
                                  onClick={this.onBackButtonFromCourseContent}
                                  label="Zurück"
                                />
                              )}
                              <Button
                                href="#"
                                onClick={this.onNextFromCourseContent}
                                label="Weiter"
                              />
                            </div>
                          </div>
                        </React.Fragment>
                      )}
                    </div>
                  </div>
                )}
                {showLoading && <Loading />}
              </section>
            </React.Fragment>
          );
        }}
      </AppContext.Consumer>
    );
  }
}

export default CourseContent;
