import React, { Component } from "react";
import Question from "../questions/question";
import Button from "../common/button";
import { Progress } from "reactstrap";
import AppContext from "../common/appContext";
import questionService from "../../services/questionService";
import assignmentsService from "../../services/assignmentsService";
import userService from "../../services/userService";
import _ from "lodash";
import Loading from "../common/loading";

class CodeQuestions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.match.params.id,
      questionId: this.props.match.params.questionId,
      course: this.props.match.params.course,
      currentAssignment: {},
      prevAssingment: null,
      nextAssingment: null,
      currentQuestion: null,
      selectedAnswers: [],
      questions: [],
      showMenu: false,
      hideMenu: false,
      success: null,
      correct: false,
      allowSubmit: true,
      message: "",
      color: "",
      totalPoints: 0,
      maxQuestions: 0,
      currentTaskNum: 0,
      showLoading: true,
    };
    this.rootPath = this.props.match.path.split("/").slice(0, -4).join("/");
    this.queryParams = new URLSearchParams(this.props.location.search);
  }

  componentDidMount() {
    const { course, id, questionId } = this.state;
    assignmentsService
      .getAssignments(this.state.course)
      .then(({ data: response }) => {
        questionService
          .getQuestionByChapterId(this.state.course, id)
          .then(({ data: response1 }) => {
            userService
              .getScoresForCourse(course)
              .then(({ data: responsePoints }) => {
                let pointsItems = responsePoints;
                let chapterPointsItems = pointsItems
                  ? pointsItems.filter(
                      (pointsItem) =>
                        // pointsItem.chapter_id.charAt(0) === id.charAt(0)
                        pointsItem.chapter_id.split("_")[0] === id.split("_")[0]
                    )
                  : [];
                let totalPoints = 0;
                chapterPointsItems.map((item) => {
                  totalPoints += item.points;
                });

                this.setState({ totalPoints });
              });

            let correct = null;
            let subState = { message: "", color: "", allowSubmit: true };
            let selectedAnswers = [];
            let questionAnswers = response1.find(
              (p) => p.quiz_id === parseInt(questionId)
            );

            let currentAssignment = response.assignments.find(
              (p) => p.id === id
            );

            // find next and previous assingments to aid next/back navigation
            let currentIndex = response.assignments.indexOf(currentAssignment);
            let prevAssignment = null;
            let nextAssignment = null;

            if (currentIndex !== 0)
              prevAssignment = response.assignments[currentIndex - 1];
            if (currentIndex < response.assignments.length - 1)
              nextAssignment = response.assignments[currentIndex + 1];

            let questionResponse = JSON.parse(
              response.assignments.find((p) => p.id === id).questions
            ).allQuestions;
            let currentQuestion = questionResponse.find(
              (p) => p.id === parseInt(questionId)
            );
            if (questionAnswers) {
              correct = questionAnswers.correct;
              selectedAnswers = questionAnswers.answers;
              subState = this.handleMessage(questionAnswers, true);
            }

            // Handle Progress Bar
            let chapterQuestionsNum = 0;
            let currentTaskNum = 0;
            let currentSubChapter = this.state.id.slice(-2);
            // let currentChapter = this.state.id.charAt(0);
            let currentChapter = this.state.id.split("_")[0];
            response.assignments.forEach((item, index) => {
              if (item.id.split("_")[0] === currentChapter) {
                let currentSubchapterQuestions = 0;
                if (item.questions) {
                  currentSubchapterQuestions = JSON.parse(item.questions)
                    .allQuestions.length;
                }

                let subChapterId = item.id.slice(-2);
                if (item.intro) {
                  chapterQuestionsNum += 1;

                  if (subChapterId <= currentSubChapter) {
                    currentTaskNum += 1;
                  }
                }

                if (item.course_data) {
                  chapterQuestionsNum += 1;
                  if (subChapterId <= currentSubChapter) {
                    currentTaskNum += 1;
                  }
                }

                if (item.description) {
                  chapterQuestionsNum += 1;
                  if (item.id.slice(-2) < currentSubChapter) {
                    currentTaskNum += 1;
                  }
                }

                if (item.end) {
                  chapterQuestionsNum += 1;
                  if (subChapterId < currentSubChapter) {
                    currentTaskNum += 1;
                  }
                }

                chapterQuestionsNum += currentSubchapterQuestions;
                if (subChapterId < currentSubChapter)
                  currentTaskNum += currentSubchapterQuestions;

                if (subChapterId === currentSubChapter) {
                  currentTaskNum += parseInt(this.state.questionId) - 1;
                }
              }
            });

            correct = questionResponse.correct;

            this.setState({
              questions: [].concat(questionResponse),
              currentQuestion,
              currentAssignment,
              prevAssignment,
              nextAssignment,
              correct,
              selectedAnswers,
              message: subState.message,
              color: subState.color,
              allowSubmit: subState.allowSubmit,
              maxQuestions: chapterQuestionsNum,
              currentTaskNum: currentTaskNum,
              showLoading: false,
            });
          });
      })
      .catch((err) => {});
    // assignmentsService.getUserAssignment(course, id).then(({ data: response }) => {
    // })
  }

  submitQuestions = () => {
    const { currentQuestion, id, course } = this.state;
    let questionSubmit = {};
    questionSubmit.id = currentQuestion.id;
    questionSubmit.chapterId = id;
    questionSubmit.type = course;
    questionSubmit.answers = currentQuestion.answers;
    questionService
      .submitQuestions(questionSubmit)
      .then(({ data: response }) => {
        userService
          .getScoresForCourse(course)
          .then(({ data: responsePoints }) => {
            let pointsItems = responsePoints;
            let chapterPointsItems = pointsItems
              ? pointsItems.filter(
                  (pointsItem) =>
                    pointsItem.chapter_id.split("_")[0] === id.split("_")[0]
                )
              : [];
            let totalPoints = 0;
            chapterPointsItems.map((item) => {
              totalPoints += item.points;
            });

            this.setState({ totalPoints });
          });

        let selectedAnswers = response.answers;
        let subState = this.handleMessage(response, false);
        this.setState({
          correct: response.correct,
          success: true,
          selectedAnswers,
          message: subState.message,
          color: subState.color,
          allowSubmit: subState.allowSubmit,
          showLoading: false,
        });
      })
      .catch((err) => {
        this.setState({
          success: false,
          showLoading: false,
        });
      });
  };

  onNextButton = () => {
    const { id, questionId } = this.props.match.params;
    this.props.history.push({
      pathname: `${this.rootPath}/code-questions/course=${
        this.state.course
      }/id=${id}/questionId=${parseInt(questionId) + 1}`,
      search: this.queryParams.toString(),
    });
  };

  onPreviousButton = () => {
    const { questionId, id } = this.props.match.params;
    const { prevAssignment, currentAssignment, course } = this.state;

    if (parseInt(questionId) === 1) {
      // if first question
      if (currentAssignment.course_data) {
        this.props.history.push({
          pathname: `${this.rootPath}/course-content/course=${course}/id=${currentAssignment.id}`,
          search: this.queryParams.toString(),
        });
      } else if (currentAssignment.intro) {
        this.props.history.push({
          pathname: `${this.rootPath}/introduction/course=${course}/id=${currentAssignment.id}`,
          search: this.queryParams.toString(),
        });
      } else if (currentAssignment.video) {
        this.props.history.push({
          pathname: `${this.rootPath}/chapter-video/course=${course}/id=${currentAssignment.id}`,
          search: this.queryParams.toString(),
        });
      } else {
        // if first question and the current subchapter doestn have vieo or intro then, navigate to previous subchapter
        if (
          currentAssignment.id.split("_")[0] === prevAssignment.id.split("_")[0]
        ) {
          // if previous subchapter is not from a different chapter
          if (currentAssignment.end) {
            this.props.history.push({
              pathname: `${this.rootPath}/conclusion-page/course=${course}/id=${prevAssignment.id}`,
              search: this.queryParams.toString(),
            });
          } else if (
            prevAssignment.description &&
            prevAssignment.description !== null
          ) {
            this.props.history.push({
              pathname: `${this.rootPath}/code-submission/course=${course}/id=${prevAssignment.id}`,
              search: this.queryParams.toString(),
            });
          } else if (prevAssignment.questions) {
            let allQuestions = JSON.parse(prevAssignment.questions);
            let questionNum = allQuestions.allQuestions.length - 1;
            this.props.history.push({
              pathname: `${this.rootPath}/code-questions/course=${course}/id=${prevAssignment.id}/questionId=${allQuestions.allQuestions[questionNum].id}`,
              search: this.queryParams.toString(),
            });
          } else if (prevAssignment.course_data) {
            this.props.history.push({
              pathname: `${this.rootPath}/course-content/course=${course}/id=${prevAssignment.id}`,
              search: this.queryParams.toString(),
            });
          } else if (prevAssignment.intro) {
            this.props.history.push({
              pathname: `${this.rootPath}/introduction/course=${course}/id=${prevAssignment.id}`,
              search: this.queryParams.toString(),
            });
          } else if (prevAssignment.video) {
            this.props.history.push({
              pathname: `${this.rootPath}/chapter-video/course=${course}/id=${prevAssignment.id}`,
              search: this.queryParams.toString(),
            });
          } else {
            this.props.history.push({
              pathname: `${this.rootPath}/course=${course}`,
            });
          }
        } else {
          // if previous assignment is from a different chapter, to course home
          this.props.history.push({
            pathname: `${this.rootPath}/course=${course}`,
          });
        }
      }
    } else {
      this.props.history.push({
        pathname: `${this.rootPath}/code-questions/course=${
          this.state.course
        }/id=${id}/questionId=${parseInt(questionId) - 1}`,
        search: this.queryParams.toString(),
      });
    }
  };

  onHomeButton = () => {
    const { questionId, id } = this.props.match.params;
    const { nextAssignment, currentAssignment, course } = this.state;

    if (currentAssignment.description) {
      this.props.history.push({
        pathname: `${this.rootPath}/code-submission/course=${course}/id=${id}`,
        search: this.queryParams.toString(),
      });
    } else if (currentAssignment.end) {
      this.props.history.push({
        pathname: `${this.rootPath}/conclusion-page/course=${course}/id=${id}`,
        search: this.queryParams.toString(),
      });
    } else {
      if (
        currentAssignment.id.split("_")[0] === nextAssignment.id.split("_")[0]
      ) {
        if (nextAssignment.video) {
          this.props.history.push({
            pathname: `${this.rootPath}/chapter-video/course=${course}/id=${nextAssignment.id}`,
            search: this.queryParams.toString(),
          });
        } else if (nextAssignment.intro) {
          this.props.history.push({
            pathname: `${this.rootPath}/introduction/course=${course}/id=${nextAssignment.id}`,
            search: this.queryParams.toString(),
          });
        } else if (nextAssignment.course_data) {
          this.props.history.push({
            pathname: `${this.rootPath}/course-content/course=${course}/id=${nextAssignment.id}`,
            search: this.queryParams.toString(),
          });
        } else if (nextAssignment.questions) {
          let allQuestions = JSON.parse(nextAssignment.questions);
          this.props.history.push({
            pathname: `${this.rootPath}/code-questions/course=${course}/id=${nextAssignment.id}/questionId=${allQuestions.allQuestions[0].id}`,
            search: this.queryParams.toString(),
          });
        } else if (
          nextAssignment.description &&
          nextAssignment.description !== null
        ) {
          this.props.history.push({
            pathname: `${this.rootPath}/code-submission/course=${course}/id=${nextAssignment.id}`,
            search: this.queryParams.toString(),
          });
        } else if (currentAssignment.end) {
          this.props.history.push({
            pathname: `${this.rootPath}/conclusion-page/course=${course}/id=${nextAssignment.id}`,
            search: this.queryParams.toString(),
          });
        } else {
          this.props.history.push({
            pathname: `${this.rootPath}/course=${course}`,
          });
        }
      } else {
        // if next assignment is from a different chapter, to course home
        this.props.history.push({
          pathname: `${this.rootPath}/course=${course}`,
        });
      }
    }
  };

  onHandleChange = (id, index) => {
    const { currentQuestion } = this.state;
    currentQuestion.answers[index].checked =
      !currentQuestion.answers[index].checked;
    this.setState({
      currentQuestion,
    });
  };

  goTo = (chapterId, assignmentId) => {
    this.bodyOverflowClass(false);
    this.props.history.push(
      `${this.rootPath}/code-questions/id=${chapterId}/questionId=${parseInt(
        assignmentId
      )}`
    );
  };

  handleMessage = (response, load) => {
    let message = "";
    let color = "";
    let allowSubmit = true;

    if (load && (response.correct || response.num_attempts === 3)) {
      message = "";
      color = "";
      allowSubmit = false;
      return { message: message, color: color, allowSubmit: allowSubmit };
    }

    if (response.num_attempts < 3) {
      if (response.correct) {
        message = "Super. Du hast Punkte gesammelt.";
        color = "message-correct";
        allowSubmit = false;
      } else {
        message = "Noch nicht ganz richtig. Versuche es nochmal.";
        color = "message-more";
      }
    } else if (response.num_attempts === 3) {
      allowSubmit = false;
      if (response.correct) {
        message = "Super. Du hast Punkte gesammelt.";
        color = "message-correct";
      } else {
        if (response.correct_answers_count === 0) {
          message = "Schade, diesmal hast du leider keine Punkte gesammelt.";
          color = "message-wrong";
        } else if (response.correct_answers_count > 0) {
          message = "Du erhältst für die richtigen Antworten Punkte.";
          color = "message-partial";
        }
      }
    }
    return { message: message, color: color, allowSubmit: allowSubmit };
  };

  render() {
    const {
      currentQuestion,
      currentAssignment,
      questions,
      correct,
      selectedAnswers,
      allowSubmit,
      totalPoints,
      maxQuestions,
      currentTaskNum,
      showLoading,
    } = this.state;
    const { id, course } = this.props.match.params;
    return (
      <AppContext.Consumer>
        {(context) => {
          if (context === undefined) {
            throw new Error("AppConsumer must be used within a AppProvider");
          }

          let chapter_header = "Chapter Header";
          if (course) {
            chapter_header = context.tutorials.find((p) => p.course === course)
              .chapters[id.split("_")[0] - 1];
          }
          return (
            <React.Fragment>
              {currentQuestion && (
                <React.Fragment>
                  <section className="content question">
                    <div className="container-fluid">
                      <div className="container-limit my-limit">
                        <div className="progressBar">
                          <div className="progress-table-bar">
                            {/* <div>
                              <Progress
                                value={currentTaskNum + 1}
                                max={maxQuestions}
                              >
                                <span>
                                  {currentTaskNum + 1}/{maxQuestions}
                                </span>
                              </Progress>
                            </div> */}
                            <div className="progress-div">
                              <Progress
                                value={currentTaskNum + 1}
                                max={maxQuestions}
                              ></Progress>
                              <div className="progress-span">
                                {currentTaskNum + 1}/{maxQuestions}
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* remove hard coded header and replace with chapter's header */}
                        {/* { assignment && assignment.title && } */}
                        <div className="task-item-lightheader">
                          Kapitel {id.split("_")[0] - 0}: {chapter_header}
                        </div>
                        <div className="task-item-mainheader">
                          {currentAssignment ? currentAssignment.title : ""}
                        </div>

                        <div className="question-wrapper">
                          <Question
                            correct={correct}
                            key={currentQuestion.id}
                            question={currentQuestion}
                            onChange={this.onHandleChange}
                            type="tutorial"
                            selectedAnswers={selectedAnswers}
                            showAnswers={!allowSubmit}
                            course={this.state.course}
                          />

                          {this.state.message && !(this.state.message === "") && (
                            <div
                              className={`question-info ${this.state.color}`}
                            >
                              <span>{this.state.message}</span>
                            </div>
                          )}
                          {!this.state.allowSubmit && (
                            <div className="question-info message-answers">
                              <span>
                                Musterlösung - dies sind alle korrekten
                                Antworten!
                              </span>
                            </div>
                          )}
                          <div className="submit-parent-wrapper">
                            {questions.length > 0 && (
                              <div className="button-wrapper">
                                <div className="points-panel-wrapper">
                                  <span>Punkte</span>
                                  <div className="points-panel">
                                    {totalPoints}
                                  </div>
                                </div>
                                {/* questions.indexOf(currentQuestion) !== 0 && */}
                                {
                                  <Button
                                    onClick={this.onPreviousButton}
                                    href="#"
                                    className="firstBtn"
                                    label="Vorherige Frage"
                                  />
                                }
                                {this.state.allowSubmit && (
                                  <Button
                                    onClick={this.submitQuestions}
                                    href="#"
                                    className="secondBtn primary-color-lightblue-foreground"
                                    label="Auswerten"
                                  />
                                )}
                                {questions &&
                                  questions.indexOf(currentQuestion) + 1 <
                                    questions.length && (
                                    <Button
                                      onClick={this.onNextButton}
                                      href="#"
                                      className="thirdBtn"
                                      label="Nächste Frage"
                                    />
                                  )}
                                {questions &&
                                  questions.indexOf(currentQuestion) + 1 ===
                                    questions.length && (
                                    <Button
                                      onClick={this.onHomeButton}
                                      href="#"
                                      className="thirdBtn"
                                      label={`${
                                        currentTaskNum + 1 === maxQuestions
                                          ? "Weiter"
                                          : "Nächste Frage"
                                      }`}
                                    />
                                  )}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </React.Fragment>
              )}
              {(showLoading || !currentQuestion) && <Loading />}
            </React.Fragment>
          );
        }}
      </AppContext.Consumer>
    );
  }
}

export default CodeQuestions;
