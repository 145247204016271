import React, { Component } from 'react';
import { Tooltip } from 'reactstrap';

class HoverTooltip extends Component {
    state = {
        tooltipOpen: false
    }

    toggle = () => {
        this.setState({
            tooltipOpen: !this.state.tooltipOpen
        });
    }

    render() {
        const { name, message, placement } = this.props;
        return (
            <React.Fragment>
                <Tooltip placement={placement || 'bottom'} isOpen={this.state.tooltipOpen} target={name} toggle={this.toggle} className="hover-text"> 
                  <span> {message} </span>
                </Tooltip>
            </React.Fragment>
        );
    }
}

export default HoverTooltip;