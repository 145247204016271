import React, { useEffect, useState } from "react";
import b_04_content_md from "./04_b_content.md";
import Markdown from "../common/markdown";
// Makes use of : https://github.com/react-syntax-highlighter/react-syntax-highlighter/blob/master/AVAILABLE_LANGUAGES_HLJS.MD
import Loading from "../common/loading";
import SvgFuture from "./svgComponents/FuturisGrafik";
import SvgMembers from "./svgComponents/Members";
import Button from "../common/button";

const InfoPanel = (props) => {
  const { content, next, back, isArgumentPositive } = props;
  const [panelContent, setPanelContent] = useState(props.content);
  const [mdContent, setMdContent] = useState();
  const [definition, setDefinition] = useState();
  const [showDefinitionPanel, setShowDefinitionPanel] = useState(false);
  // const [audioFile, setAudioFile] = useState("1_01_digital-retina.wav");
  const [b_04_content, setB04Content] = useState("Loading...");
  const [definitionAudioFile, setDefinitionAudioFile] = useState();
  const [playing, setPlaying] = useState(false);
  const [definitionPlaying, setDefinitionPlaying] = useState(false);
  const [definitionPrompt, setDefinitionPrompt] = useState(true);
  const [showAudioPlayer, setShowAudioPlayer] = useState(false);
  const [showMemberPanelAudioPlayer, setShowMemberPanelAudioPlayer] =
    useState(false);
  const audioBase =
    "https://bwki1.fra1.digitaloceanspaces.com/courseplatform_files/audio";
  const futureAudio = "00_Horbuch_Futuris.wav";
  const audioCategories = {
    MEMBERS: "Council",
    SCENARIOS: "Scenarios",
    FUTURESVG: "Futuris-Maps",
  };
  Object.freeze(audioCategories);

  useEffect(() => {
    setShowDefinitionPanel(false);
    let mdContent = content.content;
    let newMarkDown = mdContent.replaceAll("URL_FOR_IMAGES", "/images/ethics");
    if (content.id === "end" && isArgumentPositive) {
      newMarkDown = newMarkDown.replaceAll("positive", "negative");
      newMarkDown = newMarkDown.replaceAll("negativen", "positiven");
    }
    setMdContent(newMarkDown);

    // Load second half of the content end/04
    fetch(b_04_content_md)
      .then((res) => res.text())
      .then((text) => {
        // console.log(text);

        setB04Content(text);
      });
  }, [content]);

  // const openDefinition = (definition, index) => {
  //   console.log(definition, index);
  //   setDefinition(definition);
  //   setShowDefinitionPanel(true);
  // };

  const setAudioSource_new = (type, audioFileName) => {
    setDefinitionAudioFile(`${audioBase}/${type}/${audioFileName}`);
  };

  const handleSvgElementClick = (id, type) => {
    let tempId = id;

    // console.log("tempId", tempId);
    // console.log("check", id, type);
    // updated definitionPrompt based on defPrompt
    if (type === audioCategories.MEMBERS) {
      setDefinitionPrompt(false);
      setShowMemberPanelAudioPlayer(true);
    } else if (type === audioCategories.FUTURESVG) {
      setDefinitionPrompt(true);
      setShowMemberPanelAudioPlayer(false);
    } else {
      setDefinitionPrompt(false);
      setShowMemberPanelAudioPlayer(false);
    }

    // reset audio?
    setShowAudioPlayer(false);
    if (content.definitions) {
      let selectedDefinition = content.definitions.find(
        (item) => item.id == tempId
      );
      // console.log(selectedDefinition);
      if (selectedDefinition) {
        setAudioSource_new(type, selectedDefinition.audioFile);
        setDefinition(selectedDefinition);
        setShowDefinitionPanel(true);
      }
    }
  };

  const handleCheckListSvgClick = () => {
    setShowMemberPanelAudioPlayer(false);
    setDefinitionPrompt(false);
    setDefinition(content.definitions[0]);
    setShowDefinitionPanel(true);
  };

  const closeDefinition = () => {
    if (definitionPlaying) {
      // definitionAudioFile.pause()
      // definitionAudioFile.currentTime = 0
      setDefinitionPlaying(false);
    } else {
      // definitionAudioFile.currentTime = 0
      setDefinitionPlaying(false);
    }
    setShowDefinitionPanel(false);
    setDefinition(undefined);
    setDefinitionPrompt(true);
  };

  const playFutureAudio = () => {
    console.log("audio clicked");
    setAudioSource_new(audioCategories.FUTURESVG, futureAudio);
    setShowAudioPlayer(true);
    setShowDefinitionPanel(false);
  };

  const handleAudioOnPrompt = () => {
    // set definition audio
    setShowAudioPlayer(true);
    setShowDefinitionPanel(false);
  };

  let sourceDoc = `<audio style='width:100%!important' autoplay controls="true" src=${definitionAudioFile}  />`;
  let memberSourceDoc = `<audio style='width:100%!important' controls="true" src=${definitionAudioFile}  />`;

  return (
    <React.Fragment>
      <section className="main-wrapper info-panel">
        {content && content.title && (
          <div className="main-header primary-color-lightgreen-foreground">
            {content.title}
          </div>
        )}
        <div className="container-limit my-limit">
          <div className="intro-section" style={{ minHeight: "auto" }}>
            {mdContent && (
              <React.Fragment>
                <div className="intro-wrapper" style={{ minHeight: "auto" }}>
                  <Markdown
                    className={`intro ethics ${
                      content.columns ? "columns-2" : ""
                    }`}
                    children={mdContent}
                  />
                </div>
              </React.Fragment>
            )}
            {content.definitions && content.file_id === "06_file" && (
              <img
                className="checklist-img"
                src="/images/ethics/06_end_Checkliste.svg"
                onClick={handleCheckListSvgClick}
              />
            )}
          </div>
          {content.definitions && content.file_id === "02_file" && (
            <div className="svgfuture-wrapper">
              <SvgFuture
                handleElementClick={handleSvgElementClick}
                category={audioCategories.FUTURESVG}
              />
              {showAudioPlayer && (
                <div className="audio-iframe-wrapper">
                  <div className="audio-title">
                    {definition ? definition.title : ""}
                  </div>
                  <iframe
                    className="audio-iframe"
                    srcDoc={sourceDoc}
                    frameBorder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                </div>
              )}
              <div
                className={`audio-wrapper ${showAudioPlayer ? "bt-5" : ""}`}
                onClick={playFutureAudio}
              >
                <div>
                  {playing ? "Klicke zum pausieren" : "Klicke zum Abspielen"}
                </div>
                <img src="/images/ethics/audio.svg" />
              </div>
            </div>
          )}
          {content.definitions && content.file_id === "03_Rat_der_Denkenden" && (
            <div className="members-wrapper">
              <ul className="member-speech-list">
                {content.definitions.map((definitionItem, index) => {
                  return (
                    <li className="speech-item">
                      <div className="speech-header">
                        {index % 2 == 1 ? "Der" : "Die"} Denkende für{" "}
                        <span className="primary-color-lightgreen-foreground">
                          {definitionItem.name}
                        </span>
                      </div>
                      <div
                        className="img primary-color-lightgreen-background"
                        onClick={handleSvgElementClick.bind(
                          this,
                          definitionItem.id,
                          audioCategories.MEMBERS
                        )}
                      >
                        <img src="/images/ethics/play.svg" />
                      </div>
                    </li>
                  );
                })}
              </ul>

              <SvgMembers
                handleElementClick={handleSvgElementClick}
                category={audioCategories.MEMBERS}
              />
            </div>
          )}
          {/* Deutschen Ethikrat Popups */}
          {content.definitions && content.file_id === "04_file" && (
            <div>
              <div className="members-wrapper">
                <ul className="fields-container">
                  {content.definitions.map((definitionItem, index) => {
                    return (
                      <li
                        key={index}
                        className="field-item"
                        onClick={handleSvgElementClick.bind(
                          this,
                          definitionItem.id,
                          null
                        )}
                      >
                        <div className="img-wrapper primary-color-lightgreen-background">
                          <img className="img" src={definitionItem.imageURL} />
                        </div>
                        <div className="field-header">
                          <span className="primary-color-lightgreen-foreground">
                            {" "}
                            {definitionItem.name}{" "}
                          </span>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>

              <Markdown className="intro ethics" children={b_04_content} />
            </div>
          )}
        </div>
        <div className="submit-parent-wrapper">
          <div className="button-wrapper">
            {content.intro && content.index === 0 && (
              <>
                <Button
                  onClick={(e) => {
                    back();
                  }}
                  href="#"
                  className="firstBtn"
                  label="Zurück"
                />
                <Button
                  href="#"
                  className="thirdBtn"
                  onClick={(e) => next()}
                  label="Los geht’s"
                ></Button>
              </>
            )}
            {(content.end || content.index !== 0) && (
              <>
                {
                  <Button
                    onClick={(e) => {
                      back();
                    }}
                    href="#"
                    className="firstBtn"
                    label="Zurück"
                  /> /*Vorherige Frage */
                }
                {
                  <Button
                    onClick={(e) => {
                      next();
                    }}
                    href="#"
                    className="thirdBtn"
                    label="Weiter"
                  /> /*Nächste Frage */
                }
              </>
            )}
          </div>
        </div>
      </section>
      {showDefinitionPanel && definition && (
        <div className="overlay-panel-container">
          <div
            className={`overlay-panel ${definitionPrompt ? "prompt" : ""} ${
              showMemberPanelAudioPlayer ? "include-audio" : ""
            } ${definition.imageURL ? "with-img" : ""}`}
          >
            <div
              className="close"
              style={{ gridRow: "1", top: "-2rem" }}
              onClick={closeDefinition}
            ></div>
            <div className="ethics-overlay-header">
              {definition.imageURL && (
                <img
                  className="background-image-style"
                  src={definition.imageURL}
                />
              )}
              <div
                className="definition-header primary-color-lightgreen-foreground"
                style={{ gridRow: "1", marginLeft: "0rem" }}
              >
                {definition.title}
              </div>
            </div>
            {/* <audio src={setDefinitionAudioFile} /> */}
            {definitionPrompt ? (
              <>
                <div className="options-wrapper">
                  <div className="options-header">
                    Du kannst Informationen über die neue Technologie selbst
                    lesen
                    <br />
                    oder sie dir anhören. Wie möchtest du fortfahren?
                  </div>
                  <div className="options button-wrapper">
                    <Button
                      onClick={(e) => {
                        handleAudioOnPrompt();
                      }}
                      href="#"
                      className=""
                      label="Hören"
                    />
                    <Button
                      onClick={(e) => {
                        setDefinitionPrompt(false);
                      }}
                      href="#"
                      className=""
                      label="Lesen"
                    />
                  </div>
                </div>
              </>
            ) : (
              <div
                className="section-wrapper"
                style={{ gridRow: "2", padding: "0rem 3rem" }}
              >
                {definition.sections &&
                  definition.sections.map((section) => (
                    <div className="section">
                      <p>
                        <span className="section-title">{section.title}</span>
                        {section.type === "md" ? (
                          <Markdown children={section.description} />
                        ) : (
                          <>{" " + section.description}</>
                        )}
                      </p>
                      {section.link && (
                        <a target="_blank" href={section.link}>
                          {section.link}
                        </a>
                      )}
                    </div>
                  ))}
              </div>
            )}
            {showMemberPanelAudioPlayer && (
              <div className="audio-iframe-wrapper">
                <div className="audio-title">
                  Klicke auf abspielen und höre dir zusätzliche Informationen
                  von den Ratsmitgliedern an!
                </div>
                <iframe
                  className="audio-iframe"
                  srcDoc={memberSourceDoc}
                  frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            )}
            {/* <div className="audio-wrapper" onClick={playDefinitionAudio}>
                              <div>{definitionPlaying?'Klicke zum pausieren':'Klicke zum Abspielen'}</div>
                              <img src="/images/ethics/play.svg"  className="primary-color-lightgreen-background"/>
                            </div> */}
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

InfoPanel.propTypes = {};

export default InfoPanel;
