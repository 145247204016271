import React, { Component } from "react";
import auth from "../../services/authService";
import { Redirect } from "react-router-dom";
import Login from "./login";
import Register from "./register";
import ForgotPassword from "./forgot-password";
import LoginClasscode from "./login-classcode";
import * as alert from "../../alert.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import classService from "../../services/classService";
import { schools } from "../../schools.json";

class Auth extends Component {
  state = {
    activeTab: "login",
    registerTeam: false,
    olderWarning: false,
    youngerWarning: false,
    showAlert: true,
    showPanel: true,
    modal: null,
    options: schools.map((school) => school),
    errors: "",
  };

  showPanel = (event) => {
    event.preventDefault();
    this.setState({
      showPanel: true,
      modal: {
        title: "KI Schule des Jahres",
      },
    });
  };

  closeDefinition = () => {
    this.setState({
      showPanel: false,
      modal: null,
    });
  };

  componentDidMount() {
    if (alert.auth.enabled) {
      this.setState({
        showAlert: true,
      });
    }

    this.setSchoolsAsOptions();
  }

  setSchoolsAsOptions() {
    classService
      .getAllSchools()
      .then((schools) => {
        this.setState({ options: schools });
      })
      .catch((err) => {
        console.log(err);
        let error = "Es ist ein unerwarteter Fehler aufgetreten.";
        if (err.response && err.response.status === 400) {
          error = err.response.data.error;
        }
        this.setState({ errors: error });
      });
  }

  changeTabs = (tab) => {
    this.setState({
      activeTab: tab,
    });
  };

  changeIsTeam = (value) => {
    this.setState({
      registerTeam: value,
    });
  };

  onShowOlderWarning = (show) => {
    this.setState({
      olderWarning: show,
    });
  };

  onShowYoungerWarning = (show) => {
    this.setState({
      youngerWarning: show,
    });
  };

  removeAlert = () => {
    this.setState({
      showAlert: false,
    });
  };

  render() {
    if (auth.getCurrentUser()) return <Redirect to="/home" />;

    const { activeTab, registerTeam, olderWarning, youngerWarning, showAlert, showPanel, modal, options, errors } =
      this.state;
    return (
      <div className="page">
        {alert.auth.enabled && (
          <div className={`myAlert auth ${showAlert ? "show" : ""}`}>
            <small dangerouslySetInnerHTML={{ __html: alert.auth.message }} />
            <FontAwesomeIcon className="icon" onClick={this.removeAlert} icon={faTimes} />
          </div>
        )}
        <div
          className={`login ${activeTab === "register" ? (registerTeam ? "activeTeam" : "active") : ""} ${
            activeTab === "register" && olderWarning ? "warning-active" : "" && youngerWarning ? "warning-active" : ""
          }`}
        >
          <div className="nav-login">
            <a
              onClick={() => this.changeTabs("login")}
              className={`${activeTab === "login" ? "active" : ""} user-login`}
            >
              <span>Anmelden</span>
            </a>
            <a
              onClick={() => this.changeTabs("register")}
              className={`${activeTab === "register" ? "active" : ""} team-login`}
            >
              <span>Registrieren</span>
            </a>
          </div>
          <div className="content-login">
            {errors.length > 0 && <div className="text-danger">{errors}</div>}
            <Login changeTabs={this.changeTabs} activeTab={activeTab} location={this.props.location} />
            <Register
              changeIsTeam={this.changeIsTeam}
              activeTab={activeTab}
              location={this.props.location}
              olderWarning={olderWarning}
              youngerWarning={youngerWarning}
              onShowOlderWarning={this.onShowOlderWarning}
              onShowYoungerWarning={this.onShowYoungerWarning}
              showPanel={this.showPanel}
              options={options}
            />
            <ForgotPassword activeTab={activeTab} location={this.props.location} />
            <LoginClasscode activeTab={activeTab} location={this.props.location} />
          </div>
        </div>
        {showPanel && modal && (
          <div className="overlay-panel-container">
            <div className="overlay-panel">
              <div className="definition-header">{modal.title}</div>
              <div className="section-wrapper">
                <div className="section">
                  <p className="text-justify">
                    Bewältigt den KI-Kurs gemeinsam als Klasse, AG oder Schülerforschungszentrum und nehmt am Rennen um
                    den Titel <b>"KI-Schule des Jahres"</b> teil. Die Einrichtung, die während eines Schuljahres
                    (1.August bis 31.Juli) am aktivsten am KI-Kurs teilnimmt, erhält den Titel und einen attraktiven
                    Sachpreis.
                  </p>
                  <p className="text-justify">Weitere Infos findest du unter dem Menüpunkt „KI-Schule des Jahres“.</p>
                </div>
              </div>
              <div className="close" onClick={this.closeDefinition}></div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default Auth;
