import React from "react";
import { Link } from "react-router-dom";
import MenuPage from "./MenuPage.jsx";

const PioneerWomenQuizIntro = () => {
  const heading = "Quiz";
  const text = "Teste dein Wissen aus dem Museumsrundgang!";
  const buttons = [
    { label: "Starte das Quiz!", link: "/milestones/pioneer-women/quiz/id=1" }
  ];
  const image = "/images/pioneer-women/robot_quiz.svg";

  return (
    <MenuPage heading={heading} text={text} buttons={buttons} image={image} />
  );
};

export default PioneerWomenQuizIntro;
