import { optionHandlers } from "codemirror";
import React, { useEffect, useState } from "react";
import assignmentsService from "../../services/assignmentsService";
import "./ethics.css";
import InfoPanel from "./infoPanel";
import QuestionsPanel from "./questionsPanel";
import Loading from "../common/loading";
import Button from "../common/button";

const EthicsCourseMain = (props) => {
  const [course, setCourse] = useState();
  const [panels, setPanels] = useState([]);
  const [currentPanel, setCurrentPanel] = useState();
  const [showVideo, setShowVideo] = useState(false);
  const [showPanels, setShowPanels] = useState(false);
  const [flowsPanel, setFlowsPanel] = useState(props.flows);
  const [allQuestions, setAllQuestions] = useState();
  const [showQuestionsPanel, setShowQuestionsPanel] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    initEthicsCourse();
    return () => {};
  }, []);

  const existsJsonAttachment = (section, id) => {
    let check = section.find((item) => {
      if (item.file_type === "json") {
        let fileIdData = item.file_id.split("_");
        // console.log(id, fileIdData[0]);
        if (fileIdData[0] === id[0]) {
          return true;
        }
      }
      return false;
    });
    return check;
  };
  const initEthicsCourse = async () => {
    try {
      let ethicsCourseRes = await assignmentsService.getCourses("ethics");
      if (ethicsCourseRes.status !== 200) {
        console.log("something went wrong!");
        // TODO: show message
      }
      let tempCourse = ethicsCourseRes.data.course;
      setCourse(tempCourse);

      //setup screens
      if (props.intro) {
        // console.log(tempCourse.intro);
        let tempMDs = tempCourse.intro.filter(
          (item) => item.file_type === "md"
        );

        // console.log(tempMDs);
        // find title , json definition files for each panel
        tempMDs.map((mdItem, index) => {
          let id = mdItem.file_id.split("_");
          let titleItem = tempCourse.intro.find(
            (item) => item.file_id === `${id[0]}_title`
          );
          if (titleItem) mdItem.title = titleItem.content;

          if (id[0] == "01") mdItem.columns = true;

          let popupItemsData = existsJsonAttachment(tempCourse.intro, id);

          if (popupItemsData)
            mdItem.definitions = JSON.parse(
              popupItemsData.content
            ).allDefinitions;
          mdItem.index = index;
          mdItem.intro = true;
        });
        setPanels(tempMDs);
        setCurrentPanel(tempMDs[0]);
        setShowVideo(false);
        setShowPanels(true);
      } else if (props.conclusion) {
        // console.log(tempCourse.end);
        let tempMDs = tempCourse.end.filter((item) => item.file_type === "md");

        // find title , json definition files for each panel
        tempMDs.map((mdItem, index) => {
          let id = mdItem.file_id.split("_");
          let titleItem = tempCourse.end.find(
            (item) => item.file_id === `${id[0]}_title`
          );
          if (titleItem) mdItem.title = titleItem.content;

          let popupItemsData = existsJsonAttachment(tempCourse.end, id);
          if (popupItemsData)
            mdItem.definitions = JSON.parse(
              popupItemsData.content
            ).allDefinitions;
          mdItem.index = index;
          mdItem.end = true;
          // TODO: review, move to executor?
          if (id[0] == "01") mdItem.columns = true;
        });

        let tempQuestions = tempCourse.end.filter(
          (item) => item.file_type === "json" && item.file_id === "questions"
        );
        if (tempQuestions) {
          let tempConlusionQuestions = JSON.parse(tempQuestions[0].content);
          setAllQuestions(tempConlusionQuestions);
        }
        setPanels(tempMDs);
        setCurrentPanel(tempMDs[0]);
        setShowVideo(false);
        setShowPanels(true);
        console.log(tempMDs);
      }
      setLoading(false);
    } catch (error) {
      console.log("error", error);
      setLoading(false);
    }
  };

  const onPathClick = (pathId) => {
    props.history.push({
      pathname: `/ethics/ethically-questionable/coursepath=${pathId}`,
    });
  };

  const onPanelBack = () => {
    if (currentPanel.index === 0) {
      if (props.intro) {
        // props.history.push({pathname:'/ethics'})
        props.history.push({
          pathname: "/ethics/ethically-questionable/intro-video",
        });
        setShowPanels(false);
        // setShowVideo(true)
      } else if (props.conclusion) {
        props.history.push({
          pathname: "/ethics/ethically-questionable/paths",
        });
      }
    } else {
      if (
        props.conclusion &&
        currentPanel.index == panels.length - 2 &&
        allQuestions
      ) {
        setShowPanels(false);
        setShowQuestionsPanel(true);
      } else {
        let tempNewPanel = panels[currentPanel.index - 1];
        setCurrentPanel(tempNewPanel);
      }
    }
  };

  const onPanelNext = () => {
    if (currentPanel.index == panels.length - 1) {
      if (props.intro) {
        props.history.push({
          pathname: "/ethics/ethically-questionable/paths",
        });
      } else if (props.conclusion) {
        props.history.push({ pathname: "/ethics" });
      }
    } else {
      if (
        props.conclusion &&
        currentPanel.index == panels.length - 3 &&
        allQuestions
      ) {
        setShowPanels(false);
        setShowQuestionsPanel(true);
      } else {
        let tempNewPanel = panels[currentPanel.index + 1];
        setCurrentPanel(tempNewPanel);
      }
    }
  };

  const onQuestionsNext = () => {
    setShowQuestionsPanel(false);
    setShowPanels(true);
    let tempNewPanel = panels[panels.length - 2];
    setCurrentPanel(tempNewPanel);
  };

  const onQuestionsBack = () => {
    setShowQuestionsPanel(false);
    setShowPanels(true);
    let tempNewPanel = panels[panels.length - 3];
    setCurrentPanel(tempNewPanel);
  };

  return (
    <>
      {!loading && (
        <>
          {/* { showVideo &&
                    <>
                     <section className="main-wrapper info-panel">
                        <div className="main-header primary-color-lightgreen-foreground">Willkommen zu Ethisch fragwürdig!</div>
                            <div className="container-limit my-limit">
                                <div className="video-wrapper">
                                    <iframe title='entry-video' height='720' widht='640' src='https://player.vimeo.com/video/688447379?h=e2b17a02af' frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen>
                                    </iframe>
                                </div>
                                <div className="submit-parent-wrapper">
                                <div className="button-wrapper">
                                    <Button onClick={e => { setShowVideo(false); setShowPanels(true) }} href="#" className="thirdBtn" label="Weiter" /> 
                                </div>
                            </div>
                        </div>
                     </section>
                        
                    </>
                } */}
          {showPanels && currentPanel && (
            <InfoPanel
              content={currentPanel}
              back={onPanelBack}
              next={onPanelNext}
            />
          )}
          {showQuestionsPanel && allQuestions && (
            <QuestionsPanel
              questions={allQuestions}
              back={onQuestionsBack}
              next={onQuestionsNext}
              title={"Quiz"}
              chapter={3} // the quiz is conlusion for now is chapter 3 by default
              last={currentPanel.index == panels.length - 2 ? true : false}
            />
          )}
          {flowsPanel && (
            <div className="main-wrapper">
              <div className="ethics-sub-header mb-2">
                Der Them-O-Mat hat eine hohe Relevanz für folgende Themen
                berechnet.
              </div>
              <div className="ethics-sub-header mb-5">
                Wähle ein Thema aus, um den Rat der Denkenden über dieses Thema
                beraten zu lassen:
              </div>
              <div className="path-options">
                <div
                  className="path-option"
                  onClick={onPathClick.bind(this, 1)}
                >
                  <div className="path-option-title primary-color-lightgreen-foreground">
                    Pflegeroboter
                  </div>
                  <img src="/images/ethics/01_flow1_icone v2_hands.svg"></img>
                </div>
                <div
                  className="path-option"
                  onClick={onPathClick.bind(this, 2)}
                >
                  <div className="path-option-title primary-color-lightgreen-foreground">
                    Aura-Level-System
                  </div>
                  <img src="/images/ethics/02_flow2_icone v2_Dron.svg"></img>
                </div>
              </div>
            </div>
          )}
        </>
      )}
      {loading && <Loading />}
    </>
  );
};

export default EthicsCourseMain;
