import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckSquare, faTimes, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'

const CodeResponse = ({ results }) => {
    return (
        <React.Fragment>
            {results && results.map((result, index) =>
                <div key={index} className={'code-response-wrapper ' + (result.passed ? "success" : "error")}>

                    <p>
                        {result.passed &&
                            // <i className="fa fa-check-square color-square"></i>
                            <FontAwesomeIcon className="icon" icon={faCheckSquare} />
                        }
                        {!result.passed &&
                            // <i className="fa fa-times"></i>
                            <FontAwesomeIcon className="icon" icon={faTimes} />
                        }
                        {result.name}
                    </p>
                    {result.hint &&
                        <p className="hint-wrapper">
                            {/* <i className="fa fa-exclamation-triangle color-triangle"></i>  */}
                            <FontAwesomeIcon className="icon" icon={faExclamationTriangle} />
                            {result.hint}
                        </p>
                    }
                    {result.print && <p className='std-output'>{result.print}</p>}
                </div>
            )}
        </React.Fragment>
    );
}

export default CodeResponse;
