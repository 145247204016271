import React, { Component } from "react";
import authService from "../services/authService";
import { Link } from "react-router-dom";
import * as alert from "../alert.json";

class aiSchool extends Component {
  state = {
    winners: [],
  };

  componentDidMount() {
    let winners = [
      {
        title: "Coppernicus-Gymnasium Norderstedt",
        description: "Norderstedt, Schleswig-Holstein",
        subHeader: "GEWINNER 2023",
        link: "https://coppernicus.de/de/home/",
      },
      {
        title: "Anne-Frank-Schule",
        description: "Molbergen, Niedersachsen",
        subHeader: "GEWINNER 2024",
        link: "https://www.a-f-s.info/",
      },
      {
        title: "Hier könnte DEINE Schule stehen!\n",
        description: "",
        subHeader: "GEWINNER 2025",
        link: "",
      },
    ];

    let prevWinners = [
      {
        year: "2022",
        school: "Gymnasium Neuenbürg, Baden-Württemberg",
      },
      {
        year: "2021",
        school: "Gymnasium St. Augustin, Grimma, Sachsen",
      },
      {
        year: "2020",
        school: "Gymnasium St. Augustin, Grimma, Sachsen",
      },
      {
        year: "2019",
        school: "Saarpfalz-Gymnasium, Bad Homburg, Saarland",
      },
    ];

    this.setState({
      winners: winners,
      prevWinners: prevWinners,
    });
  }

  removeAlert = () => {
    this.setState({
      showAlert: false,
    });
  };
  render() {
    // const user = authService.getCurrentUser();

    // if (!user) this.props.history.push('/auth')
    return (
      <React.Fragment>
        <section className="main-content">
          <h1
            className="homepage header1 font-main-header text-center"
            style={{ marginTop: "2rem" }}
          >
            KI-Schule des Jahres
          </h1>
          <h3 className="homepage header2 font-sub-header text-center mb-0">
            Bewältigt den KI-Kurs gemeinsam als Klasse, AG oder
            Schülerforschungszentrum und nehmt am Rennen um den Titel "
            <b>KI-Schule des Jahres</b>" teil. Die Einrichtung, die während
            eines Schuljahres (1.August bis 31.Juli) am aktivsten am KI-Kurs
            teilnimmt, erhält den Titel und einen attraktiven Sachpreis. Die
            Aktivität ermitteln wir über die Anzahl der bewältigten Aufgaben
            normiert auf die Anzahl der teilnehmenden Schüler:Innen pro Schule.
            Um allen Schulen die Möglichkeit auf einen Gewinn zu geben, kann die
            Siegerschule in den zwei Folgejahren nicht erneut den Preis
            gewinnen. Damit der Fleiß und Ehrgeiz dennoch belohnt wird,
            überreichen wir den TOP 5 der teilgenommenen Schulen eine Urkunde.
          </h3>
          <h3 className="homepage header2 font-sub-header text-center">
            Du willst mitmachen? Markiere bei der Registrierung oder im
            Anschluss in deinem Benutzerprofil, dass du teilnehmen möchtest und
            informiere uns an welcher Schule du Schüler:in bist.
          </h3>

          <div className="tiles-container aischool-tiles">
            {this.state.winners.length > 0 &&
              this.state.winners.map((school) => {
                if (school.link === "") {
                  return (
                    <div className="aischool-tile">
                      <div className="tile-sub-header">{school.subHeader}</div>
                      <div className="tile-header last-tile">
                        <div className="">{school.title}</div>
                        <div>{school.description}</div>
                      </div>
                    </div>
                  );
                } else {
                  return (
                    <a
                      href={school.link}
                      target="_blank"
                      className="aischool-tile link"
                    >
                      <div className="aischool-tile-inside">
                        <div className="tile-sub-header">
                          {school.subHeader}
                        </div>
                        <div className="tile-header">{school.title}</div>
                        <div className="tile-footer">{school.description}</div>
                      </div>
                    </a>
                  );
                }
              })}
          </div>
          <div className="container-limit">
            <h3 className="homepage header3 font-main-header mt-5 mb-3">
              Frühere KI-Schulen des Jahres
            </h3>
            <div className="table-responsive"></div>
            <table className="table">
              <tbody>
                {this.state.prevWinners &&
                  this.state.prevWinners.map((school) => (
                    <tr>
                      <td>
                        <h4>{school.year}</h4>
                      </td>
                      <td>
                        <h4>{school.school}</h4>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default aiSchool;
