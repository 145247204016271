import React from 'react'
import PropTypes from 'prop-types'
import './loader.css'

function Loader(props) {
    return (
        <div className='loader'>
            
            <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
            </div>
        </div>
    )
}

Loader.propTypes = {

}

export default Loader
