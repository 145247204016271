import React from "react";
import ReactMarkdown from "react-markdown";
import remarkMath from "remark-math";
import remarkGfm from "remark-gfm";
import rehypeMathjax from "rehype-mathjax";
import rehypeRaw from "rehype-raw";
import { UnControlled as CodeMirror } from "react-codemirror2";
import "codemirror/lib/codemirror.css";
import "codemirror/theme/material.css";
import "codemirror/keymap/sublime";
import "codemirror/theme/elegant.css";
import "codemirror/mode/python/python";
import { Link } from "react-router-dom";

/**
 * React component to render markdown files using react-markdown.
 *
 * Supports rendering math formulas (as SVG) with [remark-math](https://github.com/remarkjs/remark-math/)
 * and [rehype-mathjax](https://github.com/remarkjs/remark-math/).
 * Renders `<pre><code> </code></pre>` blocks inside a readonly CodeMirror tag.
 *
 * Example usage: <Markdown children={your-markdown-string} />
 *
 * @param {object} props - React props for this component. Props are passed through to react-markdown component, after adjusting some default values.
 * @return {JSXElement} The React component/JSXElement.
 */
const Markdown = (props) => {
  // Redefine how to handle code:
  const components = {
    a: ({ node, href, children, ...props }) => {
      // Use <Link> for internal links, in such cases href must start with "/app/<path>".
      if (href.startsWith("/app")) {
        href = href.replace("/app", "");
        return <Link to={href}>{children}</Link>;
      }
      // For all others, insert target="_blank" and rel="noopener noreferrer".
      return (
        <a href={href} target="_blank" rel="noopener noreferrer" {...props}>
          {children}
        </a>
      );
    },
    code: ({ node, inline, ...props }) => {
      if (inline) {
        return <code {...props} />;
      } else {
        const trimEmptyLine = (value) => {
          const lines = value.split("\n");
          if (lines[lines.length - 1] === "") {
            return lines.slice(0, -1).join("\n");
          } else {
            return value;
          }
        };
        return (
          <CodeMirror
            className="intro-code-block"
            options={{
              theme: "elegant",
              keyMap: "sublime",
              mode: "python",
              lineNumbers: true,
              readOnly: true,
            }}
            value={trimEmptyLine(node.children[0].value)}
          />
        );
      }
    },
  };

  let props_ = { ...props };

  // Set default props_ for ReactMarkdown component
  if (!props_.hasOwnProperty("components")) {
    props_.components = components;
  }
  if (!props_.hasOwnProperty("linkTarget")) {
    props_.linkTarget = "_blank";
  }
  if (!props_.hasOwnProperty("remarkPlugins")) {
    props_.remarkPlugins = [remarkMath, remarkGfm];
  }
  if (!props_.hasOwnProperty("rehypePlugins")) {
    props_.rehypePlugins = [rehypeMathjax, rehypeRaw];
  }
  if (!props_.hasOwnProperty("allowDangerousHtml")) {
    props_.allowDangerousHtml = true;
  }
  if (!props_.hasOwnProperty("skipHtml")) {
    props_.skipHtml = false;
  }

  return <ReactMarkdown {...props_} />;
};

export default Markdown;
