import http from "./httpService";

const { REACT_APP_WEB_API_URL: web_api_url } = process.env;

export async function register(
  username,
  password,
  confirmPassword,
  school,
  postNumber,
  student,
  teacher,
  others,
  fullName,
  email,
  parentEmail,
  is_ai_school,
  ageBelow18,
  ageAbove18,
  eventsCheck
) {
  let ageBar = true;
  if (ageBelow18) {
    ageBar = false;
  }
  return await http.post(`${web_api_url}/user`, {
    username,
    password,
    confirmPassword,
    school,
    postNumber,
    // captcha,
    student,
    teacher,
    others,
    fullName,
    //birthday,
    email,
    parentEmail,
    is_ai_school,
    ageBar,
    eventsCheck,
  });
}

export async function changePassword(password, confirmPassword, token) {
  return await http.post(`${web_api_url}/change-password`, {
    password,
    confirmPassword,
    token,
  });
}

export async function getUser() {
  return await http.get(`${web_api_url}/user`);
}

export async function getAttemptedTasksForCourse(course) {
  return await http.get(`${web_api_url}/user/task_attempts/${course}`);
}

export async function getScoresForCourse(course) {
  return await http.get(`${web_api_url}/user/scores/${course}`);
}

export async function getUserScores(course) {
  return await http.get(`${web_api_url}/user/scores`);
}

export async function editUser(
  fullName,
  postNumber,
  school,
  is_ai_school,
  events_check
) {
  //console.log("Edit --", { fullName, postNumber, school, is_ai_school, events_check })
  let payload = {
    fullName: fullName ? fullName : "",
    postNumber: postNumber ? postNumber : "",
    school: school ? school : "",
    is_ai_school: is_ai_school,
    events_check: events_check,
  };
  return await http.put(`${web_api_url}/user`, payload);
}

export async function deleteAccount(id) {
  return await http.put(`${web_api_url}/close-account/${id}`);
}

export async function registerStudent(
  username,
  classcode,
  fullName,
  school,
  is_ai_school,
  postNumber
) {
  // var ageBar=false;

  return await http.post(`${web_api_url}/classroom/student`, {
    username,
    classcode,
    fullName,
    school,
    is_ai_school,
    postNumber,
  });
}

export default {
  register,
  changePassword,
  getUser,
  editUser,
  getAttemptedTasksForCourse,
  getScoresForCourse,
  getUserScores,
  deleteAccount,
};
