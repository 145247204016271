import React, { Component } from "react";
import myStatusService from "../../services/myStatusService";
import userService from "../../services/userService";
import Loading from "../common/loading";

class MyStatus extends Component {
  _isMounted = false;
  state = {
    userStatus: [],
    userScore: 0,
    showLoading: true,
  };

  componentDidMount() {
    myStatusService.getMyStatus().then(({ data: response }) => {
      response.sort((a, b) => a.order - b.order);
      this.setState({
        userStatus: response,
        showLoading: false,
      });
    });

    userService.getUserScores().then(({ data: response }) => {
      this.setState({ userScore: response.scores });
    });
  }

  render() {
    const { userStatus, userScore } = this.state;

    return (
      <React.Fragment>
        <section className="content">
          <div className="container-fluid">
            {userStatus && !(userStatus.length === 0) && (
              <div className="container-limit wide-limit">
                <div className="status-scoring-info">
                  <div>
                    Hier kannst du deine gesammelten Punkte sehen. Für jede
                    richtige Quizantwort, sowie ausgeführte Codingaufgabe
                    erhältst du Punkte.
                  </div>
                </div>
                <div className="container-center">
                  {userStatus &&
                    userStatus.map((statusItem) => (
                      <div className="status-tile">
                        <div className="status-sub-header">
                          {statusItem.type}
                        </div>
                        <div className="status-header primary-color-lightblue-foreground">
                          {statusItem.header}
                        </div>
                        <div className="section-header">Resultat</div>
                        <div className="section-content">{`${statusItem.attempted_tasks} bearbeitete Aufgaben`}</div>
                        <div className="section-content">{`${
                          statusItem.total_tasks - statusItem.attempted_tasks
                        } ungelöste Aufgaben`}</div>
                        <div className="section-header">{`${statusItem.collected_points} gesammelte Punkte`}</div>
                      </div>
                    ))}
                  <div className="status-text-wrapper">
                    <div className="status-text">
                      {`Insgesamt hast du ${userScore} Punkte gesammelt.`}
                      <a
                        className="leaderboard-link primary-color-lightblue-foreground"
                        href="/leaderboard"
                      >
                        Bestenliste
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {(!userStatus || userStatus.length === 0) && <Loading />}
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default MyStatus;
