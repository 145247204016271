import React, { Component, Fragment  } from 'react';
import authService from '../services/authService';
import { Link } from 'react-router-dom';
import * as alert from '../alert.json';
import memorys from '../json/memory.json'
import ViewModeAlert from '../components/viewModeAlert'
import questionService from '../services/questionService';

class memory extends Component {
    state = {
        viewMode:true,
        showViewModeMessage:false,
        timelines: []
    }

    componentDidMount() {

    let user = authService.getCurrentUser();

        if(!user){
            this.setState({viewMode:true})
        }
        else{
            this.setState({viewMode:false})
        }

    questionService.getMultipleQuestionsByType('milestones_memory').then(({ data: response }) => {
            var finishedList=[];
            var quizList = response.filter(response => response.quiz_id == 6 || (response.quiz_id == 1 && response.num_attempts > 2)) ;
            quizList.forEach(entry => finishedList.push(entry.chapter_id));
            this.setState({timelines: finishedList})
        });

    }

    viewModeMessage = () => {
            this.setState({
                showViewModeMessage:true,
            })
    }

    toggleViewModeMessage = () => {
        this.setState({
            showViewModeMessage:!this.state.showViewModeMessage
        })
    }

    onRegister = () => {
        this.props.history.push({
            pathname: '/auth', state: { prevPath: this.props.location.pathname }
        })
        this.setState({
            showViewModeMessage:false
        })
      }


    removeAlert = () => {
        this.setState({
            showAlert: false
        })
    }
    render() {
        const {showViewModeMessage, timelines} = this.state;

        return (
            <React.Fragment>
                <section className="content homepage">
                    <div>
                        <h1 className="homepage header1 font-main-header text-center">Meilensteine</h1>
                        <div className="homepage header2 font-sub-header text-center">In diesem Kapitel erfährst du mehr über wichtige historische Ereignisse auf dem Gebiet der KI. Spiele eine Runde KI-Memospiel und beantwote die Quizfragen.</div>
                        <div className="homepage header2 font-sub-header text-center">Los geht’s. Klicke ein Kapitel deiner Wahl an.</div>
                    </div>
                    <div className="memory-menu">
                    <img className="memory-menu-background" src={`/images/memory/memory_background.svg`}/>
                    <h1 className="homepage header1 font-main-header text-left">Themen</h1>
                        <div style={{ marginLeft: 20 }}>
                            {this.state.viewMode &&
                                memorys.allMemorys && memorys.allMemorys.map( (memoryItem,index) =>
                                    (
                                    <div className={"definition definition-footer-link"} key={index} style={{ cursor: 'pointer' }} onClick={this.viewModeMessage.bind(this)}>{memoryItem.chapter.toString() + " - " +  memoryItem.title}</div>
                                    )
                                )
                            }
                            {!this.state.viewMode &&
                                memorys.allMemorys && memorys.allMemorys.map( (memoryItem,index) =>
                                    (
                                    <Link to={`/milestones/memory/game/chapter=${memoryItem.chapter}`}>
                                    <div className={"definition definition-footer-link"} key={index}> {memoryItem.chapter.toString() + " - " +  memoryItem.title} {timelines.includes(memoryItem.chapter.toString()) ? '\u2713' : ''} </div>
                                    </Link>
                                    )
                                )
                            }
                            <br />
                            {!this.state.viewMode && <Fragment>
                                <div className="definition definition-footer-link">Hier findest du die abgeschlossenen Zeitstrahlen: &nbsp;
                                <Link to={`/milestones/memory/timeline`}>Hier lang!</Link>
                                </div>
                            </Fragment>}
                        </div>
                    </div>
                </section>
            {showViewModeMessage && <ViewModeAlert onRegister={this.onRegister} onCancel={this.toggleViewModeMessage} />}
            </React.Fragment>
        );
    }
}

export default memory;
