import React, { useEffect, useState } from 'react';
import authService from '../../services/authService';
import ViewModeAlert from '../viewModeAlert';

const AiChallengeOverview = (props) => {

    const [showViewModeMessage, setShowViewModeMessage] = useState(false)
    useEffect(() => {
      
    }, [])
    let user = authService.getCurrentUser();
    let url = "#";
    if (user) url = "/app/programming/practice/course-content/course=ai_challenge/id=1_01";

    const toggleViewModeMessage = () => {
        setShowViewModeMessage(!showViewModeMessage)
    }

    const onRegister = () => {
        props.history.push({
            pathname: '/auth', state: { prevPath: props.location.pathname }
        })
        setShowViewModeMessage(false)
    }

    return (
        <React.Fragment>
            <div className="content-base">
                <div className="task-main">
                        <div className="task-content"  >
                            <div className="task-header primary-color-lightblue-background" >
                                <div className="task-title">KI Challenge</div>
                            </div>
	    <p>
<iframe
    width="100%"
    height="500"
    src="https://player.vimeo.com/video/871540599?h=355707bdda&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen title="Intro_KI_Challenge"
>
</iframe>
		</p>
                            <p><span style={{fontWeight:'bold'}}>Lernziele: </span>Implementierung eines Machine Learning Modells</p>

                            <div className="task-footer">
                                <a className="task-action-btn" onClick={e => {
                                    if (user) props.history.push({pathname:"/programming/practice/course-content/course=ai_challenge/id=1_01"})
                                    
                                    else { setShowViewModeMessage(true);}
                                }} href={user ? "/app/programming/practice/course-content/course=ai_challenge/id=1_01" : null}>Weiter zur Aufgabe</a>
                		<a className="task-action-btn" href="/app/programming/practice/course=ai_challenge/submission" target="_blank">Lösung einreichen</a>
                		<a className="task-action-btn" href="/app/programming/practice/course=ai_challenge/leaderboard" target="_blank">Leaderboard</a>
                            </div>
                        </div>
                </div>
                
            </div>
            {showViewModeMessage && 
                    <ViewModeAlert onRegister={onRegister} onCancel={toggleViewModeMessage} />}
            
        </React.Fragment>
    );
}
export default AiChallengeOverview;
