import http from './httpService';
import { getUserName } from './authService'

const submission_api_url = process.env.REACT_APP_SUBMISSIONS_API_URL
const web_api_url = process.env.REACT_APP_WEB_API_URL

export async function getAssignments(course) {
    return await http.get(`${submission_api_url}/assignments/${course}`);
}

export async function getUserAssignment(course, assignmentId) {   
    const username = getUserName();
    return await http.get(`${submission_api_url}/assignments/${username}/${course}/${assignmentId}`);
}

export async function getCourseMetadta(course){
    return await http.get(`${submission_api_url}/metadata/${course}`)
}

export async function multipleQuestion(type) {
    return await http.get(`${web_api_url}/multiple-questions/${type}/1`);
}

export async function submitUsersAssingmentCode(course, assignmentId, code) {
    const username = getUserName();
    return await http.post(`${submission_api_url}/submissions/${username}/${course}/${assignmentId}`, code);
}

export async function resetUsersAssignmentCode(course, assignmentId) {
    const username = getUserName();
    return await http.put(`${submission_api_url}/submissions/${username}/${course}/${assignmentId}`);
}

export async function submitAssignmentResults(course, assignmentId, results) {
    let res = {
        course,
        assignmentId,
        results: results.map(p => {
            return {
                passed: p.passed
            }
        })
    }
    return await http.post(`${web_api_url}/assignment`, res)
}

export async function getCourses(course) {
    return await http.get(`${submission_api_url}/courses/${course}`);
}

export default {
    getAssignments,
    getUserAssignment,
    submitUsersAssingmentCode,
    submitAssignmentResults,
    multipleQuestion,
    resetUsersAssignmentCode,
    getCourseMetadta,
    getCourses
}