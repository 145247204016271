import FileSubmission from './file-submission.jsx'
import React, { Component } from 'react';
import Button from '../common/button';
import {UnControlled as CodeMirror} from 'react-codemirror2'

/**
 * React component specifically for the KI-Challenge of ki-kurs.org.
 * Acts as wrapper around the general FileSubmission component.
 * 
 * @extends Component
 */
class AiChallengeSubmissionPage extends Component {

	/**
	 * Constructor for AiChallengeSubmissionPage React component.
	 *
	 * @param props - The usual React props. Nothing special here.
	 */
	constructor(props) {
		super(props);
		const { path } = this.props.match;
		this.rootPath = path.split('/').slice(0,-1).join('/')
		this.exampleCode = `import json

# Nun nutzen wir das trainierte Modell um Vorhersagen für die Testdaten zu generieren
y_test_prediction = model.predict(X_test)

# Vermutlich wird euer Modell keine Zeichenketten wie "italian" oder "chinese" vorhersagen sondern eher Zahlen, die 
# stellvertrenden für ein Herkunftsland stehen. Das musst Du nun wieder zurück übersetzen um das vorhergesagt Herkunftsland
# als String zu erhalten. Die Funktion "label_to_string" ist hier nur ein Platzhaler und je nach dem wie Du vorgegangen bist,
# kannst Du hier deine eigene Programmlogik einbauen. Wichtig ist nur, dass deine Vorhersagen nachher wieder in der gleichen
# Form vorliegen, wie in den Trainingsdaten, also als Zeichenkette, die die Art der Küche bzw. das Herkunftland beinhaltet.
test_prediction_strings = [label_to_string(p) for p in y_test_prediction]
                           
# Als letztes fügen wir die Vorhersagen mit den Ids der Rezepte zusammen.
predictions = []
for i in range(len(test_prediction_strings)):
    id_recipe = test_ids[i]
    cuisine = test_prediction_strings[i]
    json_object = {
        "id":id_recipe,
        "cuisine":cuisine}
    predictions.append(json_object)
    
# Wir haben jetzt eine Liste aus json Objekten die in etwa so aussehen:
#
# {"id":1,
# "cuisine":"italian"}
#

# Nun speichern wir alles in eine große JSON Datei. Diese Datei kannst Du nun auf unserer Website hochladen
# und bekommst direkt dein Ergebnis und deinen Platz in unserer Bestenliste mitgeteilt
with open("abgabe.json", "w") as outfile:
    json.dump(predictions, outfile)
`
	}

	/**
	 * Handle "Zur Übersicht" button press. Navigates to the start of this challenge.
	 *
	 */
	onToOverview = () => {
		this.props.history.push({pathname: `${this.rootPath}`})
	}

	/**
	 * Handle "Zum Leaderboard" button press. Navigates to the leaderboard of this challenge.
	 *
	 */
	onToLeaderboard = () => {
		this.props.history.push({pathname: `${this.rootPath}/leaderboard`})
	}


	/**
	 * Renders this component.
	 *
	 * @returns The React component tree.
	 */
	render() {
		return (
                        <React.Fragment>
                        <section className="content question">
                                <div className="container-limit my-limit">
                                    <div className="intro-section">
                                            <React.Fragment>
                                              <div className="task-item-mainheader">
						KI-Challenge: Lade Deine Vorhersagen hoch
                                              </div>
                                              <div className="intro-wrapper">
                                       	      <div className="intro">
						<p>Du hast dein erstes Modell mit den Trainingsdaten trainiert und willst es nun testen, wie gut die Vorhersage für unsere Testdaten sind? Super, hier zeigen wir dir, wie Du die Vorhersagen für die Testdaten erstellst und das Ganze dann hier auf der Website als Datei hochladen kannst.</p>
						<p>An dieser Stelle hast Du bereits euer Modell mit den Trainingsdaten trainiert. Du hast auch bereits die Testdaten eingelesen und die Features auf die gleiche Weise erstellt wie für die Trainingsdaten. Diese Features hast Du dann in der Variable <code>X_test</code> gespeichert. Die IDs der Rezepte sind für die Vorhersage ziemlich uninteressant und wahrscheinlich nicht Teil von <code>X_test</code>. Sie sind aber wichtig, um später wieder zuordnen zu können, um welches Rezept es geht. Wir gehen also davon aus, dass Du die IDs in einer separaten Liste <code>test_ids</code> gespeichert hast und die Reihenfolge von <code>X_test</code> und <code>test_ids</code> gleich ist.</p>
                <CodeMirror
                    id='codeblock' 
                    options={{
                        theme: 'elegant',
                        mode: 'python',
                        lineNumbers:true,
                        readOnly:true
                    }}                                              
                    className="terminal-block"
                    value={this.exampleCode} 
                    disabled
                />
						<p>Wichtig: Das ist nur ein Beispiel-Code, der erklären soll, wie die Abgabe-Datei aufgebaut sein soll. Wir verwenden hier einige Objekte und Methoden wie <code>model</code>, <code>label_to_string</code> usw., die nur als Platzhalter dienen. Wie genau Du die Vorhersagen für die Testdaten erzeugst und mit den IDs der Rezepte zusammenfügst hängt natürlich von deinem erstlichen Code ab und kann daher natürlich auch anders aussehen als in diesem Beispiel. Wichtig ist nur, dass deine Abgabe-Datei später im gleichen Format ist, sodass es keine Probleme bei der automatischen Auswertung gibt. Bei Fragen helfen wir dir natürlich wie immer in unserem <a href="https://discord.gg/J6T5pJyVa8">Discord Channel</a> weiter.</p>
            	                            </div>
            	                            </div>
	    	        			<FileSubmission course='ai_challenge' id='1_03'/>
            	                            <div className="button-wrapper">
            	                                <div className="button-video">
            	                                    <Button label="Zur Übersicht" onClick={this.onToOverview}/>
            	                                    <Button label="Zum Leaderboard" onClick={this.onToLeaderboard} />
            	                                </div>
            	                            </div>
                                            </React.Fragment>
                                    </div>
                                </div>
                        </section>
                    </React.Fragment>
        );
    }
}

export default AiChallengeSubmissionPage;
