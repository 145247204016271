import React, { useState } from "react";
import { Link } from "react-router-dom";
import ViewModeAlert from "../viewModeAlert";

const MenuPage = ({ props, heading, text, buttons, image, viewMode }) => {

  const [showViewModeMessage, setShowViewModeMessage] = useState(false);

  const viewModeMessage = () => {
    setShowViewModeMessage(true);
    console.log("viewModeMessage: " + showViewModeMessage);
  }

  const toggleViewModeMessage = () => {
    setShowViewModeMessage(!showViewModeMessage);
  }

  const onRegister = () => {
    props.history.push({ pathname: '/auth', state: { prevPath: props.location.pathname } })
    setShowViewModeMessage(false);
  }

  return (
    <>
      <section className="content homepage">
        <div style={{ maxWidth: "1200px", margin: "auto" }}>
          <h1 class="homepage header1 font-main-header text-center">
            {heading}
          </h1>
          <div style={{ fontSize: "1.2rem" }}>{text}</div>
          <div style={{ display: "flex" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                rowGap: "1rem",
                minWidth: "300px",
                margin: "10%"
              }}
            >
              {!viewMode && buttons.map(button => (
                <Link to={button.link}>
                  <button class="memory-game-button" style={{ width: "17rem" }}>
                    {button.label}
                  </button>
                </Link>
              ))}
              {viewMode && buttons.map(button => (
                <button class="memory-game-button" style={{ width: "17rem" }} onClick={viewModeMessage}>
                  {button.label}
                </button>
              ))}
            </div>
            <div style={{ flexGrow: "1" }}>
              <img src={image} />
            </div>
          </div>
        </div>
      </section>
      {showViewModeMessage && <ViewModeAlert onRegister={onRegister} onCancel={toggleViewModeMessage} />}
    </>
  );
};

export default MenuPage;
