import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { women } from "./pioneer-women.json";
import authService from "../../services/authService";
import Markdown from "../common/markdown";

import MuseumskatalogPDF from "../../pdfs/Museumskatalog_Pionierfrauen.pdf";
import TimelinePosterPDF from "../../pdfs/Poster_Pionierinnen.pdf";

const PioneerWomenCatalogue = (props) => {
  const prevButtonLink = "/milestones/pioneer-women/intro";
  const nextButtonLink = "/milestones/pioneer-women";

  const [category, setCategory] = useState("");
  const [categories, setCategories] = useState([]);
  const [womenList, setWomenList] = useState(women);
  const [showModal, setShowModal] = useState(false);
  const [currentPerson, setCurrentPerson] = useState("");

  const filterCategory = (category) => {
    setCategory(category);
    let filteredList =
      category === ""
        ? women
        : women.filter((person) => person.category === category);
    setWomenList(filteredList);
  };

  useEffect(() => {
    const allCategories = [
      ...new Set(
        women.map((person) => {
          return person.category;
        })
      ),
    ];
    setCategories(allCategories);

    let user = authService.getCurrentUser();
    if (!user) {
      props.history.push({
        pathname: "/auth",
        state: { prevPath: props.location.pathname },
      });
    }

    return () => {};
  }, [""]);

  const onPersonClicked = (person) => {
    setCurrentPerson(person);
    setShowModal(true);
  };
  const closeModal = (item) => {
    setShowModal(false);
  };

  return (
    <React.Fragment>
      <section className="content question">
        <div className="container-fluid">
          <div className="homepage header1 font-main-header text-center mt-5">
            Museumskatalog
          </div>
          <div className="homepage header2 font-sub-header text-center mb-5">
            <p>
              Überblick gefällig? Hier im Museumskatalog könnt ihr euch noch
              einmal alle IT-Pionierinnen und ihre Errungenschaft ansehen.
            </p>
            <p className="catalog-download-strong">
              Den Katalog könnt ihr euch auch als PDF herunterladen. Klickt dazu
              auf den Download-Button. Außerdem gibt es den
              Pionierfrauen-Zeitstrahl als Poster. Holt euch die PDF-Datei,
              druckt das Poster aus und hängt es auf.
            </p>
            <div className="mx-auto catalog-button-container">
              <a
                className="catalog-button-wrapper"
                href={MuseumskatalogPDF}
                target="_blank"
                referrerPolicy="no-referrer"
              >
                <div className="catalog-button">
                  <img
                    className="icon"
                    src="/images/ai_challenge/Download.svg"
                  />
                  &nbsp;Museumskatalog
                </div>
              </a>
              <a
                className="catalog-button-wrapper"
                href={TimelinePosterPDF}
                target="_blank"
                referrerPolicy="no-referrer"
              >
                <div className="catalog-button">
                  <img
                    className="icon"
                    src="/images/ai_challenge/Download.svg"
                  />
                  &nbsp;Poster
                </div>
              </a>
            </div>
          </div>
          <div className="container-limit wide-limit">
            <div className="portraits">
              <div className="category-selector">
                <div className="left-container">
                  <h3>Kategorie</h3>
                  <div className="category-wrapper">
                    {categories.map((categoryItem) => (
                      <p
                        className={category === categoryItem ? "selected" : ""}
                        onClick={() => {
                          filterCategory(categoryItem);
                        }}
                      >
                        {categoryItem}
                      </p>
                    ))}
                    {category !== "" && (
                      <div
                        className="clear"
                        onClick={filterCategory.bind(this, "")}
                      >
                        X
                      </div>
                    )}
                  </div>
                </div>
                <div className="right-container">
                  <img
                    src="/images/pioneer-women/catalogue.svg"
                    alt="Museumskatalog icon"
                  />
                </div>
              </div>

              <div className="portraits-list">
                {womenList &&
                  womenList.map((person, index) => (
                    <div className="portrait-frame lightblue-green-gradient-background">
                      <div
                        className="portrait-box"
                        onClick={onPersonClicked.bind(this, person)}
                      >
                        <div className="portrait-title">
                          <h1>{person.name}</h1>
                        </div>
                        <div className="portrait-subtitle">
                          <h2>{person.subtitle}</h2>
                        </div>
                        <div className="portrait-image">
                          <img src={person.imagepath} alt={person.name} />
                        </div>
                      </div>
                      <Markdown
                        className="portrait-summary"
                        children={person.summary}
                      />
                    </div>
                  ))}
              </div>
            </div>
            <div className="submit-parent-wrapper">
              <div className="button-wrapper">
                <Link to={prevButtonLink}>
                  <button className="firstBtn">Zurück</button>
                </Link>
                <Link to={nextButtonLink}>
                  <button className="thirdBtn">Zum Menu</button>
                </Link>
              </div>
            </div>
          </div>
        </div>

        {showModal && (
          <div className="overlay-panel-container">
            <div className="pioneer-woman overlay-modal">
              <div className="modal-content-wrapper">
                <div className="image-left">
                  <object
                    className="object"
                    data={currentPerson.objectpath}
                    type="image/svg+xml"
                  />
                  {/* <img className="object" src={currentPerson.objectpath} alt={currentPerson.name} /> */}
                </div>
                <div className="description-right">
                  <h1>{currentPerson.name}</h1>
                  <img
                    className="person"
                    src={currentPerson.imagepath}
                    alt={currentPerson.name}
                  />
                  {/* <div className="description">
                    {currentPerson.description}</div> */}
                  <Markdown
                    className="description"
                    children={currentPerson.description}
                  />
                </div>
              </div>
              <div className="close" onClick={closeModal}></div>
            </div>
          </div>
        )}
      </section>
    </React.Fragment>
  );
};

export default PioneerWomenCatalogue;
