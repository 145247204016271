import React from "react";
import { Redirect, Link } from "react-router-dom";
import Form from "../../common/form";
import Joi from "joi-browser";
import userService from "../../../services/userService";
import authService from "../../../services/authService";
import classService from "../../../services/classService";

class EditUser extends Form {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        id: "",
        fullname: "",
        school: "",
        postNumber: "",
        showParent: false,
        is_ai_school: true,
        is_disabled: false,
        is_sonstige: false,
        eventsCheck: false,
      },
      nonEditableInfo: {
        username: "",
        email: "",
        parent_email: "",
      },
      errors: [],
      showPanel: true,
      success: "",
      modal: null,
      showOlderWarning: "",
      showYoungerWarning: "",
      showNL: false,
      showDeleteConfirmation: false,
      userDeletedMessage: false,
      is_name_disabled: false,
      is_postNumber_disabled: false,
      deleteError: "",
      schools: [],
    };
    this.handleIsAISchool = this.handleIsAISchool.bind(this);
  }

  showPanel = () => {
    this.setState({
      showPanel: true,
      modal: true,
    });
  };

  closeDefinition = () => {
    this.setState({
      showPanel: false,
      modal: null,
    });
  };

  deleteAccount = () => {
    this.setState({
      showDeleteConfirmation: true,
    });
  };

  onDeleteConfirm = () => {
    const { id } = this.state.data;

    userService
      .deleteAccount(id)
      .then((response) => {
        authService.logout();
        this.setState({ userDeletedMessage: true });
      })
      .catch((e) => {
        console.log("delete failed", e);
        this.setState({ deleteError: "Unable to delete account" });
      });
  };

  closeDeleteMessage = () => {
    this.props.historyProp.push("/");
  };

  closeDeleteConfirmation = () => {
    this.setState({
      showDeleteConfirmation: false,
    });
  };

  schema = {
    id: Joi.string().allow().optional(),
    showParent: Joi.boolean().optional(),
    is_ai_school: Joi.boolean().optional(),
    school: Joi.when("is_ai_school", {
      is: false,
      then: Joi.string().allow("").optional(),
      otherwise: Joi.string().allow("").required(),
    }),
    fullname: Joi.when("is_ai_school", {
      is: false,
      then: Joi.string().allow("").optional(),
      otherwise: Joi.string().allow("").required(),
    }),

    postNumber: Joi.when("is_ai_school", {
      is: false,
      then: Joi.number().integer().optional(),
      otherwise: Joi.number().integer().allow("").required(),
    }),
    is_disabled: Joi.boolean().optional(),
    is_sonstige: Joi.boolean().optional(),
    eventsCheck: Joi.boolean().optional(),
  };

  componentDidMount() {
    // Fetch list of all aschools
    classService.getAllSchools().then((schools) => {
      this.setState({
        schools: schools,
      });
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.response._id) {
      this.setState({
        data: {
          id: nextProps.response._id.$oid,
          fullname: nextProps.response.fullname,
          school: nextProps.response.school,
          postNumber: nextProps.response.post_number,
          is_ai_school: nextProps.response.is_ai_school,
          is_disabled:
            nextProps.response.is_ai_school &&
            nextProps.response.post_number !== "",
          is_sonstige: nextProps.response.school === "Sonstige" ? true : false,
          eventsCheck: nextProps.response.events_check,
        },
        nonEditableInfo: {
          username: nextProps.response.username,
          email: nextProps.response.email,
          parent_email: nextProps.response.parent_email,
        },
        showNL: nextProps.response.events_check,
        is_name_disabled: nextProps.response.fullname !== "",
        is_postNumber_disabled: nextProps.response.post_number !== "",
      });
    }
  }

  handleIsAISchool = (event) => {
    const { data } = this.state;
    data["is_ai_school"] = event.target.checked;

    this.setState({
      data,
    });
  };

  handleChangeRadio = (event) => {
    const { data } = this.state;
    data["gender"] = event.target.value;
    this.setState({
      data,
    });
  };

  handleInputChange = (event) => {
    const { data } = this.state;
    let school = event.target.value;
    data["school"] = school.replace(" ", "-");
    this.setState({
      data,
    });
  };

  submitForm = () => {
    console.log("func called");
    const { data } = this.state;
    const { fullname, postNumber, school, is_ai_school, eventsCheck } = data;

    userService
      .editUser(fullname, postNumber, school, is_ai_school, eventsCheck)
      .then(({ data: response }) => {
        this.setState({
          success: "true",
          data: {
            // id: response._id.$oid,
            fullname: response.fullname,
            school: response.school,
            postNumber: response.post_number,
            is_ai_school: response.is_ai_school,
            is_disabled: response.is_ai_school && response.post_number !== "",
            is_sonstige: response.school === "Sonstige" ? true : false,
            eventsCheck: response.events_check,
          },
          nonEditableInfo: {
            email: response.email,
            username: response.username,
            parent_email: response.parent_email,
          },
          showNL: response.events_check,
          is_name_disabled: response.fullname === "" ? false : true,
          is_postNumber_disabled: response.post_number !== "",
        });
      })
      .catch((err) => {
        this.setState({
          success: "false",
        });
      });
  };

  render() {
    let options = [];
    this.state.schools.forEach((school) => {
      if (school === "Sonstige") {
        return;
      }
      options.push(<option>{school}</option>);
    });

    const modal_title = "KI Schule des Jahres";
    const {
      data,
      errors,
      success,
      nonEditableInfo,
      is_name_disabled,
      is_postNumber_disabled,
      showPanel,
      modal,
      showNL,
      userDeletedMessage,
    } = this.state;
    const {
      fullname,
      school,
      postNumber,
      is_ai_school,
      is_disabled,
      is_sonstige,
      eventsCheck,
    } = data;
    const { username, email, parent_email } = nonEditableInfo;

    return (
      <div className={`col-md-12`}>
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-md-12">
                <h2 className="text-center">Benutzerprofil</h2>
                <hr />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label htmlFor="Benutzername">Benutzername</label>
                    <input
                      className="form-control"
                      name="username"
                      value={username}
                      type="text"
                      placeholder={""}
                      disabled
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <label htmlFor="Email">Email</label>
                    <input
                      className="form-control"
                      name="email"
                      data-teamindex="0"
                      value={email}
                      type="email"
                      disabled
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className={`form-group col-md-6`}>
                    <label htmlFor="ParentEmail">
                      E-Mail des Erziehungsberechtigten
                    </label>
                    <input
                      className={`form-control`}
                      name="parent_email"
                      data-teamindex="0"
                      value={parent_email}
                      type="email"
                      disabled
                    />
                  </div>
                </div>
                <hr />
                <div className="form-row">
                  <div className="form-group">
                    <div className="checkbox-wrapper">
                      <div className="chechbox-terms">
                        <label className="switch">
                          {!is_disabled ? (
                            <input
                              type="checkbox"
                              id="is_ai_school"
                              name="is_ai_school"
                              checked={is_ai_school}
                              onChange={this.handleIsAISchool}
                            />
                          ) : (
                            <input
                              type="checkbox"
                              id="is_ai_school"
                              name="is_ai_school"
                              checked={is_ai_school}
                              disabled
                            />
                          )}
                          <span
                            className={
                              is_disabled
                                ? "slider round grayout"
                                : "slider round"
                            }
                            disabled={is_disabled}
                          ></span>
                        </label>
                        <span className={is_disabled ? "grayout" : ""}>
                          Wenn du am Rennen um den Titel der
                          <a
                            style={{ color: "#007bff" }}
                            onClick={this.showPanel}
                          >
                            {" "}
                            KI-Schule des Jahres{" "}
                          </a>
                          teilnehmen möchtest, klicke hier und fülle die
                          folgenden Felder aus.
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                {!showNL && (
                  <>
                    <div className="form-row">
                      <div className="col-md-12">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            onChange={this.handleChange}
                            checked={eventsCheck}
                            name="eventsCheck"
                          />
                          {/* <input className="form-check-input" type="checkbox" checked={eventsCheck} name="eventsCheck" disabled /> */}

                          <label
                            className="form-check-label"
                            htmlFor="check-events"
                          >
                            Ja, ich möchte zu weiteren Aktivitäten des BWKI
                            informiert werden
                          </label>
                        </div>
                      </div>
                    </div>
                    <hr />
                  </>
                )}
                <div className="form-row">
                  <div className="form-group col-md-4">
                    <label htmlFor="Vollständiger Name ">
                      Vollständiger Name{" "}
                    </label>
                    <input
                      className="form-control"
                      name="fullname"
                      disabled={is_name_disabled ? true : false}
                      value={fullname}
                      onChange={this.handleChange}
                      type="text"
                      placeholder=""
                    />
                  </div>
                  <div className="form-group col-md-4">
                    <label
                      className={is_postNumber_disabled ? "grayout" : ""}
                      htmlFor="PLZ"
                    >
                      PLZ
                    </label>
                    <input
                      name="postNumber"
                      value={postNumber}
                      onChange={this.handleChange}
                      type="text"
                      placeholder="PLZ"
                      className={`form-control ${
                        errors.postNumber ? "invalid" : ""
                      }`}
                      disabled={is_postNumber_disabled}
                    />
                  </div>
                  {is_sonstige ? (
                    <div className="form-group col-md-4">
                      <label
                        className={is_disabled ? "grayout" : ""}
                        htmlFor="Schule"
                      >
                        Schule
                      </label>
                      <div>
                        <input
                          name="school"
                          value={school}
                          onChange={this.handleInputChange}
                          type="text"
                          placeholder={"Schule"}
                          className={
                            errors.school
                              ? "invalid form-control"
                              : "form-control"
                          }
                        />
                      </div>
                    </div>
                  ) : (
                    <div className="form-group col-md-4">
                      <label
                        className={is_disabled ? "grayout" : ""}
                        htmlFor="Schule"
                      >
                        Schule
                      </label>
                      <div>
                        <input
                          list="schools"
                          name="school"
                          value={school}
                          onChange={this.handleChange}
                          type="text"
                          placeholder={"Schule"}
                          className={
                            errors.school
                              ? "invalid form-control"
                              : "form-control"
                          }
                        />
                        <datalist id="schools">{options}</datalist>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className={`button-div`}>
                <button
                  className="btn btn-primary"
                  type="submit"
                  onClick={this.handleSubmit}
                >
                  Speichern
                </button>
              </div>
              <div className="message-wrapper">
                {success === "true" && (
                  <div className="alert alert-success">
                    Deine Veränderungen wurden erfolgreich gespeichert!
                  </div>
                )}
                {success === "false" && (
                  <span className="alert alert-danger">
                    Deine Veränderungen konnten nicht gespeichert werden.
                  </span>
                )}
              </div>
              <div className="delete-account" onClick={this.deleteAccount}>
                Account löschen?
              </div>

              {showPanel && modal && (
                <div className="overlay-panel-container">
                  <div className="overlay-panel">
                    <div className="definition-header">{modal_title}</div>
                    <div className="section-wrapper">
                      <div className="section">
                        <p className="text-justify">
                          Bewältigt den KI-Kurs gemeinsam als Klasse, AG oder
                          Schülerforschungszentrum und nehmt am Rennen um den
                          Titel <b>"KI-Schule des Jahres"</b> teil. Die
                          Einrichtung, die während eines Schuljahres (1.August
                          bis 31.Juli) am aktivsten am KI-Kurs teilnimmt, erhält
                          den Titel und einen attraktiven Sachpreis.
                        </p>
                        <br />
                        <p className="text-justify">
                          Weitere Infos findest du unter dem Menüpunkt „
                          <Link to="/school-of-the-year" target="_blank">
                            KI-Schule des Jahres
                          </Link>
                          “.
                        </p>
                      </div>
                    </div>
                    <div className="close" onClick={this.closeDefinition}></div>
                  </div>
                </div>
              )}

              {this.state.showDeleteConfirmation && (
                <div className="overlay-panel-container">
                  <div className="overlay-panel view-mode-message">
                    <div className="panel-content">
                      <div
                        className="message-title"
                        style={{ textAlign: "center", margin: "1rem" }}
                      >
                        {userDeletedMessage
                          ? `Dein Account wurde erfolgreich gelöscht. Wir hoffen dich bald wieder zu sehen!`
                          : `Bist du dir sicher, dass du deinen Account löschen möchtest? Dies kann nicht rückgängig gemacht werden.`}
                      </div>
                      <div className="button-wrapper">
                        {userDeletedMessage ? (
                          <button
                            style={{
                              color: "#fff",
                              backgroundColor: "#0083d3",
                            }}
                            onClick={this.closeDeleteMessage}
                          >
                            Ok
                          </button>
                        ) : (
                          <>
                            <button
                              style={{
                                color: "#fff",
                                backgroundColor: "#0083d3",
                              }}
                              onClick={this.onDeleteConfirm}
                            >
                              Ja
                            </button>
                            <button onClick={this.closeDeleteConfirmation}>
                              Abbrechen
                            </button>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default EditUser;
