import React, { Component, Fragment } from "react";
import authService from "../services/authService";
import { Link } from "react-router-dom";
import * as alert from "../alert.json";
import definitions from "../json/definitions.json";
import ViewModeAlert from "../components/viewModeAlert";

class definition extends Component {
  state = {
    showDefinitionPanel: true,
    showViewModeMessage: false,
    definition: null,
  };

  componentDidMount() {}

  openDefinition = (viewMode, definitionItem) => {
    if (viewMode) {
      this.setState({
        showViewModeMessage: true,
      });
    } else {
      this.setState({
        showDefinitionPanel: true,
        definition: {
          title: definitionItem.title,
          sections: [...definitionItem.sections],
        },
      });
    }
  };

  toggleViewModeMessage = () => {
    this.setState({
      showViewModeMessage: !this.state.showViewModeMessage,
    });
  };

  onRegister = () => {
    this.props.history.push({
      pathname: "/auth",
      state: { prevPath: this.props.location.pathname },
    });
    this.setState({
      showViewModeMessage: false,
    });
  };

  closeDefinition = () => {
    this.setState({
      showDefinitionPanel: false,
      definition: null,
    });
  };

  render() {
    const { definition, showViewModeMessage } = this.state;
    const user = authService.getCurrentUser();
    let viewMode = true;
    // if (!user && viewMode) this.props.history.push('/auth')
    if (user) viewMode = false;
    return (
      <React.Fragment>
        <section className="content homepage">
          <div>
            <h1 className="homepage header1 font-main-header text-center">
              Unsere Mindmap zum Thema Künstliche Intelligenz
            </h1>
            <div className="homepage header2 font-sub-header text-center">
              Diese Mindmap gibt euch einen Überblick zu Begriffen, Konzepten
              und Problemen der Künstlichen Intelligenz. Wir stellen keinen
              Anspruch auf Vollständigkeit, sondern wollen verdeutlichen, wie
              vielfältig das Themengebiet ist. Für vertiefende Informationen
              verlinken wir euch interessante Websites. Klick dich mittels der
              Kästchen durch die spannende Welt der KI!
            </div>
            <div className="definition-panel-wrapper">
              <div className="definition-image-wrapper">
                <img
                  src={`/images/icons/subpaths/milestones/definition_structure.svg`}
                  alt=""
                />
                {definitions.allDefinitions &&
                  definitions.allDefinitions.map((definitionItem, index) => (
                    <div
                      className={`item${index + 1} box-border`}
                      key={index}
                      onClick={this.openDefinition.bind(
                        this,
                        viewMode,
                        definitionItem
                      )}
                    >
                      {" "}
                      {definitionItem.title}
                    </div>
                  ))}
              </div>
            </div>

            {viewMode && (
              <Fragment>
                <div className="definition definition-footer-link">
                  Was genau ist KI und was steckt dahinter? Ein spannender Kurs
                  von Elements of AI.
                </div>
                <div className="definition definition-footer-link">
                  KI in Deutschland - die Landkarte von Lernende Systeme.
                </div>
                <div className="definition definition-footer-link">
                  Spezielle KI Kurse, Videos und Podcasts - die Plattform
                  KI-Campus.
                </div>
              </Fragment>
            )}
          </div>
        </section>
        {this.state.showDefinitionPanel && this.state.definition && (
          <div className="overlay-panel-container">
            <div className="overlay-panel">
              <div
                className="close"
                style={{ gridRow: "1" }}
                onClick={this.closeDefinition}
              ></div>
              <div className="definition-header" style={{ gridRow: "1" }}>
                {definition.title}
              </div>
              <div className="section-wrapper" style={{ gridRow: "2" }}>
                {definition.sections &&
                  definition.sections.map((section, index) => (
                    <div className="section" key={index}>
                      <p>
                        <span className="section-title">{section.title}</span>
                        {" " + section.description}
                      </p>
                      {section.link && (
                        <a target="_blank" href={section.link}>
                          {section.link}
                        </a>
                      )}
                    </div>
                  ))}
              </div>
            </div>
          </div>
        )}

        {showViewModeMessage && (
          <ViewModeAlert
            onRegister={this.onRegister}
            onCancel={this.toggleViewModeMessage}
          />
        )}
      </React.Fragment>
    );
  }
}

export default definition;
