import React, { useEffect, useState } from "react";
import questionService from "../../services/questionService";
import Question from "../questions/question";
import Loading from "../common/loading";
import Button from "../common/button";
import { Progress } from "reactstrap";

const QuestionsPanel = (props) => {
  const { questions, title, next, back, chapter } = props;
  const [currentQuestion, setCurrentQuestion] = useState();
  const [selectedAnswers, setSelectedAnswers] = useState();
  const [allQuestions, setAllQuestions] = useState();
  const [correct, setCorrect] = useState();
  const [message, setMessage] = useState();
  const [allowSubmit, setAllowSubmit] = useState(true);
  const [color, setColor] = useState();
  const [showLoader, setShowLoader] = useState(true);
  const [totalPoints, setTotalPoints] = useState(0);
  const [correctAnswerCount, setCorrectAnswerCount] = useState(0);

  useEffect(() => {
    console.log("qindex", props);
    setShowLoader(true);
    let curQuestion = undefined;
    if (props.last) {
      curQuestion = questions.allQuestions[questions.allQuestions.length - 1];
    } else {
      curQuestion = questions.allQuestions[0];
    }
    setCurrentQuestion(curQuestion);
    initAnswers(curQuestion);

    // if (curQuestion.id >= questions.allQuestions.length) {
    //   console.log('in next', curQuestion.id, questions.allQuestions.length, props.last)
    // }
    return () => {};
  }, [questions, props.qIndex]);

  const initAnswers = async (question) => {
    try {
      let resAnswers = await questionService.getQuestionByChapterId(
        "ethics",
        chapter
      );
      console.log("res asnwers", resAnswers);
      if (resAnswers.status === 200) {
        let correctTemp = null;
        let subState = { message: "", color: "", allowSubmit: true };
        let selectedAnswersTemp = [];
        let tempCorrectAnswersCount = 0;
        console.log("res .data", resAnswers.data);
        let answerItem = resAnswers.data.find(
          (dataItem) => dataItem.quiz_id == question.id
        );
        // console.log(answerItem)
        if (answerItem) {
          correctTemp = answerItem.correct;
          selectedAnswersTemp = answerItem.answers;
          subState = handleMessage(answerItem, true);
          tempCorrectAnswersCount = answerItem.correct_answers_count;
        }
        setCorrectAnswerCount(tempCorrectAnswersCount);
        console.log("asnwers and substate", selectedAnswersTemp, subState);
        setSelectedAnswers(selectedAnswersTemp);
        setCorrect(correctTemp);
        setMessage(subState.message);
        setColor(subState.color);
        setAllowSubmit(subState.allowSubmit);

        // calculate accumulated points
        let tempTotalPoints = 0;
        resAnswers.data.map((pointItem) => {
          tempTotalPoints += pointItem.correct_answers_count
            ? pointItem.correct_answers_count
            : 0;
        });
        setTotalPoints(tempTotalPoints);
      }
    } catch (error) {
      console.log("error in answer init", error);
    } finally {
      setShowLoader(false);
    }
  };

  const onChangeAnswerStatus = (id, index) => {
    console.log("onchange ", id, index);
    let tempQuestion = { ...currentQuestion };
    tempQuestion.answers[index].checked = !tempQuestion.answers[index].checked;
    setCurrentQuestion(tempQuestion);
  };

  const onNextQuestion = () => {
    setShowLoader(true);
    console.log("in next", currentQuestion.id, questions.allQuestions.length);
    if (currentQuestion.id >= questions.allQuestions.length) {
      next();
    } else if (currentQuestion.id < questions.allQuestions.length) {
      console.log("here");
      let nextQuestion = questions.allQuestions[currentQuestion.id];
      console.log("next question", nextQuestion);
      initAnswers(nextQuestion);
      setCurrentQuestion(nextQuestion);
    }
  };

  const onPrevQuestion = () => {
    setShowLoader(true);
    console.log("in back", currentQuestion.id);
    if (currentQuestion.id == 1) {
      console.log("on back");
      back();
    } else {
      console.log("pre question");
      let currentIndex = currentQuestion.id - 1;
      let prevQuestion = questions.allQuestions[currentIndex - 1];
      console.log("prev", prevQuestion);
      initAnswers(prevQuestion);
      setCurrentQuestion(prevQuestion);
    }
  };

  // console.log(questions)

  const submitAnswer = async () => {
    setShowLoader(true);
    let questionId = currentQuestion.id;
    let question = {};
    question.id = questionId;
    question.chapterId = chapter;
    question.type = "ethics";
    question.answers = currentQuestion.answers;
    console.log("submitting ", question);
    try {
      let resSubmit = await questionService.submitQuestions(question);
      console.log("submit result", resSubmit);
      let selectedAnswersTemp = resSubmit.data.answers;
      let subState = handleMessage(resSubmit.data, false);
      console.log("substate", subState);
      setSelectedAnswers(selectedAnswersTemp);
      setMessage(subState.message);
      setColor(subState.color);
      setAllowSubmit(subState.allowSubmit);

      let tempTotalPoints =
        totalPoints - correctAnswerCount + resSubmit.data.correct_answers_count;
      setTotalPoints(tempTotalPoints);
      setCorrectAnswerCount(resSubmit.data.correct_answers_count);
    } catch (error) {
      console.log("submit failed", error);
    } finally {
      setShowLoader(false);
    }
  };

  const handleMessage = (response, load) => {
    let message = "";
    let color = "";
    let allowSubmit = true;

    if (load && (response.correct || response.num_attempts === 3)) {
      message = "";
      color = "";
      allowSubmit = false;
      return { message: message, color: color, allowSubmit: allowSubmit };
    }

    if (response.num_attempts < 3) {
      if (response.correct) {
        message = "Super. Du hast Punkte gesammelt.";
        color = "message-correct";
        allowSubmit = false;
      } else {
        message = "Noch nicht ganz richtig. Versuche es nochmal.";
        color = "message-more";
      }
    } else if (response.num_attempts === 3) {
      allowSubmit = false;
      if (response.correct) {
        message = "Super. Du hast Punkte gesammelt.";
        color = "message-correct";
      } else {
        if (response.correct_answers_count === 0) {
          message = "Schade, diesmal hast du leider keine Punkte gesammelt.";
          color = "message-wrong";
        } else if (response.correct_answers_count > 0) {
          message = "Du erhältst für die richtigen Antworten Punkte.";
          color = "message-partial";
        }
      }
    }

    return { message: message, color: color, allowSubmit: allowSubmit };
  };

  return (
    <>
      {!showLoader && (
        <>
          <section className="main-wrapper info-panel content question">
            {title && (
              <div className="main-header primary-color-lightgreen-foreground">
                {title}
              </div>
            )}
            <div className="container-limit my-limit">
              <div className="progress-div">
                <Progress
                  value={currentQuestion.id}
                  max={questions.allQuestions.length}
                />
                <div className="progress-span">
                  {currentQuestion.id}/{questions.allQuestions.length}
                </div>
              </div>
              {currentQuestion && (
                <div className="question-wrapper">
                  <Question
                    key={currentQuestion.id}
                    correct={correct}
                    question={currentQuestion}
                    selectedAnswers={selectedAnswers}
                    onChange={onChangeAnswerStatus}
                    showAnswers={!allowSubmit}
                    type="ethics"
                  />
                </div>
              )}
            </div>
            {message && !(message === "") && (
              <div
                className={`container-limit my-limit question-info ${color}`}
              >
                <span>{message}</span>
              </div>
            )}
            {!allowSubmit && (
              <div className="container-limit my-limit question-info message-answers">
                <span>Musterlösung - dies sind alle korrekten Antworten!</span>
              </div>
            )}

            <div className="submit-parent-wrapper">
              {
                <div className="button-wrapper">
                  <div className="points-panel-wrapper">
                    <span>Punkte</span>
                    <div className="points-panel">{totalPoints}</div>
                  </div>
                  {
                    <Button
                      onClick={onPrevQuestion}
                      href="#"
                      className="firstBtn"
                      label="Zurück"
                    /> /*Vorherige Frage */
                  }
                  {allowSubmit && (
                    <Button
                      onClick={submitAnswer}
                      href="#"
                      className="secondBtn primary-color-lightblue-foreground"
                      label="Auswerten"
                    />
                  )}
                  {
                    <Button
                      onClick={onNextQuestion}
                      href="#"
                      className="thirdBtn"
                      label="Weiter"
                    /> /*Nächste Frage */
                  }
                </div>
              }
            </div>
          </section>
        </>
      )}
      {showLoader && <Loading />}
    </>
  );
};

export default QuestionsPanel;
