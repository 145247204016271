import React, { Component } from 'react';
import assignmentsService from '../../services/assignmentsService';
import questionService from '../../services/questionService';
import userService from '../../services/userService'
import authService from '../../services/authService'
import { element, number } from 'prop-types';
import memorys from '../../json/memory.json'
import Loading from '../common/loading'


class MemoryGame extends Component {

    state = {
        chapterId: this.props.match.params.chapterId,
        openCards: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        correct_pair: false,
        false_pair: false,
        finished: false,
        finished_quiz: false,
        showSources: false
    }

    componentDidMount() {
    const user = authService.getCurrentUser();
        if (!user) this.props.history.push({pathname: '/auth', state: { prevPath: this.props.location.pathname }});

        questionService.getMultipleQuestionsByType('milestones_memory').then(({ data: response }) => {
            var quizList = response.filter(response => response.quiz_id == 6 || (response.quiz_id == 1 && response.num_attempts > 2));
            var done = quizList.filter(e => e.chapter_id === this.state.chapterId.toString()).length > 0;
            if (done){
            var openQuiz = this.state.openCards.fill(2);
            this.setState({
            openCards: openQuiz,
            finished: true,
            finished_quiz: done})
            }
        });
    }

    checkPair(id_1, id_2) {
    const pairs = [[0, 6], [1, 10], [2, 4], [3, 8], [5, 11], [7, 9]];
    const result = pairs.find(el => el.includes(id_1));
    const updatedCards = this.state.openCards;
    var pair_result = 0;

    if(result.includes(id_2) == true){
        this.setState({
        correct_pair: true
        });
        pair_result = 2;
    }
    else{
        this.setState({
        false_pair: true
        });
    }

    setTimeout(() => {

    updatedCards[id_1] = pair_result;
    updatedCards[id_2] = pair_result;

    var updateTime = 2000;

    if(updatedCards.filter(item => item == 2).length == 12){
        this.setState({
        finished: true
        });
        updateTime = 0;
    }

    // flip Cards after 2 sec, else use continueGame
    setTimeout(() => {
    this.setState({
        correct_pair: false,
        false_pair: false
    });
    }, updateTime);

    }, 200);




    }

    flipCard = (id) =>  {
    const updatedCards = this.state.openCards;
    const open = updatedCards.indexOf(1);

    if(updatedCards.filter(i => i == 1).length < 2 && !this.state.correct_pair && !this.state.false_pair)
    {
    updatedCards[id] = 1;

    this.setState({
        openCards: updatedCards
    });

    if (open > -1){
        this.checkPair(open, id);
    }
    }

    }

    continueGame = () => {
        this.setState({
        correct_pair: false,
        false_pair: false
    })
     }

    toQuiz = () => {
      this.props.history.push({
        pathname: `/milestones/memory/quiz/chapter=${this.state.chapterId}/id=1`,
      })
     }

    toOverview = () => {
     this.props.history.push({
       pathname: `/milestones/memory/`,
     })
    }

    openSources = () => {
            this.setState({
                showSources:true
            })
    }

    closeSources = () =>{
        this.setState({
            showSources:false
        })
    }


    render() {
        const {chapterId, openCards, correct_pair, false_pair, finished, finished_quiz, showSources} = this.state;
        const user = authService.getCurrentUser();
        if (!user) this.props.history.push({pathname: '/auth', state: { prevPath: this.props.location.pathname }});

        const positions = [0, 3, 4, 11, 5, 6, 1, 8, 10, 9, 2, 7];
        const cards = [];

        return (
            <React.Fragment>
                <section className="main-content memory-game">

                        {memorys.allMemorys && memorys.allMemorys.filter(el => el.chapter == chapterId).map( (memoryItem,index) =>

                             { memoryItem.pairs.forEach(el => cards.push(el.image_1, el.image_2));

                                return (
                                <>
                                <div className="memory-game-description">
                                <h2 className="homepage header2 font-main-header text-left" style={{ marginLeft: 0 }}>{memoryItem.chapter.toString() + " - " +  memoryItem.title}</h2>
                                <p className={finished? 'hidden' : ''}>Finde das korrekte Paar! Ein Paar besteht aus einem Bild und einer Beschreibung.</p>
                                <p className={finished? 'hidden' : ''}>Ist ein Paar falsch, dreht es sich automatisch wieder um. Ist es richtig, bleibt es offen.</p>
                                <p className={finished ? 'hidden' : ''}>Nachdem du alle Paare gefunden hast, kannst du in einem Quiz die Ereignisse chronologisch einordnen.</p>
                                <p className={correct_pair ? 'correct-pair' : 'hidden'}>Genau, die gehören zusammen!</p>
                                <p className={false_pair ? 'false-pair' : 'hidden'}>Noch nicht ganz richtig.</p>
                                {
                                //<button type="button" onClick={this.continueGame} className={correct_pair || false_pair ? 'memory-game-button mini-button' : 'hidden'}>Weiter</button>
                                }
                                

                                </div>


                                <div className="memory-game-field">

                                {positions.map((pos, i) =>
                                {
                                return  (
                                <div className="cards">
                                <img className={openCards[i] == 0 ? '' : 'hidden'} onClick={this.flipCard.bind(this,i)} src="/images/memory/card_back.svg"/>
                                <img className={openCards[i] != 0 ? '' : 'hidden'} src={`/images/memory/${cards[pos]}`}/>
                                </div>
                                )
                                })
                                }

                                </div>
                                <div className="memory-align-bottom">
                                <p className={finished && ! finished_quiz ? 'correct-pair' : 'hidden'}>Super, diese Runde wäre geschafft! Nun kannst du im Quiz die Ereignisse chronologisch einordnen, bevor du dir das nächste Kapitel vornimmst.</p>
                                <button href="#" onClick={finished_quiz ? this.toOverview : this.toQuiz} className={finished ? 'memory-game-button' : 'hidden'} disabled={!finished}>{finished_quiz ? 'Zurück' : 'Zum Quiz'}</button>
                                <img onClick={this.openSources} src="/images/memory/sources_icon.svg"/>
                                </div>

                                {this.state.showSources &&
                    <div className="overlay-panel-container">
                        <div className="overlay-panel">
                            <div className="close" style={{gridRow:"1"}} onClick={this.closeSources}></div>
                            <div className="definition-header" style={{gridRow:"1"}}>Quellenangaben</div>
                            <div className="section-wrapper" style={{gridRow:"2"}}>
                            {
                                memoryItem.sources && memoryItem.sources.map((source) => (
                                    <div className="section">
                                        <p><span className="section-title">{source.title}</span>: {source.license_url=='' ?<span>{source.license_type}</span> : <span><a target="_blank" href={source.license_url}>{source.license_type}</a>, </span> } 
                                        {source.source && <a target="_blank" href={source.url}>{source.source}</a>},  neu gezeichnet</p>

                                    </div>
                                )

                                )
                            }
                            </div>
                        </div>
                    </div>}

                                </>

                                )
                             })
                        }
                </section>


            </React.Fragment>
        );
    }

}

export default MemoryGame;