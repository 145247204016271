import React, { Component } from "react";
import { Link } from "react-router-dom";

class CodeWeekOverview extends Component {
  packets = [
    {
      image: "/images/code-week/tiles/B1.svg",
      link: "/code-week/paket1",
    },
    {
      image: "/images/code-week/tiles/B2.svg",
      link: "/code-week/paket2",
    },
    {
      image: "/images/code-week/tiles/B3.svg",
      link: "/code-week/paket3",
    },
  ];

  render() {
    return (
      <React.Fragment>
        <section className="content">
          <div className="code-week-page">
            <h1 className="header1 font-main-header text-center">
              Die Code Week hat gestartet!
            </h1>
            <div className="header2 font-sub-header text-center">
              <p>
                Ganz Europa ist am Coden und wir machen mit! Hier könnt ihr 3
                Pakete aus unserem KI-Kurs finden, die wir für euch
                zusammengestellt haben.
              </p>
              {/* </div>
            <div className="header2 font-sub-header text-center"> */}
              <p>
                Lernt die Programmier-Grundlagen mit Python und wendet eure
                neuen Skills an. Außerdem gibt es was über KI zu lernen:
                Diskutiert die ethischen Aspekte von KI-Technologien und erfahrt
                mehr über die Geschichte von IT-Pionierfrauen.
              </p>
            </div>
            <div className="container-fluid">
              <div className="packet-tiles">
                {this.packets.map((packet, index) => (
                  <div className="tile-wrapper" key={index}>
                    <Link to={packet.link} key={index} disabled="true">
                      <img
                        className="tile-image"
                        src={packet.image}
                        alt="packet"
                      />
                    </Link>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default CodeWeekOverview;
