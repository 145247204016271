import React, { Component } from 'react';
import authService from '../services/authService';
import { Link } from 'react-router-dom';
import * as alert from '../alert.json';
import userService from '../services/userService'
import ViewModeAlert from '../components/viewModeAlert';

class ethics extends Component {
    state = {
        subPaths: [{
            course: 'ki_and_society',
            title: "KI & Gesellschaft",
            description: "Hier findest du eine Vielzahl an Artikeln zu verschiedenen Themen, in denen KI Anwendung in unserem gesellschaftlichen Leben findet.",
            subHeader: "Trainiere kritisches Denken",
            link: "/ethics/course=ki_and_society",
            image: "aiSociety.svg",
            tasksAttempted: 0,
            totalTasks: 32
        },
        {
            course: 'ethics',
            title: "Ethisch fragwürdig",
            description: "Hier lernst du KI-Anwendungen zu hinterfragen und diskutierst mit deiner eigenen Arbeitsgruppe Vorteile und Nachteile.",
            subHeader: "Diskutiere KI-Anwendungen",
            link: "/ethics/ethically-questionable/intro-video",
            image: "Wie_ethisch_fragwürdig.svg",
            tasksAttempted: 0,
            totalTasks: 14
        },
        {
            course: '',
            title: "KI-Regulierungen",
            description: "In diesem Kapitel könnt ihr erfahren, wie KI-Anwendungen gesetzlich reguliert werden sollen und welche Strategien dabei verfolgt werden.",
            subHeader: "Rechtlicher Rahmen",
            link: "/ethics/ai-regulations",
            image: "coming_soon_regulations.svg",
            tasksAttempted: "",
            totalTasks: ""
        }
        ],
        viewMode: true,
        taskAiSociety: 0,
        taskEthics: 0,
        showViewModeMessage: false


    }

    componentDidMount() {
        let user = authService.getCurrentUser();
        if (!user) {
            this.setState({
                viewMode: true
            })
        }
        else {
            this.setState({ viewMode: false })
            let tasksDog = 0;
            let tasksRecycling = 0;
            this.state.subPaths.map(subPath => {
                if (!subPath.course) return;
                userService.getAttemptedTasksForCourse(subPath.course).then(({ data: response }) => {
                    let sum = 0;
                    if (subPath.course === 'ki_and_society') {
                        response.map((subchapter) => {
                            sum += subchapter.num_attempted;
                        })
                        // let aiSociety = subPaths.find(path => path.course === 'ki_and_society')

                        this.setState({ taskAiSociety: sum })
                    }
                    else if (subPath.course === 'ethics') {
                        response.map((subchapter) => {
                            sum += subchapter.num_attempted;
                        })
                        this.setState({ taskEthics: sum })
                    }

                })
            })
        }


    }

    removeAlert = () => {
        this.setState({
            showAlert: false
        })
    }

    onTileClick = (subPath, event) => {
        // Commenting this out as we no longer have to stop here for login for the ethics course
        // if(this.state.viewMode && subPath.course === 'ethics'){
        //     event.preventDefault()
        //     this.toggleViewModeMessage()
        // }
    }

    onRegister = () => {
        this.props.history.push({
            pathname: '/auth', state: { prevPath: this.props.location.pathname }
        })
        this.setState({ showViewModeMessage: false })
    }


    toggleViewModeMessage = () => {
        this.setState({ showViewModeMessage: !this.state.showViewModeMessage })
    }


    render() {
        const user = authService.getCurrentUser();
        let viewMode = true;
        // if (!user && viewMode) this.props.history.push('/auth')

        const { taskAiSociety, taskEthics } = this.state
        let course = this.props.match.params;
        if (user) viewMode = false;
        return (
            <React.Fragment>
                <section className="content">

                    {/* <h1 className="homepage header"></h1>
                    <h3 className="homepage sub-header"></h3> */}

                    <div className="container-fluid home-wrapper">
                        <div className="subpath-tiles">

                            {this.state.subPaths.length > 0 && this.state.subPaths.map((subPath) => (
                                <Link to={`${subPath.link}`} className="subpath-tile" onClick={this.onTileClick.bind(this, subPath)}>
                                    <div className="tile-wrapper">
                                        <img className="image negative-margin" src={`/images/icons/subpaths/ethics/${subPath.image}`} />
                                        <div className="row2 primary-color-lightgreen-background">
                                            <div className="tile-sub-header">{subPath.subHeader}</div>
                                            <div className="tile-header">{subPath.title}</div>
                                            <div className="tile-description">{subPath.description}</div>
                                            {!viewMode && <div className="tile-status"> {subPath.totalTasks && `${subPath.tasksAttempted} / ${subPath.totalTasks} Aufgaben bearbeitet`}
                                            </div>}
                                        </div>
                                    </div>
                                </Link>))
                            }


                        </div>
                    </div>
                    {this.state.showViewModeMessage && <ViewModeAlert onRegister={this.onRegister} onCancel={this.toggleViewModeMessage} />}

                </section>
            </React.Fragment>
        );
    }
}

export default ethics;
