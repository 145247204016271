import React, { Component } from 'react';
import { getUserIdColor } from '../../services/authService';
import Identicon from 'react-identicons';
import christmasLeaderboardService from '../../services/christmasLeaderboardService';
import Loading from '../common/loading'

class ChristmasLeaderboard extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            users: [],
            error: false,
            isLoading: true,
            lastPage: null,
        }
        this.handleScroll = this.handleScroll.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
        this.getData();
        // var elm = document.getElementsByTagName('section')[0];
        // if (elm) {
        //     elm.addEventListener('scroll', this.handleScroll)
        // }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (this.state.page !== nextState.page || this.state.users.length !== nextState.users.length) {
            return true;
        }
        return true;
    }

    handleScroll() {
        const { error, isLoading, page, lastPage } = this.state;
        if (error || isLoading || (lastPage && page === lastPage)) return;
        var elm = document.getElementsByTagName('section')[0];
        var list = document.getElementById('leaderboard-list');
        if (elm.scrollTop + window.innerHeight >= list.offsetHeight) {
            this.setState({
                isLoading: true
            })
            this.getData();
        }
    };

    getData = () => {
        let { page, users } = this.state;

        christmasLeaderboardService.getTopChristmasScores(page).then(({ data }) => {
            let allUsers = users;
            const dataUsers = data.users;

            dataUsers.forEach(user => {                
                allUsers.push({
                    id: user.id,
                    username: user.username,
                    points: user.points
                })
            })

            if (this._isMounted) {
                this.setState({
                    page: page + 1,
                    lastPage: data.last_page,
                    isLoading: false,
                    users: allUsers
                })
            }
        }).catch(err => {
            if (this._isMounted) {
                this.setState({
                    page: page,
                    isLoading: false
                })
            }
        })
    }

    render() {
        const { users,isLoading } = this.state;

        if (!this.props.user) return (<React.Fragment></React.Fragment>)
        return (
            <React.Fragment>
                <section className="content question">
                   {!isLoading &&  <div className="container-fluid">
                        <div className="container-limit my-limit">
                            <div className="bestenliste">
                                <div style={{marginTop:"5rem"}}>
                                    <h1 className="homepage header1 font-main-header text-center">Bestenliste</h1>
                                    
                                </div>
                                <div className="table-list">
                                    {users.map((user, i) =>
                                        <div key={user.id} className="liste-wrapper">
                                            <div className="number-list">
                                                <span>{i + 1}</span>
                                            </div>
                                            <div className="name-list border-wrapper">
                                                <span>{user.username}</span>
                                            </div>
                                            <div className="points-list border-wrapper">
                                                <span>{user.points}</span>
                                            </div>
                                            <div className="avatar-list border-wrapper">
                                                <span><Identicon palette={[getUserIdColor(user.id)]} string={user.username} /></span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>}
                    { isLoading && <Loading />}
                </section>
                
            </React.Fragment>
        );
    }
}

export default ChristmasLeaderboard;