import React, { Component } from 'react';
import assignmentsService from '../../services/assignmentsService';
import questionService from '../../services/questionService';
import userService from '../../services/userService'
import authService from '../../services/authService'
import { element, number } from 'prop-types';
import memorys from '../../json/memory.json'
import Loading from '../common/loading'


class MemoryGame extends Component {

    state = {
        chapterId: this.props.match.params.chapterId,
        questionId: this.props.match.params.id,
        current_answer: 0,
        correct_answer: false,
        false_answer: false,
        finished: false,
        success: null
    }

    componentDidMount() {
    }

    onSelectAnswer = (id) => {
        this.setState({
            current_answer: id
        })
    }

    submitQuestions = () => {

    const currentAnswer = this.state.current_answer;

    const correctAnswerPerId = [6, 4, 1, 3, 2, 5];

        let answers = [
            {"id": 1, "checked": currentAnswer == 1},
            {"id": 2, "checked": currentAnswer == 2},
            {"id": 3, "checked": currentAnswer == 3},
            {"id": 4, "checked": currentAnswer == 4},
            {"id": 5, "checked": currentAnswer == 5},
            {"id": 6, "checked": currentAnswer == 6},
        ]

        let questionSubmit = {}
        questionSubmit.chapterId = this.state.chapterId;
        questionSubmit.id = this.state.questionId;
        questionSubmit.type = "milestones_memory";
        questionSubmit.answers = answers;
        questionService.submitQuestions(questionSubmit).then(({ data: response }) => {

            this.setState({
                correct_answer: response.correct,
                false_answer: !response.correct,
                current_answer: correctAnswerPerId[this.state.questionId -1],
                success: true
            })

        }).catch(err => {
            this.setState({
                success: false
            })
        })
    }

    onNextButton = () => {
        this.setState({
                current_answer: 0,
                correct_answer: false,
                false_answer: false,
                success: null
            });
        this.props.history.push(`/milestones/memory/quiz/chapter=${this.state.chapterId}/id=${parseInt(this.state.questionId) + 1}`)
    }

    toMilestone = () => {
      this.props.history.push({
        pathname: "/milestones/memory/timeline",
      })
     }


    render() {
        const {chapterId, questionId, current_answer, correct_answer, false_answer, finished, success} = this.state;
        const user = authService.getCurrentUser();
        if (!user) this.props.history.push('/auth');

        const positions = [0, 3, 4, 11, 5, 6, 1, 8, 10, 9, 2, 7];
        const cards = [];

        return (
            <React.Fragment>
                <section className="main-content memory-game">

                        {memorys.allMemorys && memorys.allMemorys.filter(el => el.chapter == chapterId).map( (memoryItem,index) =>

                             {

                                return (
                                <>
                                <div className="memory-game-description">
                                <h2 className="homepage header2 font-main-header text-left" style={{ marginLeft: 0 }}>{memoryItem.chapter.toString() + " - " +  memoryItem.title}</h2>
                                <p>Ordne nun die Ereignisse chronologisch zu. Findest du die entsprechende Jahreszahl? Klicke auf ein Feld und werte deinen Tipp aus, um zu sehen, ob du richtig liegst.</p>
                                <p className={correct_answer ? 'correct-pair' : 'hidden'} style={{ fontWeight: 'bold' }}>Richtig zugeordnet. Weiter geht's!</p>
                                <p className={false_answer ? 'false-pair' : 'hidden'} style={{ fontWeight: 'bold' }}>Leider nicht richtig. Die richtige Antwort wird dir angezeigt. Weiter geht's!</p>

                                </div>


                                <div className="memory-quiz-area">
                                <div className="memory-quiz-field">

                                <img className="" src={`/images/memory/${memoryItem.pairs[questionId - 1].image_1}`}/>
                                <img className="" src={`/images/memory/${memoryItem.pairs[questionId - 1].image_2}`}/>

                                {memoryItem.answers.map((ans, i) =>
                                {
                                return  (
                                <div className={`answer-content ${current_answer == i+1 ? 'active' : ''} ${success == true && current_answer == i+1 ? 'correct-answer' : ''}`}  id={i +1} onClick={success ? null : this.onSelectAnswer.bind(this, i +1)}
                                >
                                <p>{ans.title}</p>
                                </div>
                                )
                                })
                                }
                                <button href="#" onClick={this.submitQuestions} disabled={success || current_answer == 0} className="memory-game-button evaluate-quiz">Auswerten</button>
                                <button onClick={questionId == 6 ? this.toMilestone : this.onNextButton} className="memory-game-button" disabled={!success}>Weiter</button>

                                </div>

                                </div>
                                </>

                                )
                             })
                        }
                </section>


            </React.Fragment>
        );
    }

}

export default MemoryGame;