import http from './httpService';

const {
    REACT_APP_WEB_API_URL: web_api_url,
} = process.env;

export async function getTopAiChallengeScores(page) {
    return await http.get(`${web_api_url}/top_aichallenge_scores`);
}

export default {
    getTopAiChallengeScores
}