import React from "react";
import Form from "../common/form";
import EditUser from "./components/edit_user";
import userService from "../../services/userService";

class UserProfile extends Form {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      errors: [],
      togglePopup: false,
      showInvitations: false,
      showTeams: false,
      teamNameForAction: "",
    };
  }

  componentDidMount() {
    userService
      .getUser()
      .then(({ data: response }) => {
        this.setState({
          data: response,
        });
      })
      .catch((err) => {
        console.log(err);
        let error = "Es ist ein unerwarteter Fehler aufgetreten.";
        if (err.response && err.response.status === 400) {
          error = err.response.data.error;
        }
        this.setState({ errors: error });
      });
    // this.getUser();
  }

  getUser() {
    if (this.props.user) {
    }
  }

  render() {
    const { data, errors } = this.state;
    return (
      <React.Fragment>
        <section className="content question">
          <div className="container-fluid">
            <div className="container-limit">
              <div className={`user-profile`}>
                <EditUser response={data} historyProp={this.props.history} />
              </div>
            </div>
          </div>
          {errors && (
            <div className="error text-center">
              <span className="font-main-header text-danger">{errors}</span>
            </div>
          )}
        </section>
      </React.Fragment>
    );
  }
}

export default UserProfile;
