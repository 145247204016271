import React from 'react';
import AceEditor from 'react-ace';
import 'brace/mode/python';
import 'brace/theme/monokai';
import Button from '../common/button';
import CodeResponse from './code-response';
import { Progress } from 'reactstrap';
import Markdown from '../common/markdown'

const CodeContent = ({ assign: props, chapter, title, onChange, submitCode, canSubmit, assignmentIndex, loading, assignmentsLength, resetCode }) => {
  const { description, id, code, previous_submissions, results } = props;

  return (
    <React.Fragment>
      <div className="code-page-limit">
        <div className="progressBar">
          <div className="progress-table-bar">
            {/* <div>
              <Progress value={assignmentIndex} max={assignmentsLength}><span>{assignmentIndex}/{assignmentsLength}</span></Progress>
            </div> */}
            <div className="progress-div">
                <Progress value={assignmentIndex} max={assignmentsLength}></Progress>
                <div className="progress-span">{assignmentIndex}/{assignmentsLength}</div>
            </div>
          </div>
        </div>
        {/* remove hard coded header and replace with chapter's header */}
        {/* { assignment && assignment.title && } */}
        <div className="task-item-lightheader">Kapitel {id.split('_')[0]}: {chapter}</div>
        <div className="task-item-mainheader">{title}</div>

        <div className="tittle no-padding-sides">
          {/* <span dangerouslySetInnerHTML={{ __html: description }}></span> */}
        <div className="intro-wrapper">
	  	
          <Markdown
            className="intro"
            children={description}
          />
          </div>
        </div>
      </div>
      <div className="bottom-part">
        <div className="row helper-class">
          <div className="code-page-limit">
            <div id="editor" className="ace-editor-wrapper">
           
              <AceEditor name={id.toString()}
                value={previous_submissions ? code.submission : code}
                mode='python'
                theme='monokai'
                height='300px'
                width="100%"
                onChange={(e) => onChange(id, e)}
                editorProps={{ $blockScrolling: true }} />
            </div>
            <Button disabled={loading} href='#' className='code-reset-button' label={loading === 'reset' ? 'Resetting': 'RESET'} onClick={() => resetCode(id)} />
            {
                canSubmit && <Button disabled={loading} href='#' className='code-response-button' label={loading === 'save' ? "Loading..." : "RUN"} onClick={() => submitCode(id, null)} />
            }
          </div>
        </div>
        <div className="row helper-class">
        <div className="code-page-limit">
            <div className="code-response">
              <div className="response-first-part">
                {previous_submissions && <CodeResponse results={results.results} />}
              </div>
              <div className="response-second-part">
                {previous_submissions && <label>Last updated: {code.last_modified}</label>}
              </div>
            </div>
        </div>
      </div>
      </div>
    </React.Fragment>
  );
}

export default CodeContent;
