import React, { Component } from "react";
import Button from "../common/button";
import assignmentsService from "../../services/assignmentsService";
import { Progress } from "reactstrap";
import Markdown from "../common/markdown";
import AppContext from "../common/appContext";
import App from "../../App";
// Makes use of : https://github.com/react-syntax-highlighter/react-syntax-highlighter/blob/master/AVAILABLE_LANGUAGES_HLJS.MD
import SyntaxHighlighter from "react-syntax-highlighter";
import {
  vs2015,
  atelierDuneLight,
} from "react-syntax-highlighter/dist/esm/styles/hljs";
import Loading from "../common/loading";

class ConclusionPage extends Component {
  constructor(props) {
    super(props);
    const { params, path } = this.props.match;
    this.state = {
      id: params.id,
      course: params.course.toLowerCase().replace(" ", "_"),
      // The below is specifically to handle tasks from task-archive
      returnURL: params.course,
      assignment: {},
      chapters: [],
      showLoading: true,
      currentTaskNum: 0,
      maxQuestions: 0,
    };
    this.rootPath = path.split("/").slice(0, -3).join("/");
  }

  componentDidMount() {
    const { id, assignment, course } = this.state;
    assignmentsService
      .getAssignments(course)
      .then(({ data: response }) => {
        let assignment = response.assignments.find((p) => p.id === id);

        let conclusionMarkdown = assignment.end;
        let newMarkDown = conclusionMarkdown.replace(
          /URL_FOR_IMAGES\//g,
          "/images/" + course + "/"
        );
        assignment.end = newMarkDown;

        // handle progress
        let chapterQuestionsNum = 0;
        let currentTaskNum = 0;
        let currentSubChapter = this.state.id.slice(-2);
        let currentChapter = this.state.id.split("_")[0];
        response.assignments.forEach((item, index) => {
          let chapterId = item.id.split("_")[0];
          let subChapterId = item.id.slice(-2);
          if (chapterId === currentChapter) {
            let currentSubchapterQuestions = 0;
            if (item.questions) {
              currentSubchapterQuestions = JSON.parse(item.questions)
                .allQuestions.length;
            }

            if (item.intro) {
              chapterQuestionsNum += 1;
              if (subChapterId <= currentSubChapter) {
                currentTaskNum += 1;
              }
            }

            if (item.course_data) {
              chapterQuestionsNum += 1;
              if (subChapterId <= currentSubChapter) {
                currentTaskNum += 1;
              }
            }

            if (item.description) {
              chapterQuestionsNum += 1;
              if (subChapterId <= currentSubChapter) {
                currentTaskNum += 1;
              }
            }

            if (item.end) {
              chapterQuestionsNum += 1;
              if (subChapterId < currentSubChapter) {
                currentTaskNum += 1;
              }
            }

            chapterQuestionsNum += currentSubchapterQuestions;
            if (subChapterId <= currentSubChapter)
              currentTaskNum += currentSubchapterQuestions;

            if (subChapterId === currentSubChapter) {
              currentTaskNum = currentTaskNum;
            }
          }
        });

        this.setState({
          assignment,
          chapters: [].concat(response.assignments),
          showLoading: false,
          maxQuestions: chapterQuestionsNum,
          currentTaskNum: currentTaskNum,
        });
      })
      .catch((err) => {});
  }

  onNextFromConclusion = () => {
    const { chapters, assignment, course, returnURL } = this.state;
    const queryParams = new URLSearchParams(this.props.location.search);

    let nextAssignment = chapters[chapters.indexOf(assignment) + 1];
    if (!nextAssignment) {
      this.props.history.push({
        pathname: `${this.rootPath}/course=${returnURL}`,
      });
      return;
    }
    // TODO @mtc-20 Find out why this page has logic to check for intro and video pages when there is no "Back" button
    if (assignment.id.split("_")[0] === nextAssignment.id.split("_")[0]) {
      if (nextAssignment.video) {
        this.props.history.push({
          pathname: `${this.rootPath}/chapter-video/course=${returnURL}/id=${nextAssignment.id}`,
        });
      } else if (nextAssignment.intro) {
        this.props.history.push({
          pathname: `${this.rootPath}/introduction/course=${returnURL}/id=${nextAssignment.id}`,
        });
      } else if (nextAssignment.course_data) {
        this.props.history.push({
          pathname: `${this.rootPath}/course-content/course=${returnURL}/id=${nextAssignment.id}`,
        });
      } else if (nextAssignment.questions) {
        let allQuestions = JSON.parse(nextAssignment.questions);
        this.props.history.push({
          pathname: `${this.rootPath}/code-questions/course=${returnURL}/id=${nextAssignment.id}/questionId=${allQuestions.allQuestions[0].id}`,
        });
      } else if (
        nextAssignment.description &&
        nextAssignment.description !== null
      ) {
        this.props.history.push({
          pathname: `${this.rootPath}/code-submission/course=${returnURL}/id=${nextAssignment.id}`,
        });
      } else if (nextAssignment.end) {
        this.props.history.push({
          pathname: `${this.rootPath}/conclusion-page/course=${returnURL}/id=${nextAssignment.id}`,
        });
      } else {
        if (queryParams.has("code_week")) {
          const path = this.rootPath.split("/")[0];
          const paket = queryParams.get("code_week");
          this.props.history.push({
            pathname: `${path}/code-week/paket${paket}`,
          });
        } else {
          this.props.history.push({
            pathname: `${this.rootPath}/course=${course}`,
          });
        }
      }
    } else {
      if (queryParams.has("code_week")) {
        const path = this.rootPath.split("/")[0];
        const paket = queryParams.get("code_week");
        this.props.history.push({
          pathname: `${path}/code-week/paket${paket}`,
        });
      } else {
        this.props.history.push({
          pathname: `${this.rootPath}/course=${course}`,
        });
      }
    }
  };

  // Adding back button for the introduction
  onConclusionBackButton = () => {
    const { chapters, assignment, course } = this.state;
    const queryParams = new URLSearchParams(this.props.location.search);
    let prevAssignment = chapters[chapters.indexOf(assignment) - 1];

    if (prevAssignment.description && prevAssignment.description !== null) {
      this.props.history.push({
        pathname: `${this.rootPath}/code-submission/course=${course}/id=${assignment.id}`,
        search: queryParams.toString(),
      });
    } else if (assignment.questions) {
      let allQuestions = JSON.parse(assignment.questions);
      let questionNum = allQuestions.allQuestions.length - 1;
      this.props.history.push({
        pathname: `${this.rootPath}/code-questions/course=${course}/id=${assignment.id}/questionId=${allQuestions.allQuestions[questionNum].id}`,
        search: queryParams.toString(),
      });
    } else if (assignment.course_data) {
      this.props.history.push({
        pathname: `${this.rootPath}/course-content/course=${course}/id=${assignment.id}`,
        search: queryParams.toString(),
      });
    } else if (assignment.intro) {
      this.props.history.push({
        pathname: `${this.rootPath}/introduction/course=${course}/id=${assignment.id}`,
        search: queryParams.toString(),
      });
    } else if (assignment.video) {
      this.props.history.push({
        pathname: `${this.rootPath}/chapter-video/course=${course}/id=${assignment.id}`,
        search: queryParams.toString(),
      });
    } else {
      // If current assignment doesnt have ano other content
      if (!prevAssignment) {
        this.props.history.push({
          pathname: `${this.rootPath}/course=${this.course}`,
        });
      } else {
        if (prevAssignment.end) {
          this.props.history.push({
            pathname: `${this.rootPath}/conclusion-page/course=${course}/id=${prevAssignment.id}`,
            search: queryParams.toString(),
          });
        } else if (
          prevAssignment.description &&
          prevAssignment.description !== null
        ) {
          this.props.history.push({
            pathname: `${this.rootPath}/code-submission/course=${course}/id=${prevAssignment.id}`,
            search: queryParams.toString(),
          });
        } else if (prevAssignment.questions) {
          let allQuestions = JSON.parse(prevAssignment.questions);
          let questionNum = allQuestions.allQuestions.length - 1;
          this.props.history.push({
            pathname: `${this.rootPath}/code-questions/course=${course}/id=${prevAssignment.id}/questionId=${allQuestions.allQuestions[questionNum].id}`,
            search: queryParams.toString(),
          });
        } else if (prevAssignment.course_data) {
          this.props.history.push({
            pathname: `${this.rootPath}/course-content/course=${course}/id=${prevAssignment.id}`,
            search: queryParams.toString(),
          });
        } else if (prevAssignment.intro) {
          this.props.history.push({
            pathname: `${this.rootPath}/introduction/course=${course}/id=${prevAssignment.id}`,
            search: queryParams.toString(),
          });
        } else if (prevAssignment.video) {
          this.props.history.push({
            pathname: `${this.rootPath}/chapter-video/course=${course}/id=${prevAssignment.id}`,
            search: queryParams.toString(),
          });
        } else {
          this.props.history.push({
            pathname: `${this.rootPath}/course=${this.course}`,
          });
        }
      }
    }
  };

  render() {
    const {
      assignment,
      id,
      course,
      chapters,
      showLoading,
      currentTaskNum,
      maxQuestions,
    } = this.state;
    return (
      <AppContext.Consumer>
        {(context) => {
          if (context === undefined) {
            throw new Error("AppConsumer must be used within a AppProvider");
          }
          let chapter_header = "";
          if (course) {
            chapter_header = context.tutorials.find((p) => p.course === course)
              .chapters[id.split("_")[0] - 1];
          }

          return (
            <React.Fragment>
              <section className="content question">
                {!showLoading && (
                  <div className="container-limit my-limit">
                    <div className="intro-section">
                      {assignment.end && (
                        <React.Fragment>
                          <div className="progressBar">
                            <div className="progress-table-bar">
                              {/* <div>
                                                          <Progress value={currentTaskNum+1} max={maxQuestions}><span>{currentTaskNum+1}/{maxQuestions}</span></Progress>
                                                      </div> */}
                              <div className="progress-div">
                                <Progress
                                  value={currentTaskNum + 1}
                                  max={maxQuestions}
                                ></Progress>
                                <div className="progress-span">
                                  {currentTaskNum + 1}/{maxQuestions}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="task-item-lightheader">
                            Kapitel {id.split("_")[0] - 0}: {chapter_header}
                          </div>
                          <div className="task-item-mainheader">
                            Zusammenfassung
                          </div>
                          <div className="intro-wrapper">
                            {/* <iframe className="intro" title='intro' width="100%" height="100%"  srcDoc={assignment.intro} frameBorder="0" allow="accelerometer; encrypted-media;" allowFullScreen>
                                                    </iframe> */}
                            <Markdown
                              className="intro"
                              linkTarget="_blank"
                              children={assignment.end}
                            />
                          </div>
                          <div className="button-wrapper">
                            <div className="button-video">
                              {chapters[chapters.indexOf(assignment) - 1] && (
                                <Button
                                  href="#"
                                  onClick={this.onConclusionBackButton}
                                  label="Zurück"
                                />
                              )}
                              <Button
                                href="#"
                                onClick={this.onNextFromConclusion}
                                label="Weiter"
                              />
                            </div>
                          </div>
                        </React.Fragment>
                      )}
                    </div>
                  </div>
                )}
                {showLoading && <Loading />}
              </section>
            </React.Fragment>
          );
        }}
      </AppContext.Consumer>
    );
  }
}

export default ConclusionPage;
