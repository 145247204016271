import React, { Component } from 'react';
import { cooperation } from './sponsors.json'

function Cooperation() {
    return (
        <div className="cooperation">
            <h3>IN KOOPERATION MIT</h3>
            {
                cooperation.map((sponsor, index) =>
                    <div key={index} className="womix__image-wrapper">
                        <a href={sponsor.url} target="_blank">
                            {sponsor.image && <img src={`${sponsor.image}`} alt={sponsor.name} />}
                            {sponsor.text && <h4>{sponsor.text}</h4>}
                        </a>
                    </div>
                )
            }
        </div>
    )
}

export default Cooperation;