import React, { Component } from "react";
import Button from "../common/button";
import assignmentsService from "../../services/assignmentsService";

class VideoPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.match.params.id,
      course: this.props.match.params.course,
      assignment: {},
      chapters: [],
    };
    this.rootPath = this.props.match.path.split("/").slice(0, -3).join("/");
  }

  componentDidMount() {
    const { id, assignment, course } = this.state;
    assignmentsService
      .getAssignments(course)
      .then(({ data: response }) => {
        let assignment = response.assignments.find((p) => p.id === id);
        this.setState({
          assignment,
          chapters: [].concat(response.assignments),
        });
        if (!assignment.video) {
          this.onContinue(true);
        }
      })
      .catch((err) => {});
  }

  onContinue = (onLoad) => {
    const { chapters, assignment, course } = this.state;
    const queryParams = new URLSearchParams(this.props.location.search);

    if (assignment.intro && assignment.intro !== null) {
      this.props.history.push({
        pathname: `${this.rootPath}/introduction/course=${course}/id=${assignment.id}`,
        search: queryParams.toString(),
      });
    } else if (assignment.course_data && assignment.course_data !== null) {
      this.props.history.push({
        pathname: `${this.rootPath}/course-content/course=${course}/id=${assignment.id}`,
        search: queryParams.toString(),
      });
    } else if (assignment.questions && assignment.questions !== null) {
      let allQuestions = JSON.parse(assignment.questions);
      this.props.history.push({
        pathname: `${this.rootPath}/code-questions/course=${course}/id=${assignment.id}/questionId=${allQuestions.allQuestions[0].id}`,
        search: queryParams.toString(),
      });
    } else if (assignment.description && assignment.description !== null) {
      this.props.history.push({
        pathname: `${this.rootPath}/code-submission/course=${course}/id=${assignment.id}`,
        search: queryParams.toString(),
      });
    } else {
      if (chapters[chapters.indexOf(assignment) + 1]) {
        this.props.history.push({
          pathname: `${this.rootPath}/chapter-video/course=${course}/id=${
            chapters[chapters.indexOf(assignment) + 1].id
          }`,
          search: queryParams.toString(),
        });
      } else if (!onLoad) {
        this.props.history.push({
          pathname: `${this.rootPath}/course=${course}`,
        });
      }
    }
  };

  // Adding back button for the introduction
  onVideoBackButton = () => {
    const { chapters, assignment, course } = this.state;
    const queryParams = new URLSearchParams(this.props.location.search);
    let prevAssignment = chapters[chapters.indexOf(assignment) - 1];
    if (prevAssignment) {
      if (prevAssignment.description && prevAssignment.description !== null) {
        this.props.history.push({
          pathname: `${this.rootPath}/code-submission/course=${course}/id=${prevAssignment.id}`,
          search: queryParams.toString(),
        });
      } else if (prevAssignment.questions) {
        let allQuestions = JSON.parse(prevAssignment.questions);
        let questionNum = allQuestions.allQuestions.length - 1;
        this.props.history.push({
          pathname: `${this.rootPath}/code-questions/course=${course}/id=${prevAssignment.id}/questionId=${allQuestions.allQuestions[questionNum].id}`,
          search: queryParams.toString(),
        });
      } else if (prevAssignment.intro) {
        this.props.history.push({
          pathname: `${this.rootPath}/intro/course=${course}/id=${prevAssignment.id}`,
          search: queryParams.toString(),
        });
      } else if (prevAssignment.video) {
        this.props.history.push({
          pathname: `${this.rootPath}/chapter-video/course=${course}/id=${prevAssignment.id}`,
          search: queryParams.toString(),
        });
      } else {
        this.props.history.push({
          pathname: `${this.rootPath}/course=${course}`,
        });
      }
    }
  };

  render() {
    const { assignment, id, chapters } = this.state;
    return (
      <React.Fragment>
        <section className="content question">
          <div className="container-limit my-limit">
            <div className="multiple-choise-video">
              {assignment.video && (
                <React.Fragment>
                  {assignment && assignment.title && (
                    <div className="task-item-lightheader">
                      Kapitel {id.split("_")[0]}: {assignment.title}
                    </div>
                  )}
                  <div className="video-wrapper">
                    <iframe
                      title="entry-video"
                      width="560"
                      height="315"
                      src={assignment.video_url}
                      frameBorder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </div>
                  <div className="button-wrapper">
                    <div className="button-video">
                      {chapters[chapters.indexOf(assignment) - 1] &&
                        id.split("_")[0] !== "1" && (
                          <Button
                            href="#"
                            onClick={this.onVideoBackButton}
                            label="Zurück"
                          />
                        )}
                      <Button
                        href="#"
                        onClick={this.onContinue.bind(this, false)}
                        label="Weiter"
                      />
                    </div>
                  </div>
                </React.Fragment>
              )}
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default VideoPage;
