import React, { useState } from 'react';
import { Collapse, Card, CardBody, CardHeader } from 'reactstrap';
import {faqs} from './faq.json';

function Faqs() {

    const [isOpen, setIsOpen] = useState(null);
    const toggleQA = (idx) => { 
        setIsOpen((prev) => {
            return prev === idx ? null : idx;
        });
        // console.log(isOpen, idx);
        // console.log(faqs);

    };
    return (
        <section className="womix__horizontal-module faq">
            <div className="womix__text-module">
                <div className="info_main">
                    <h2 className="womix__gradient"> FAQ - Kurs </h2>
                    {/* TODO: import json and use .map() */}
                    {faqs && faqs.map((faq, idx) => (
                        <div className="faq-content">
                        <CardHeader onClick={() => toggleQA(idx)} className={isOpen === idx ? 'faq-panel-heading active' : 'faq-panel-heading'} key={"Q" + idx}> 
                            <a> {faq.question} </a>
                        </CardHeader>
                        <Collapse isOpen={isOpen === idx} key={"A" + idx}>
                            <Card className="border-0">
                                <CardBody>
                                {faq.answer.map( (para) => (
                                    <p> {para} </p>
                                ))} 
                                </CardBody>
                            </Card>
                            
                        </Collapse>
                    </div>

                    ))}

                </div>
            </div>
        </section>
    )
}

export default Faqs;