import React, { useEffect, useState } from 'react'
import Button from '../common/button'

const ScenarioMain = (props) => {

    const { members, scenario, pathId, pathHeader,  nextScenario:onNext, prevScenario, last} = props
    const [scenarioIntro, setScenarioIntro] = useState(true)
    const [conversation, setConversation] = useState(false)
    const [conversationMessage, setConversationMessage] = useState('')
    const [infoPanel, setInfoPanel] = useState(false)
    const [expertPanel, setExpertPanel] = useState(false)
    const [userPanel, setUserPanel] = useState(false)
    const [prosConsPanel, setProsConsPanel] = useState(false)
    const [prosCons, setProsCons] = useState(undefined)
    const [showAudioPlayer, setShowAudioPlayer] = useState(false)

    useEffect(() => {
        initProsCons()
        setConversationMessage('')
        setConversation(false)
        setInfoPanel(false)
        setExpertPanel(false)
        setUserPanel(false)
        setShowAudioPlayer(false)
        setScenarioIntro(!last)
        setProsConsPanel(last)
        return () => {
        }
    }, [scenario])

    let scenarioImgUrl = String(scenario.imgURL).replace('URL_FOR_IMAGES','/images/ethics')
    let expertImgUrl = String(scenario.expert.imgUrl).replace('URL_FOR_IMAGES','/images/ethics')
    let userImgUrl = String(scenario.user.imgUrl).replace('URL_FOR_IMAGES','/images/ethics')
    const audioBase = 'https://bwki1.fra1.digitaloceanspaces.com/courseplatform_files/audio/Scenarios/'
    let sourceDoc = `<audio style='width:100%!important' autoplay controls="true" src=${audioBase}${scenario.audioFile}  />`

    const initProsCons = async () => {
        let tempPros = []
        let tempCons = []

        // tempPros = members.arguments.
        let tempProsConsList = members.map(member => {
                let conversationItem = ''  
                try {
                    conversationItem = member.arguments.find(argument => (argument.pathId == pathId && argument.scenarioId == scenario.scenario))
                    let tempProConsItem = { id:member.personId, name:member.name, summary:conversationItem.summary, pro:conversationItem.pro }
                    console.log(conversationItem)
                    return tempProConsItem
                } catch (error) {
                    console.log(error)
                } 
        })

        tempProsConsList.push({ id:11, name:scenario.expert.name, summary:scenario.expert.summary, pro:scenario.expert.pro })
        tempProsConsList.push({ id:12, name:scenario.user.name, summary:scenario.user.summary, pro:scenario.user.pro })

        let tempProsConsObject = {
            pros:tempProsConsList.filter(item => item.pro),
            cons:tempProsConsList.filter(item => !item.pro)
        }
        console.log(tempProsConsObject)
        setProsCons(tempProsConsObject)
    }
    
    const onContinue = () => {
        
    }
    

    return (
        <div className="scenario-wrapper">
            { scenarioIntro &&
                <>
                <div className="scenario-intro-wrapper">
                    <div className="scenario-intro-header">
                        <div className="header-img">
                            {pathId == 1 && 
                                        <img src='/images/ethics/01_flow1_icone v2_hands.svg'></img>}
                            {pathId == 2 && 
                                <img src='/images/ethics/02_flow2_icone v2_Dron.svg'></img>}
                        </div>
                        <div className="header-title">
                            <h2 className="primary-color-lightgreen-foreground">{pathHeader}</h2>
                            <div>{scenario.title}</div>
                        </div>
                    </div> 
                    <div className="audio-iframe-wrapper" style={{gridRow:2, gridColumn:'1'}}>
                        {showAudioPlayer ?
                            <iframe className="audio-iframe" srcDoc={sourceDoc} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen >
                            </iframe> :
                            <div className="audio-wrapper" onClick={e => setShowAudioPlayer(true)}>
                              <div>{showAudioPlayer ?'Klicke zum pausieren':'Klicke zum Abspielen'}</div>
                              <img src="/images/ethics/play.svg" className="primary-color-lightgreen-background"/>
                            </div>
                        }          
                    </div>
                    <div className="scenario-info">
                        <div className="scenario-header">{scenario.header}</div>
                        <div className="scenario-desc">{scenario.desc}</div>
                    </div>  
                    <div className="scenario-img">
                        <img src={scenarioImgUrl} />
                    </div>
                </div>
                <div className="submit-parent-wrapper">
                    <div className="button-wrapper">
                        <Button onClick={e => {
                                    setConversation(false)
                                    setScenarioIntro(true)
                                    prevScenario()
                                }}
                                href='#'
                                className="firstBtn"
                                label="Zurück" >
                        </Button>
                        <Button href='#' 
                                label="Weiter" 
                                className='thirdBtn'
                                onClick={e => {
                                    setConversation(true)
                                    setScenarioIntro(false)
                                }} />
                    </div>
                </div>
                </>
            } 
            {   conversation && 
                <>
                    <div className="scenario-intro-wrapper">
                        <div className="scenario-intro-header mb-0">
                            <div className="header-title sub-header-center">
                                <h2 className="primary-color-lightgreen-foreground">{`${pathHeader} zur Kommunikation`}</h2>
                                <div>Klicke die einzelnen Denkenden an und lese ihr Statement. </div>
                            </div>
                        </div> 
                    </div>
                    <div className="conversation-wrapper">
                        {conversationMessage && <div className="conversation-message">
                            {conversationMessage}
                        </div>}
                        <div className="conversation-panel">
                            <ul className="panel-list">
                            {
                                members.map((member, index) => {
                                    let conversationItem = ''  
                                    try {
                                        conversationItem = member.arguments.find(argument => (argument.pathId == pathId && argument.scenarioId == scenario.scenario))
                                        console.log(conversationItem)
                                    } catch (error) {
                                        console.log(error)
                                    } 
                                    let personImgUrl = String(member.imgUrl).replace('URL_FOR_IMAGES','/images/ethics')
                                    return (
                                        <>
                                            <li onClick={e => setConversationMessage(conversationItem?conversationItem.argument:'')}>
                                                <div className="panel-item-header" >
                                                    {member.name}
                                                </div>
                                                <img className="panel-item-img" style={{cursor:'pointer'}} src={personImgUrl} >
                                                </img>
                                            </li>
                                        </>
                                    )
                                })
                            }
                            </ul>
                        </div>
                    </div>
                    <div className="submit-parent-wrapper">
                        <div className="button-wrapper">
                            <Button onClick={e => {
                                    setConversation(false)
                                    setScenarioIntro(true)
                                }}
                                href='#'
                                className="firstBtn"
                                label="Zurück" >
                            </Button>
                            <Button 
                                onClick={e => { 
                                    setInfoPanel(true) 
                                    setConversation(false)
                                }}
                                href='#'
                                className='thirdBtn'
                                label="Weiter" >
                            </Button>
                        </div>
                    </div>
                </>
            }

            { infoPanel &&
                <>
                    <div className="scenario-intro-wrapper">
                        <div className="scenario-intro-header mb-0">
                            <div className="header-title sub-header-center">
                                <h2 className="primary-color-lightgreen-foreground">{`${pathHeader} zur Kommunikation`}</h2>
                                <div>Der Diskussionsleiter verkündet folgende Nachricht:</div>
                            </div>
                        </div> 
                    </div>
                    <div className="conversation-wrapper">
                        {scenario.expert.argument && <div className="conversation-message">
                                Ich habe das Gefühl es sind noch nicht alle Meinungen vertreten. Lasst uns Expert:innen und Betroffene befragen!
                            </div>}
                    </div>
                    <div className="submit-parent-wrapper">
                        <div className="button-wrapper">
                            <Button onClick={e => {
                                    setConversation(true)
                                    setInfoPanel(false)
                                }}
                                href='#'
                                className="firstBtn"
                                label="Zurück" >
                            </Button>
                            <Button 
                                onClick={e => { 
                                    setInfoPanel(false)
                                    setExpertPanel(true)
                                 }}
                                href='#'
                                className='thirdBtn'
                                label="Weiter" >
                            </Button>
                        </div>
                    </div>
            </>
            }

            { expertPanel &&
                <>
                    <div className="scenario-intro-wrapper">
                        <div className="scenario-intro-header mb-0">
                            <div className="header-title sub-header-center">
                                <h2 className="primary-color-lightgreen-foreground">{`${pathHeader} zur Kommunikation`}</h2>
                                <div>Ein Experte/ Expertin äußert sich zu der Thematik:</div>
                            </div>
                        </div> 
                    </div>
                    <div className="conversation-wrapper">
                        {scenario.expert.argument && <div className="conversation-message">
                                {scenario.expert.argument}
                            </div>}
                        <img className="panel-item-img mt-7" style={{cursor:'pointer'}} src={expertImgUrl} />
                        <div className="panel-item-header" >
                            {scenario.expert.name}
                        </div>
                    </div>
                    <div className="submit-parent-wrapper">
                        <div className="button-wrapper">
                            <Button onClick={e => {
                                    setInfoPanel(true)
                                    setExpertPanel(false)
                                    }}
                                href='#'
                                className="firstBtn"
                                label="Zurück" >
                            </Button>
                            <Button 
                                onClick={e => { 
                                    setUserPanel(true)
                                    setExpertPanel(false)
                                 }}
                                href='#'
                                className='thirdBtn'
                                label="Weiter" >
                            </Button>
                        </div>
                    </div>
            </>
            }

            { userPanel &&
                <>
                    <div className="scenario-intro-wrapper">
                        <div className="scenario-intro-header mb-0">
                            <div className="header-title sub-header-center">
                                <h2 className="primary-color-lightgreen-foreground">{`${pathHeader} zur Kommunikation`}</h2>
                                <div>Eine betroffene Person sagt folgendes zu der Problematik:</div>
                            </div>
                        </div> 
                    </div>
                    <div className="conversation-wrapper">
                        {scenario.user.argument && <div className="conversation-message">
                                {scenario.user.argument}
                            </div>}
                        <img className="panel-item-img mt-7" style={{cursor:'pointer'}} src={userImgUrl} />
                        <div className="panel-item-header" >
                            {scenario.user.name}
                        </div>
                    </div>
                    <div className="submit-parent-wrapper">
                        <div className="button-wrapper">
                            <Button 
                                onClick={e => {
                                    setUserPanel(false)
                                    setExpertPanel(true)
                                }}
                                href='#'
                                className="firstBtn"
                                label="Zurück" >
                            </Button>
                            <Button 
                                onClick={e => {
                                    setUserPanel(false)
                                    setProsConsPanel(true)
                                 }}
                                href='#'
                                className='thirdBtn'
                                label="Weiter" >
                            </Button>
                        </div>
                    </div>
                </>
            }

            { prosConsPanel && 
                <>
                    <div className="scenario-intro-wrapper">
                        <div className="scenario-intro-header mb-0 ml-0">
                            <div className="header-title ml-0 sub-header-center">
                                <h2 className="primary-color-lightgreen-foreground">{`${pathHeader} zur Kommunikation`}</h2>
                                <div>Die Argumente der einzelnen Personen werden für die Abschlussdiskussion gegenübergestellt:</div>
                            </div>
                        </div> 
                    </div>
                    <div className="pros-cons-wrapper">
                        <div className="left">
                            <div className="summary-list-header">Pro</div>
                            {
                                prosCons.pros.map(proItem => (
                                    <div className="summary-item">
                                        <div className="summary-desc">{proItem.summary}</div>
                                        <div className="summary-info">{proItem.name}</div>
                                    </div>
                                )) 
                            }
                        </div>
                        <div className="right">
                            <div className="summary-list-header">Contra</div>
                            {
                                prosCons.cons.map(conItem => (
                                    <div className="summary-item">
                                        <div className="summary-desc">{conItem.summary}</div>
                                        <div className="summary-info">{conItem.name}</div>
                                    </div>
                                )) 
                            }
                        </div>
                    </div>
                    <div className="submit-parent-wrapper">
                        <div className="button-wrapper">
                            <Button 
                                onClick={e => {
                                    setProsConsPanel(false)
                                    setUserPanel(true)
                                }}
                                href='#'
                                className="firstBtn"
                                label="Zurück" >
                            </Button>
                            <Button 
                                onClick={e => {
                                    onNext()
                                 }}
                                href='#'
                                className='thirdBtn'
                                label="Weiter" >
                            </Button>
                        </div>
                    </div>
                </>
            }
        </div>
    )
}

export default ScenarioMain
