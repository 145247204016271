import React, { useState, useEffect, useRef } from "react";
import Button from "../common/button";
import Markdown from "../common/markdown";
import CodeEditor from "../course/CodeEditor";
import Loading from "../common/loading";
import { UnControlled as CodeMirror } from "react-codemirror2";
import codeweekService from "../../services/codeweekService.js";
import { withRouter } from "react-router-dom";

function CodeWeekChallenge({ year, challenge, ...props }) {
  const [challengeData, setChallengeData] = useState(null);
  const [isPyodideLoaded, setIsPyodideLoaded] = useState(false);
  // We are using a "mutable state" for pyodide to change values within the state without rerendering
  // This does not trigger a rerender: `pyodide.stdouterr = "Test"`
  // This does trigger a rerender: `setPyodide({stdouterr: "Test"})`
  // It is basically a workaround to achieve the useRef functionality (state without rerendering), which isn't available in React 16.7.
  const [pyodide, setPyodide] = useState({
    pyodide: null, // Actual pyodide instance for running python
    stdouterr: "", // Output/Error buffers for pyodide
  });

  const showLoading = challengeData == null;

  const setup_pyodide = () => {
    // The python code below overwrites the matplotlib.pyplot.show method,
    // if the matplotlib.pyplot (aka plt) module is getting imported.
    // The plt.show method then writes the image in an data URL to the python variable `_matplotlib_figure_data_url`.
    // Pyodide can access this data URL string and display the image in the browser.
    var setup_code = `
import sys as _sys
from importlib import import_module as _import_module
from importlib.abc import Loader as _Loader

# Overwrite the input function to make sure the prompt is displayed in the browser
# By default, the pyodide does not pass the prompt to the browser
from js import prompt
def prompt_input(text):
    return prompt(text)
__builtins__.input = prompt_input

class ImportInterceptor(_Loader):
    def __init__(self):
        self.overwritten = False

    def find_module(self, fullname, path=None):
        return self

    def load_module(self, fullname):
        _sys.meta_path = [x for x in _sys.meta_path[1:] if x is not self]
        module = _import_module(fullname)
        if fullname == 'matplotlib.pyplot' and not self.overwritten:
            self.overwritten = True
            import matplotlib.pyplot as _plt
            import base64, io

            def get_data_url_for_figure(plt):
                buf = io.BytesIO()
                fig = plt.gcf()
                fig.savefig(buf, format='png', bbox_inches="tight")
                plt.close(fig)
                buf.seek(0)
                data_url = 'data:image/png;base64,' + base64.b64encode(buf.read()).decode('UTF-8')
                return data_url

            def alternate_show(): 
                global _matplotlib_figure_data_url
                data_uri = get_data_url_for_figure(_plt)
                _matplotlib_figure_data_url = data_uri

            _plt.show = alternate_show
        _sys.meta_path = [self] + _sys.meta_path
        return module


if not hasattr(_sys,'frozen'):
    _sys.meta_path = [ImportInterceptor()] + _sys.meta_path
      `;
    pyodide.pyodide.runPythonAsync(setup_code);
    setIsPyodideLoaded(true);
  };

  const runScript = (code, setOutput, setError) => {
    let codeToRun = code.replaceAll('"""', "'''");
    pyodide.pyodide.loadPackage([]).then(() => {
      pyodide.pyodide
        .loadPackagesFromImports(codeToRun)
        .then(() => {
          pyodide.pyodide.runPython(codeToRun);
          setOutput(
            pyodide.stdouterr,
            pyodide.pyodide.globals.get("_matplotlib_figure_data_url")
          );
          pyodide.pyodide.globals.set("_matplotlib_figure_data_url", null);
          pyodide.stdouterr = "";
        })
        .catch((e) => {
          console.log(e.message);
          setError(e);
        });
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      codeweekService
        .getChallenge(year, challenge)
        .then(({ data: response }) => {
          // TODO: Check if response is valid
          setChallengeData(response);
        })
        .catch((err) => {
          // TODO: Handle Error
        });
    };
    fetchData();
    const setupPyodide = () => {
      console.log("initialzing and setting up pyodide async...");
      window
        .loadPyodide({
          indexURL: window.PYODIDE_BASE_URL,
          stdout: (line) => {
            pyodide.stdouterr += line + "\n";
          },
          stderr: (line) => {
            pyodide.stdouterr += line + "\n";
          },
        })
        .then((pyodide_instance) => {
          pyodide.pyodide = pyodide_instance;
          setup_pyodide();
        });
    };
    setupPyodide();
  }, []);

  const getContentElements = (parts) => {
    return parts.map((part) => {
      if (part.file_type == "py") {
        return (
          <CodeEditor
            course={null}
            id={null}
            courseItem={part}
            runCode={runScript}
            saveCode={null}
            loadSavedCode={null}
            isPyodideLoaded={isPyodideLoaded}
          />
        );
      } else {
        return (
          <div className="intro">
            <Markdown children={part.content} />
          </div>
        );
      }
    });
  };

  return (
    <>
      <section className="content question">
        {!showLoading && (
          <div className="container-limit my-limit">
            <div className="intro-section">
              {challengeData && (
                <>
                  <div className="task-item-lightheader mb-3">
                    Code Week Challenge
                  </div>
                  <div className="intro-wrapper">
                    {challengeData.challenge &&
                      getContentElements(challengeData.challenge)}
                  </div>
                  <div className="button-wrapper">
                    <div className="button-video">
                      <Button
                        href="#"
                        onClick={() => {
                          props.history.push({
                            pathname: props.location.pathname
                              .split("/")
                              .slice(0, -1)
                              .join("/"),
                          });
                          window.scrollTo(0, 0);
                        }}
                        label="Zurück zur Übersicht"
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        )}
        {showLoading && <Loading />}
      </section>
    </>
  );
}

export default withRouter(CodeWeekChallenge);
