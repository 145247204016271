import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import navigationHelper from "./navigation_helper.json";

const breadcrumb = (props) => {
  const { toggleBreadcrumbMenu } = props;
  const getbreadcrumb = (path) => {
    if (path === "/") path = "";
    if (path !== "/home") path = "home" + path;

    let appendItem = null;
    if (path.includes("dog")) {
      appendItem = navigationHelper.navigations.find(
        (navigation) => navigation.path === "dog"
      );
    } else if (path.includes("recycling")) {
      console.log("recycling");
      appendItem = navigationHelper.navigations.find(
        (navigation) => navigation.path === "recycling"
      );
    } else if (path.includes("ki_and_society")) {
      appendItem = navigationHelper.navigations.find(
        (navigation) => navigation.path === "ki_and_society"
      );
    } else if (path.includes("=python")) {
      appendItem = navigationHelper.navigations.find(
        (navigation) => navigation.path === "python"
      );
    } else if (path.includes("=mlbasics")) {
      appendItem = navigationHelper.navigations.find(
        (navigation) => navigation.path === "mlbasics"
      );
    } else if (path.includes("ethically-questionable/coursepath=")) {
      appendItem = navigationHelper.navigations.find(
        (navigation) => navigation.path === "coursepath"
      );
    } else if (path.includes("ethically-questionable/introduction")) {
      appendItem = navigationHelper.navigations.find(
        (navigation) => navigation.path === "ethically-questionable-intro"
      );
    } else if (path.includes("ethically-questionable/conclusion")) {
      appendItem = navigationHelper.navigations.find(
        (navigation) => navigation.path === "deutschen-ethikrat"
      );
    } else if (path.includes("ethically-questionable/paths")) {
      appendItem = navigationHelper.navigations.find(
        (navigation) => navigation.path === "coursepath"
      );
    }

    let items = path.split("/");
    let navs = items
      .map((item) => {
        return navigationHelper.navigations.find(
          (navigation) => navigation.path === item
        );
      })
      .filter((item) => item);

    if (appendItem) navs.push(appendItem);
    return navs;
  };

  const handleToddleBreadCrumbMenu = (event) => {
    toggleBreadcrumbMenu();
    event.preventDefault();
  };

  let itemsVal = getbreadcrumb(props.path);

  return (
    <React.Fragment>
      <div className="breadcrumb-main">
        {itemsVal.map((navItem, index) =>
          navItem.link === "/home" ? (
            <Link
              to={navItem.link}
              key={index}
              onClick={handleToddleBreadCrumbMenu}
            >
              {navItem.title}{" "}
            </Link>
          ) : (
            <Link key={index} to={navItem.link}>
              <span>{" > "}</span> {navItem.title}{" "}
            </Link>
          )
        )}
      </div>
    </React.Fragment>
  );
};
export default breadcrumb;
