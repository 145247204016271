import axios from 'axios';
import React, { Component } from 'react';
import Button from '../common/button';
import { getAssignments, editAssignment, submitAssignment, goToNext, resetAssignment } from '../../actions/code-submission';
import { submitUsersAssingmentCode, submitAssignmentResults, getUserAssignment } from '../../services/assignmentsService';
import CodeResponse from '../code-submission/code-response';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUpFromBracket, faFile } from '@fortawesome/free-solid-svg-icons'
import FileUploadAlert from './file-upload-alert';
/**
 * React component for **file** submission to the ki-kurs executor.
 *
 * Use the `CodeSubmission` component for submissions where the user uploads python code.
 * Use this component for file uploads (e.g. uploading prediction files). 
 * 
 * @extends Component
 */
class FileSubmission extends Component {

	state = {
		selectedFile: null,
		loading: false,
		result: null,
		fileUploadError: false,
	};

	/**
	 * Constructor for FileSubmission React component.
	 *
	 * @param props - Should contain `course` and `id` fields,
	 *                which specify the assignment to which the submission belongs.
	 */
	constructor(props) {
		super(props)
		const {course, id} = props;
		this.course = course;
		this.id = id;
		this.max_file_size = 5000000
	}

	/**
	 * Get the previous submission the user after the component did mount.
	 *
	 */
	componentDidMount = () => {
		this.getLastSubmissionResults().then( (results) => {
			this.setState({ result: results });
		});
	}

	/**
	 * Set the file in state to the file the user selected if the file size is less 
	 * than 5MB, once the file changes.
	 *
	 * @param event - Event that triggers this function call.
	 */
	onFileChange = (event) => {
		console.log("Event triggered")
		if (event.target.files) {
			console.log("File change triggered.")
			if (event.target.files[0].size > this.max_file_size) {
				this.setState({fileUploadError: true});
				// The event needs to be explicity reset otherwise Chrome will not trigger onChange if same file is uploaded again.
				event.target.files[0].value = '';
				event.target.value = '';
				return;
			}
			else
			this.setState({ selectedFile: event.target.files[0]});
		}
	}

	/**
	 * Fetches last submission of user from executor via the assignmentsService.
	 *
	 * @returns The result for the last submission by a user.
	 */
	getLastSubmissionResults = async () => {
		let response = await getUserAssignment(this.course, this.id);
		return response.data.results;
	}

	/**
	 * Submits the uploaded file, sends it to the executor,
	 * awaits response and logs results via executor, then fetches the latest result and returns it.
	 *
	 * @returns The result for this submission by the user.
	 */
	submitFile = async () => {
		if (this.state.selectedFile.size > this.max_file_size) {
			this.setState({fileUploadError: true});
			return;
		}
		else {
			console.log("Requesting user related stuff")
		let text = await this.state.selectedFile.text();
		let response = await submitUsersAssingmentCode(this.course, this.id, text);
		let results_submission = response.data.results
		await submitAssignmentResults(this.course, this.id, results_submission);
		return await this.getLastSubmissionResults();}
	}

	/**
	 * Calls submitFile, sets loading state accordingly and awaits the result.
	 * 
	*/
	onFileUpload = () => {
		this.setState({ loading: true }, () => {
			this.submitFile().then( (result_subm) => {
				this.setState({ loading: false, result: result_subm});
			})
		});
	};
	
	/**
	 * Resets the uploaded file and toggles off the file upload error state.
	 * 
	*/
	resetFileUpload = () => {
		this.setState({
			selectedFile: null,
			fileUploadError: false,
		});
	}

	/**
	 * Renders this component.
	 *
	 * @returns The React component tree.
	 */
	render = () => {
		const { loading, result, selectedFile, fileUploadError} = this.state;
		return (
			<div>
			<section>
				<div align="center">
					{!selectedFile &&
					<label for="file_upload">
						<div className="ai-challenge-button">
							<div style={{margin: "1rem"}}>
							<img style={{height: "1em"}} src="/images/ai_challenge/Upload.svg"/>&nbsp;Vorhersagen hochladen
							</div>
						</div>
					</label>}
					{selectedFile &&
						<div className="intro">
							<p>
							<FontAwesomeIcon className="icon" icon={faFile} />
							{selectedFile.name}</p>
						</div>}
					<input id="file_upload" type="file"
					 onChange={this.onFileChange} label="test"
					 style={{visibility: "hidden", width: 0, height: 0}}
					 />
				</div>
			{ loading &&
				<div className="intro" align="center">
				<p>Die Vorhersagen werden evaluiert...</p>
				</div>
			}
			{ result &&
				<CodeResponse results={result.results}/>
			}
			<div className="button-wrapper">
				<Button label="Auswerten" className="secondBtn primary-color-lightblue-foreground" disabled={loading || selectedFile == null} onClick={this.onFileUpload}/>
			</div>
			</section>
			{fileUploadError &&
			<FileUploadAlert onOK={this.resetFileUpload}/>}
			</div>
		);
	}
}

export default FileSubmission;
