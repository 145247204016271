import http from './httpService';

const {
    REACT_APP_WEB_API_URL: web_api_url,
} = process.env;

export async function getTopChristmasScores(page) {
    return await http.get(`${web_api_url}/top_christmas_scores`);
}

export default {
    getTopChristmasScores
}