import React, { Component } from 'react';
import authService from '../services/authService';
import { Link } from 'react-router-dom';

class Practice extends Component {
    state = {
      subPaths:[{
            course:'Aufgabenpool',
            title:"Aufgabenpool",
            description:"Von TicTacToe bis zum Labyrinthgenerator - hier findest du Programmieraufgaben, um deine Python-Kenntnisse zu testen und zu trainieren",
            subHeader:"Programmiertraining",
            link:"/programming/practice/task-archive",
            image:"taskpool.svg"
        },
        {
            course:'ai_challenge',
            title:"KI-Challenge",
            description:"Stelle dein Können unter Beweis und realisiere dein erstes KI-Project. Hier lernst du auch deine eigene Programmierungebung einzuricthen.",
            subHeader:"Fordere dich selbst heraus",
            link:"/programming/practice/course=ai_challenge",
            image:"Icon_KIchallenge.svg"
        }],
        viewMode:true,       
    }

    componentDidMount() {

        let user = authService.getCurrentUser();

        if(!user){
            this.setState({
                viewMode:true
            })
        }

    }

    removeAlert = () => {
        this.setState({
            showAlert: false
        })
    }

    render() {
        const user = authService.getCurrentUser();
        let viewMode=true;
        if(user)viewMode=false;
        return (
            <React.Fragment>
                <section className="main-content">
                <div className="container-fluid home-wrapper">
                        <div className="subpath-tiles">
                        {this.state.subPaths.length > 0 && this.state.subPaths.map( (subPath) => (
                             <Link to={subPath.link} className="subpath-tile">
                                <div className="tile-wrapper">
                                    <img style={{width:'100%'}} src={`/images/icons/subpaths/programming/practice/${subPath.image}`}/>
                                    <div className="row2 primary-color-lightblue-background">
                                        <div className="tile-sub-header">{subPath.subHeader}</div>
                                        <div className="tile-header">{subPath.title}</div>
                                        <div className="tile-description">{subPath.description}</div>
                                    </div>
                                </div>
                             </Link>))
                        }
                          
                        </div>
                        </div>
                    
                </section>
            </React.Fragment>
        );
    }
}

export default Practice;
