import React from "react";
import Button from "../common/button";
import PartialCourse from "./PartialCourse.jsx";
import CodeWeekPaket from "./CodeWeekPaket.jsx";

function CodeWeekPaketTwo(props) {
  const paketLogo = "/images/code-week/tiles/B2.svg";
  const text =
    "Ihr habt schon die Grundlagen von Python drauf? Dann geht's hier weiter mit Boolscher Logik und Verzweigungen.";
  const challengeButtonLabel = "Abschluss Challenge: FIZZ-BUZZ";
  const challenge_button_link = "";

  const new_props = {
    ...props,
    chapter_numbers: [4, 5],
    urlSearchParams: new URLSearchParams("code_week=2"),
  };

  return (
    <>
      <CodeWeekPaket
        text={text}
        paketLogo={paketLogo}
        challengeButtonLabel={challengeButtonLabel}
      >
        <PartialCourse {...new_props} />
      </CodeWeekPaket>
    </>
  );
}

export default CodeWeekPaketTwo;
