import { useEffect, useState } from "react";
import React from "react";
import assignmentsService from "../../services/assignmentsService";

import userService from "../../services/userService";
import authService from "../../services/authService";
import ViewModeAlert from "../viewModeAlert";
import Loading from "../common/loading";
import ChapterTile from "../course/ChapterTile.jsx";

function PartialCourse(props) {
  const chapter_numbers = props.chapter_numbers;
  const course = "python";
  const root = "/programming/intro";
  const [viewModeMessage, setViewModeMessage] = useState(false);
  const [chapters, setChapters] = useState(null);
  const [courseMetaData, setCourseMetaData] = useState(null);

  useEffect(() => {
    const user = authService.getCurrentUser();
    assignmentsService.getCourseMetadta(course).then(({ data: response }) => {
      const chapters_metadata = response.filter((x, i) =>
        chapter_numbers.includes(i + 1)
      );
      setCourseMetaData(chapters_metadata);
    });
    if (!isViewMode()) {
      assignmentsService
        .getAssignments(course)
        .then(({ data: response }) => {
          // console.log(response)
          userService
            .getScoresForCourse(course)
            .then(({ data: responsePoints }) => {
              // console.log(response.assignments)
              let allchapters = response.assignments
                .map((p) => p.id.split("_")[0])
                .filter((item) => !isNaN(item));
              allchapters = [...new Set(allchapters)];
              allchapters.sort((a, b) => Number(a) - Number(b));
              let chapters = [];

              let allAssignments = response.assignments;

              allAssignments = allAssignments.map((assingment) => {
                let scoreObj = responsePoints.find(
                  (pointsItem) => pointsItem.chapter_id === assingment.id
                );
                // let scoreObj = null;
                assingment.score = scoreObj ? scoreObj.points : 0;
                console.log(assingment.score);
                return assingment;
              });

              allchapters.forEach((chapter) => {
                chapters[Number(chapter)] = response.assignments.filter(
                  (p) => p.id.split("_")[0] === chapter
                );
              });

              setChapters(
                chapters.filter((x, i) => chapter_numbers.includes(i))
              );
            });
          return;
        })
        .catch((err) => {});
    }
    return () => {};
  }, []);

  function isViewMode() {
    let user = authService.getCurrentUser();
    if (user) {
      return false;
    }
    return true;
  }

  function isLoading() {
    if (isViewMode()) {
      if (courseMetaData) {
        return false;
      }
      return true;
    } else {
      if (courseMetaData && chapters) {
        return false;
      }
      return true;
    }
  }

  function toggleViewModeMessage() {
    setViewModeMessage(!viewModeMessage);
  }

  function onRegister() {
    props.history.push({
      pathname: "/auth",
      state: { prevPath: props.location.pathname },
    });
    setViewModeMessage(false);
  }

  return (
    <>
      <div className="content tutorial-landing">
        {!isLoading() && (
          <div
            className={`grid-box multiple-choice ${
              course === "recycling" ? "narrow-tile-container" : ""
            }`}
          >
            {!isViewMode()
              ? chapters &&
                chapters.map((chapter, index) => {
                  // console.log("CHAPTERS HEEERE", chapters);
                  if (chapter)
                    return (
                      <ChapterTile
                        chapter={chapter}
                        chapterName={courseMetaData[index].chapter}
                        index={chapter_numbers[index]}
                        course={course}
                        root={root}
                        history={props.history}
                        urlSearchParams={props.urlSearchParams}
                      />
                    );
                })
              : courseMetaData &&
                courseMetaData.map((chapter, index) => {
                  if (chapter)
                    return (
                      <ChapterTile
                        chapter={chapter}
                        chapterName={chapter.chapter}
                        index={chapter_numbers[index]}
                        course={course}
                        root={root}
                        history={props.history}
                        viewMode={true}
                        toggleViewModeMessage={toggleViewModeMessage}
                      />
                    );
                })}
          </div>
        )}
        {viewModeMessage && (
          <ViewModeAlert
            onRegister={onRegister}
            onCancel={toggleViewModeMessage}
          />
        )}
        {isLoading() && <Loading />}
      </div>
    </>
  );
}

export default PartialCourse;
