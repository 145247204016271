import React, { Component } from 'react';
import AppContext from './appContext';

class AppProvider extends Component {
    state = {
        tutorials: [
            {
                course: 'dog',
                chapters: [
                    "Einführung",
                    "Grundlagen der Programmierung",
                    "Klassifikator",
                    "Gradientenverfahren",
                    "Neuronale Netze"
                ]

            },
            {
                course: 'recycling',
                chapters: [
                    "Daten, Daten, Daten",
                    "Theoretische Grundlagen",
                    "Implementierung: Basics",
                    "Implementierung: Optimierung",
                    "Implementierung: Projektskript",
                    "Zusatz: Umsetzung auf Raspberry Pi"
                ]
            },
            {
                course: 'python',
                chapters: [
                    'Einführung',
                    'Hello World!',
                    'Datentypen',
                    'Boolesche Logik',
                    'Verzweigungen',
                    'Schleifen',
                    'Funktionen',
                    'Listen',
                    'Dictionaries',
                    'Objektorientierung',
                    'Werde zum Überflieger'

                ]
            },
            {
                course: 'mlbasics',
                chapters: [
                    'Einführung',
                    'Theorie: KI-Algorithmen',
                    'Einstieg Bibliotheken',
                    'Praxis: KI-Algorithmen',
                    'Zusammenfassung und Ausblick',
                ]
            },
            {
                course: 'christmas_challenge',
                chapters: [
                    "Christmas Challenge"
                ]

            },
            {
                course: 'ai_challenge',
                chapters: [
                    "KI Challenge"
                ]

            }
        ],
        quiz: [
            {
                course: 'aiSociety',
                chapters: []
            }
        ],
        status: [
            {
                type: "TUTORIAL",
                header: "Erkrankte Hunde",
                course: "dog"

            },
            {
                type: "TUTORIAL",
                header: "Müllsortierung",
                course: "recycling"

            },
            {
                type: "ETHIK",
                header: "KI & Gesellschaft",
                course: "aiSociety"
            }
        ],
        currentChapter: "",
        setCurrentChapter: this.setCurrentChapter
    };

    setCurrentChapter = (newVal) => {
        this.setState({ currentChapter: newVal })
    }

    render() {
        return (
            <AppContext.Provider
                value={{
                    tutorials: this.state.tutorials,
                    quiz: this.state.quiz,
                    status: this.state.status

                }}
            >
                {this.props.children}
            </AppContext.Provider>
        );
    }
}

export default AppProvider;
