import React, { Component } from "react";
import tipsMarkdown from "./tips_content.md";
import Markdown from "../common/markdown";

class TipsTricksAndLinks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tips_text: "",
    };
  }
  componentDidMount() {
    fetch(tipsMarkdown)
      .then((res) => res.text())
      .then((text) => {
        // console.log(text);
        this.setState({ tips_text: text });
      });
  }

  render() {
    const { tips_text } = this.state;
    return (
      <React.Fragment>
        <section className="content">
          <div className="code-page-limit-tipps">
            <div className="tittle">
              <Markdown allowDangerousHtml={false} children={tips_text} />
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default TipsTricksAndLinks;
