import React, { Component } from 'react';

class FileUploadAlert extends Component {

  render() {
    return (
      <React.Fragment>
        <div className="overlay-panel-container">
           <div className="overlay-panel view-mode-message">
             <div className="panel-content">
              <div className="message-title" style={{padding:'0 1rem', textAlign:'center'}}>Die hochgeladene Datei is zu groß! Achte darauf, dass die Datei kleiner als <b>5MB</b> ist.</div>
              <div className="button-wrapper">
                <button style={{color:"#fff", backgroundColor:"#0083d3", width:'auto', padding:'0 1rem'}} onClick={this.props.onOK}>OK</button>
              </div>
            </div>
           </div>
        </div>
        
      </React.Fragment>
    );
  }
}


export default FileUploadAlert;
