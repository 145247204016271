import React, { useEffect, useState, useMemo } from "react";
import { useHistory, generatePath } from "react-router-dom";
import questionService from "../../services/questionService";
import assignmentsService from "../../services/assignmentsService";
import userService from "../../services/userService";
import Question from "../questions/question";

const PioneerWomenQuiz = props => {
  const questionId = props.match.params.id;
  const course = "pioneer-women";
  const id = "1_01";
  const beforeQuizUrl = "/milestones/pioneer-women/timeline";
  const afterQuizUrl = "/milestones/pioneer-women/museum-catalog";

  const [answers, setAnswers] = useState();
  const [questions, setQuestions] = useState();
  const [currentQuestion, setCurrentQuestion] = useState();
  const [isFreshReload, setIsFreshReload] = useState(true);
  const [totalPoints, setTotalPoints] = useState(0);

  const selectedAnswers = useMemo(() => {
    if (answers && answers.answers) {
      return answers.answers;
    } else {
      return [];
    }
  }, [answers]);

  const allowSubmit = useMemo(() => {
    if (answers && answers.num_attempts >= 3) {
      return false;
    }
    if (answers && answers.correct) {
      return false;
    }
    return true;
  }, [answers]);

  const correct = useMemo(() => {
    if (answers) {
      return answers.correct;
    } else {
      return false;
    }
  }, [answers]);

  useEffect(() => {
    if (!currentQuestion) {
      reloadBackendState();
    }
  });

  const reloadBackendState = () => {
    assignmentsService.getAssignments(course).then(({ data: response }) => {
      let questions = JSON.parse(
        response.assignments.find(p => p.id === id).questions
      ).allQuestions;
      let currentQuestion = questions.find(p => p.id === parseInt(questionId));
      setQuestions(questions);
      setCurrentQuestion(currentQuestion);
    });
    questionService
      .getQuestionByChapterId(course, id)
      .then(({ data: response }) => {
        let questionAnswers = response.find(
          p => p.quiz_id === parseInt(questionId)
        );
        setAnswers(questionAnswers);
      });
    updateTotalPoints();
  };

  const updateTotalPoints = () => {
    userService.getScoresForCourse(course).then(({ data: response }) => {
      const chapters = response.filter(
        item => item.chapter_id.split("_")[0] === id.split("_")[0]
      );
      const sum = chapters.reduce(
        (sumPoints, chapter) => sumPoints + chapter.points,
        0
      );
      setTotalPoints(sum);
    });
  };

  const onSelectAnswer = (id, index) => {
    const question = { ...currentQuestion };
    question.answers[index].checked = !currentQuestion.answers[index].checked;
    setCurrentQuestion(question);
  };

  const onPrev = () => {
    if (questionId && questionId > 1) {
      let id = parseInt(questionId) - 1;
      props.history.push(generatePath(props.match.path, { id }));
    } else {
      props.history.push(beforeQuizUrl);
    }
  };

  const onNext = () => {
    if (questionId && questionId < questions.length) {
      let id = parseInt(questionId) + 1;
      props.history.push(generatePath(props.match.path, { id }));
    } else {
      props.history.push(afterQuizUrl);
    }
  };

  const getMessage = () => {
    let message = "";
    let color = "";
    if (answers) {
      if (isFreshReload && (answers.correct || answers.num_attempts === 3)) {
        message = "Musterlösung - dies sind alle korrekten Antworten!";
        color = "";
      } else if (answers.num_attempts < 3) {
        if (answers.correct) {
          message = "Super. Du hast Punkte gesammelt.";
          color = "message-correct";
        } else {
          message = "Noch nicht ganz richtig. Versuche es nochmal.";
          color = "message-more";
        }
      } else if (answers.num_attempts === 3) {
        if (answers.correct) {
          message = "Super. Du hast Punkte gesammelt.";
          color = "message-correct";
        } else {
          if (answers.correct_answers_count === 0) {
            message = "Schade, diesmal hast du leider keine Punkte gesammelt.";
            color = "message-wrong";
          } else if (answers.correct_answers_count > 0) {
            message = "Du erhältst für die richtigen Antworten Punkte.";
            color = "message-partial";
          }
        }
      }
    }
    return { message: message, color: color };
  };

  const onSubmit = () => {
    let questionSubmit = {
      id: currentQuestion.id,
      chapterId: id,
      type: course,
      answers: currentQuestion.answers
    };
    questionService
      .submitQuestions(questionSubmit)
      .then(({ data: response }) => {
        reloadBackendState();
        setIsFreshReload(false);
      });
  };

  const getNextButtonLabel = () => {
    if (questionId && questionId < questions.length) {
      return "Nächste Frage";
    } else {
      return "Weiter";
    }
  };

  const getPrevButtonLabel = () => {
    if (questionId && questionId > 1) {
      return "Vorherige Frage";
    } else {
      return "Zurück";
    }
  };

  return (
    <>
      <section className="content question">
        {currentQuestion && (
          <div className="container-fluid">
            <div className="container-limit my-limit">
              <div className="question-wrapper">
                <Question
                  correct={correct}
                  key={currentQuestion.id}
                  question={currentQuestion}
                  onChange={onSelectAnswer}
                  type="tutorial"
                  selectedAnswers={selectedAnswers}
                  showAnswers={!allowSubmit}
                  course={course}
                />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    justifyContent: "space-between",
                    paddingTop: "5em"
                  }}
                >
                  <object
                    data={currentQuestion["img-portrait"]}
                    style={{ maxHeight: "20em", float: "left" }}
                  />
                  <object
                    data={currentQuestion["img-item"]}
                    style={{ maxHeight: "20em", float: "right" }}
                  />
                </div>
                {!(getMessage().message === "") && (
                  <div className={`question-info ${getMessage().color}`}>
                    <span>{getMessage().message}</span>
                  </div>
                )}
              </div>
              <div className="submit-parent-wrapper">
                {questions.length > 0 && (
                  <div className="button-wrapper">
                    <div className="points-panel-wrapper">
                      <span>Punkte</span>
                      <div className="points-panel">{totalPoints}</div>
                    </div>
                    {
                      <button onClick={onPrev} className="firstBtn">
                        {getPrevButtonLabel()}
                      </button>
                    }
                    {allowSubmit && (
                      <button
                        onClick={onSubmit}
                        className="secondBtn primary-color-lightblue-foreground"
                      >
                        Auswerten
                      </button>
                    )}
                    {true && (
                      <button onClick={onNext} className="thirdBtn">
                        {getNextButtonLabel()}
                      </button>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </section>
    </>
  );
};

export default PioneerWomenQuiz;
