import React from "react";
import authService from "../../services/authService";
import { Redirect } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import Button from "../common/button";
import Form from "../common/form";
import HoverTooltip from "../common/hover-tooltip";
import userService from "../../services/userService";
import classService, { generateCode } from "../../services/classService";
import AddStudents from "./addStudents";
import { schools } from "../../schools.json";
import Joi from "joi-browser";

class NewClassroom extends Form {
  constructor(props) {
    super(props);
  }

  state = {
    data: {
      username: "",
      email: "",
      school: "",
      is_ai_school: false,
    },
    errors: [],
    classcode_error: "",
    showPanel: false,
    panel: 1,
    success: "",
    toggleStudents: true,
    classroom: {
      room_name: "",
      code: "",
      school: "",
      terms: false,
      is_ai_school: false,
    },
    classCreated: false,
    tooltipOpen: false,
    loading: false,
    isInvalid: false,
    options: schools.map((school) => school),
    currentSchoolYear: false,
    isStudent: false,
  };

  schema = {
    room_name: Joi.string()
      .required()
      .min(3)
      .max(50)
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            case "any.empty":
              err.message = "Der Klassenname sollte nicht leer sein!";
              break;
            case "string.regex":
              err.message =
                "Der Klassenname sollte nur eine Kombination aus Buchstaben und Zahlen ohne Leerzeichen enthalten";
              break;
            case "string.min":
              err.message = "Klassenname muss 3 bis 50 Zeichen lang sein";
              break;
            case "string.max":
              err.message = "Klassenname muss 3 bis 50 Zeichen lang sein";
              break;
            default:
              break;
          }
        });
        return errors;
      }),
    code: Joi.string().required(),
    school: Joi.string()
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            case "any.empty":
              err.message = "Die Schule sollte nicht leer sein!";
              break;
          }
        });
        return errors;
      }),
    is_ai_school: Joi.boolean(),
    terms: Joi.boolean()
      .required()
      .invalid(false)
      .error((errors) => {
        errors.forEach((err) => {
          err.message = "Bitte akzeptiere die Nutzungsbedingungen";
        });
        return errors;
      }),
  };

  componentDidMount() {
    let isCurrent = false;
    // console.log("JSON size", size(schools))
    this.loadUserHelpers();

    // Get the current school year to update displayed info
    isCurrent = classService.getSchoolYear(new Date(Date.now()));
    this.setState({
      currentSchoolYear: isCurrent,
    });
  }

  /**
   * Pre-loader function
   * - Loads current user info
   * - Generates new classroom code
   * - Updates state with the received data
   * - Fetches all schools
   */
  loadUserHelpers() {
    if (this.props.user) {
      const { classroom } = this.state;
      // Generate new code
      let newcode = generateCode(8);
      classroom.code = newcode;

      // Get schools all schools
      classService.getAllSchools().then((schools) => {
        this.setState({ options: schools });
      });

      // Get user info
      let isStudent = authService.isUserStudent();
      userService.getUser().then(({ data: response }) => {
        const { username, school, is_ai_school, is_disabled, user_type } =
          response;
        classroom.school = school;
        classroom.is_ai_school = is_ai_school;

        isStudent = user_type === "student" ? true : isStudent;
        this.setState({
          data: {
            username: username,
            school: school,
            is_ai_school: is_ai_school,
            is_disabled: is_disabled,
          },
          classroom,
          isStudent,
        });
      });
    }
  }

  removeAlert = () => {
    this.setState({
      showAlert: false,
    });
  };

  toggleModal = (item) => {
    this.setState({
      showPanel: !this.state.showPanel,
      panel: 1,
    });
  };

  handleIsAISchool = (event) => {
    const { data, classroom } = this.state;
    data["is_ai_school"] = event.target.checked;
    classroom["is_ai_school"] = data["is_ai_school"];

    this.setState({
      data,
      classroom,
    });
  };

  handleTerms = (event) => {
    const { classroom, errors } = this.state;
    let terms = event.target.checked;
    classroom["terms"] = terms;

    delete errors["terms"];
    this.setState({
      classroom,
      isInvalid: false,
      errors,
    });
  };
  handleClassname = (event) => {
    const { classroom, errors } = this.state;
    classroom.room_name = event.target.value;
    delete errors["name"];
    this.setState({
      classroom,
      isInvalid: false,
      errors,
    });
  };

  createClass = () => {
    let { classroom } = this.state;
    const { errors, options } = this.state;

    if (classroom.school == "") {
      const { school } = this.state.data;
      classroom.school = school;
    }

    if (!options.includes(classroom.school)) {
      // console.log("Invalid school", classroom.school)
      classroom.school = "";
      this.setState({
        loading: false,
        isInvalid: false,
        errors: { school: "Nur Schulen aus der Liste auswählen" },
      });
      return;
    }

    this.setState({
      loading: true,
      errors: [],
    });

    classService
      .addClass(
        classroom.room_name,
        classroom.code,
        classroom.school,
        classroom.is_ai_school
      )
      .then(() => {
        const { data } = this.state;
        data["is_disabled"] = true;
        this.setState({
          toggleStudents: false,
          classCreated: true,
          classroom,
          data,
        });
      })
      .catch((err) => {
        let error = "Es ist ein unterwarter Fehler aufgetreten.";
        if (err.response && err.response.status === 400) {
          error = err.response.data.error;
          console.log("Generating new Classcode");
          this.setState({
            classcode_error: error,
          });
          console.log(this.state.errors);

          classroom.code = generateCode(8);
          return this.createClass();
        }

        this.setState({
          classcode_error: error,
        });
      });
  };

  copyCode = () => {
    navigator.clipboard
      .writeText(this.state.classroom.code)
      .then(() => {})
      .catch((err) => {
        let error = err.response.data.error;
        console.log(error);
        // this.setState({
        //     errors: this.state.errors.concat([error])
        // })
      });
  };

  handleInputChange = (event) => {
    const { classroom, data, errors } = this.state;
    let school = event.target.value;
    // DO NOT pretty format this text
    // classroom['school'] = school.replace(' ', '-');
    classroom["school"] = school;
    data["school"] = school;
    delete errors["school"];
    this.setState({
      classroom,
      data,
      isInvalid: false,
      errors,
    });
  };

  validateClass = () => {
    const { error } = Joi.validate(this.state.classroom, this.schema, {
      abortEarly: false,
    });

    if (!error) {
      this.createClass();
      return null;
    }

    const { errors } = this.state;
    error.details.map((p) => {
      console.log(p);
      errors[p.path[0]] = p.message;
    });
    // console.log(error);
    this.setState({
      isInvalid: true,
      errors,
    });
  };

  render() {
    // const{showPanel,panel} = this.state
    const user = authService.getCurrentUser();
    let viewMode = true;
    if (!user && viewMode) this.props.history.push("/auth");
    if (user) viewMode = false;

    const {
      data,
      errors,
      classcode_error,
      options,
      classCreated,
      toggleStudents,
      classroom,
      loading,
      isStudent,
      isInvalid,
      currentSchoolYear,
    } = this.state;

    let optionsHTML = [];
    options.forEach((school) => {
      if (school === "Sonstige") {
        return;
      }
      optionsHTML.push(<option>{school}</option>);
    });

    if (isStudent) return <Redirect to="/not-found" />;
    return (
      <React.Fragment>
        <section className="content">
          <div className="container-fluid">
            <div className="container-limit">
              <div>
                <h1 className="header1 font-main-header text-center">
                  Neuer virtueller Klassenraum
                </h1>
              </div>
              <div className="card-body col-md-12">
                {errors.terms && (
                  <div className="error text-center">
                    <span className="text-danger">{errors.terms}</span>
                  </div>
                )}
                {errors.name && (
                  <div className="error text-center">
                    <span className="text-danger">{errors.name}</span>
                  </div>
                )}
                {errors.school && (
                  <div className="error text-center">
                    <span className="text-danger">{errors.school}</span>
                  </div>
                )}

                {/* TODO refactor to use errors from JOI */}
                <div className="row">
                  <div className="form-row col-md-12">
                    <div className="form-group col-md-6">
                      <label htmlFor="Benutzername">Benutzername</label>
                      <input
                        className="form-control"
                        name="username"
                        value={data.username}
                        type="text"
                        placeholder={""}
                        disabled
                      />
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="School">Schule</label>
                      <div>
                        <input
                          list="schools"
                          name="School"
                          value={classroom.school}
                          onChange={this.handleInputChange}
                          type="text"
                          placeholder="Schule"
                          className={
                            errors.school
                              ? "invalid form-control"
                              : "form-control"
                          }
                          disabled={classCreated}
                        />
                        <datalist id="schools">{optionsHTML}</datalist>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="form-row col-md-12">
                    <p>
                      Bitte wähle deine Schule aus. Wenn deine Schule nicht in
                      der Liste ist, wähle "Sonstige".
                    </p>
                  </div>
                </div>
                <br />
                <div className="row">
                  <div className="form-row col-md-12">
                    <p>
                      Wollt ihr auch beim Rennen um die "KI-Schule des Jahres"
                      mitmachen? Dann beachtet, dass eure Leistungen für das{" "}
                      <b>aktuelle Schuljahr</b> zählen (August{" "}
                      {currentSchoolYear} - Juli {currentSchoolYear + 1}).
                    </p>
                  </div>
                </div>
                <div className="form-row col-md-12">
                  <div className="form-group">
                    <div className="checkbox-wrapper">
                      <div className="checkbox-terms">
                        <label className="switch">
                          {!data.is_disabled ? (
                            <input
                              type="checkbox"
                              id="is_ai_school"
                              name="is_ai_school"
                              checked={classroom.is_ai_school}
                              onChange={this.handleIsAISchool}
                            />
                          ) : (
                            <input
                              type="checkbox"
                              id="is_ai_school"
                              name="is_ai_school"
                              checked={classroom.is_ai_school}
                              disabled
                            />
                          )}
                          <span className="slider round"></span>
                        </label>
                        <span
                          className={
                            data.is_disabled
                              ? "grayout"
                              : "primary-color-blue-foreground-bold"
                          }
                        >
                          Wir möchten beim Rennen um die KI-Schule des Jahres
                          teilnehmen.
                        </span>
                      </div>
                    </div>
                    <div className="row">
                      <p>
                        Wir können nur die Schulen/Bildungseinrichtungen
                        berücksichtigen, die im Feld SCHULE eine Einrichtung
                        ausgewählt haben. Wenn eure Schule nicht in der Liste
                        steht, könnt ihr sie in eurem Nutzerprofil manuell
                        eingeben.
                      </p>
                    </div>
                  </div>
                </div>

                <hr />

                <div className="row">
                  <div className="form-row col-md-12">
                    <div className="form-group col-md-5">
                      <label htmlFor="classname">Klassenname</label>
                      <input
                        className="form-control"
                        name="classname"
                        type="text"
                        placeholder={"Klassenname"}
                        onChange={this.handleClassname}
                        disabled={classCreated}
                      />
                    </div>
                    <div className="form-group col-md-5">
                      <label htmlFor="Klassencode">Dein Klassencode</label>
                      <input
                        className="form-control"
                        name="classcode"
                        value={classroom.code}
                        type="text"
                        disabled
                      />
                    </div>
                    <div
                      className="form-group col-md-2 text-center mt-auto copy-wrapper"
                      hidden={!classCreated}
                    >
                      <FontAwesomeIcon
                        icon={faCopy}
                        onClick={this.copyCode}
                        id="copy-tool"
                      ></FontAwesomeIcon>
                      <HoverTooltip name="copy-tool" message="Copy code" />
                    </div>
                  </div>
                </div>
                <div className="form-row col-md-12">
                  <div className="checkbox-wrapper">
                    <div className="checkbox-terms">
                      <label className="switch">
                        {!data.is_disabled ? (
                          <input
                            type="checkbox"
                            name="terms"
                            value={data.terms}
                            onChange={this.handleTerms}
                          />
                        ) : (
                          <input
                            type="checkbox"
                            name="terms"
                            value={data.terms}
                            disabled
                          />
                        )}
                        <span className="slider round"></span>
                      </label>
                      <span
                        className={
                          data.is_disabled
                            ? "grayout"
                            : "primary-color-blue-foreground-bold"
                        }
                      >
                        Hiermit bestätige ich, dass ich die Einwilligung der
                        SchülerInnen erhalten habe, sie in diesem virtuellen
                        Klassenraum anmelden zu dürfen.
                      </span>
                    </div>
                  </div>
                  <div
                    className="row text-center justify-content-center"
                    hidden={classCreated}
                  >
                    <Button
                      onClick={this.validateClass}
                      className="btn button-room-bold my-2"
                      label="Bestätigen"
                      disabled={loading ? true : isInvalid}
                    ></Button>
                  </div>
                </div>
                {classcode_error && (
                  <div className="row text-center justify-content-center">
                    <div className="error text-center">
                      <span className="text-danger">{classcode_error}</span>
                    </div>
                  </div>
                )}
                <br />
                <div className="form-row col-md-12" hidden={toggleStudents}>
                  <div className="row primary-color-blue-foreground-bold">
                    <p>
                      Zur Anmeldung benötigen die SchülerInnen den Klassencode
                      und den Benutzername.
                    </p>
                  </div>
                </div>

                <div className="row" hidden={toggleStudents}>
                  <AddStudents
                    response={classroom}
                    historyProp={this.props.history}
                  />
                </div>
                <div
                  className="row text-center col-md-12 justify-content-center"
                  hidden={toggleStudents}
                >
                  <Button
                    href="/classroom"
                    className="btn button-room-bold my-2"
                    label="Klassenraum bestätigen"
                  ></Button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default NewClassroom;
