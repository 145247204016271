import React, { Component } from 'react';
import { organisators } from './sponsors.json'

function Organizators() {
    return (
        <div className="organisators">
            <h3>ORGANISIERT VON</h3>
            {
                organisators.map((sponsor, index) =>
                    <div key={index} className="womix__image-wrapper">
                        <a href={sponsor.url} target="_blank">
                            {sponsor.image && <img src={`${sponsor.image}`} alt={sponsor.name} style={{width: "250px"}}/>}
                            {sponsor.text && <h4>{sponsor.text}</h4>}
                        </a>
                    </div>
                )
            }
        </div>
    )
}

export default Organizators;