import React, { Component } from 'react';

function FindUs() {
    return (
        <div className="contact">
            <h3>Hier finden Sie uns</h3>
            <ul>
                <li>Bundeswettbewerb KI</li>
                <li>AI Research Building (AG Bethge)</li>
                <li>Maria-von-Linden-Straße 6</li>
                <li>72076 Tübingen</li>
                <li className="half"><span>Telefon:</span><br />07071-29 70880</li>
                <li className="half"><span>Email:</span><br /><a href="mailto:info@bw-ki.de">info@bw-ki.de</a></li>
            </ul>
        </div>
    )
}
export default FindUs;