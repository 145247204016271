import React from "react";
import Button from "../common/button";
import PartialCourse from "./PartialCourse.jsx";
import CodeWeekPaket from "./CodeWeekPaket.jsx";

function CodeWeekPaketOne(props) {
  const paketLogo = "/images/code-week/tiles/B1.svg";
  const text =
    "Hier startet ihr ganz von vorne und lernt die ersten Grundlagen der Programmiersprache Python. Lern verschiedene Datentypen und den 'input'-Befehl kennen.";
  const challengeButtonLabel = "Abschluss Challenge: INPUT - OUTPUT";
  const challenge_button_link = "";

  const new_props = {
    ...props,
    chapter_numbers: [1, 2, 3],
    urlSearchParams: new URLSearchParams("code_week=1"),
  };

  return (
    <>
      <CodeWeekPaket
        text={text}
        paketLogo={paketLogo}
        challengeButtonLabel={challengeButtonLabel}
      >
        <PartialCourse {...new_props} />
      </CodeWeekPaket>
    </>
  );
}

export default CodeWeekPaketOne;
