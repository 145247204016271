import React from "react";
import Main from "./sponsors/main";
import Organizators from "./sponsors/organisators";
import Cooperation from "./sponsors/cooperation";

function Sponsors() {
  return (
    <div className="sponsor-section">
      <Organizators />
      <Cooperation />
      <Main />
    </div>
  );
}

export default Sponsors;
