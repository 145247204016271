import React, { useEffect, useState } from "react";
import MenuPage from "./MenuPage.jsx";
import authService from "../../services/authService.js";

const PioneerWomenIntroPage = (props) => {

  const [viewMode, setViewMode] = useState(true);

  useEffect(() => {
    let user = authService.getCurrentUser();

    if (!user) {
      console.log("no user");
      setViewMode(true);
    } else { setViewMode(false); }
  })



  const heading = "Willkommen zur Ausstellung IT-Pionierfrauen";
  const text =
    "In unserer Ausstellung könnt ihr die Arbeit bekannter Pionierinnen aus dem MINT-Bereich erkunden, welche einen bedeutsamen Beitrag im Bereich Informatik und KI geleistet haben. Frauen in der Informatik haben bisher nicht viel Aufmerksamkeit bekommen. Doch hättet ihr gewusst, dass von Anfang an Frauen hinter der Weiterentwicklung von Technologien stecken?";
  const buttons = [
    { label: "Zur Ausstellung", link: "/milestones/pioneer-women/timeline" },
    { label: "Zum Quiz", link: "/milestones/pioneer-women/quiz" },
    { label: "Zum Museumskatalog", link: "/milestones/pioneer-women/museum-catalog" }
  ];
  const image = "/images/pioneer-women/robot_welcome.svg";

  return (
    <MenuPage props={props} heading={heading} text={text} buttons={buttons} image={image} viewMode={viewMode} />
  );
};

export default PioneerWomenIntroPage;
