import React, { Component } from "react";
import authService from "../services/authService";
import { Link } from "react-router-dom";
import userService from "../services/userService";

class IntroProgramming extends Component {
  state = {
    tutorials: [
      {
        course: "python",
        title: "Grundlagen der Python Programmierung",
        description: "",
        subHeader: "Python Grundkurs",
        link: "/programming/intro/course=python",
        image: "python.svg",
        totalTasks: 44,
        attemptedTasks: 0,
      },
      {
        course: "mlbasics",
        title: "Grundlagen des Maschinellen Lernens ",
        description: "",
        subHeader: "ML Grundkurs",
        link: "/programming/intro/course=mlbasics",
        image: "mlbasics.svg",
        totalTasks: 42,
        attemptedTasks: 0,
      },
    ],
    viewMode: true,
    taskPythonBasics: 0,
    taskMLBasics: 0,
  };

  componentDidMount() {
    let user = authService.getCurrentUser();

    if (!user) {
      this.setState({
        viewMode: true,
      });
    } else {
      let tasksPython = 0;
      let tasksML = 0;
      this.state.tutorials.map((tutorial) => {
        userService
          .getAttemptedTasksForCourse(tutorial.course)
          .then(({ data: response }) => {
            if (!response) {
              console.log("Got no response!");
              return;
            }

            if (tutorial.course === "python") {
              response.map((subchapter) => {
                tasksPython += subchapter.num_attempted;
              });
              this.setState({ taskPythonBasics: tasksPython });
            } else if (tutorial.course === "mlbasics") {
              response.map((subchapter) => {
                tasksML += subchapter.num_attempted;
              });
              this.setState({ taskMLBasics: tasksML });
            }
          });
      });
    }
  }

  removeAlert = () => {
    this.setState({
      showAlert: false,
    });
  };

  render() {
    const { taskPythonBasics, taskMLBasics } = this.state;
    const user = authService.getCurrentUser();
    let viewMode = true;
    if (user) viewMode = false;
    return (
      <React.Fragment>
        <section className="main-content">
          <div className="tiles-container tutorials-tiles">
            {this.state.tutorials.length > 0 &&
              this.state.tutorials.map((tutorial) => (
                <Link
                  to={tutorial.link}
                  className="subpath-tile tutorial"
                  key={tutorial.course}
                >
                  <div className="tile-wrapper">
                    <img
                      style={{ width: "100%" }}
                      src={`/images/icons/subpaths/programming/intro/${tutorial.image}`}
                    />
                    <div className="row2 primary-color-lightblue-background">
                      <div className="tile-sub-header">
                        {tutorial.subHeader}
                      </div>
                      <div className="tile-header fix-height-3">
                        {tutorial.title}
                      </div>
                      {!viewMode && (
                        <div className="tile-status">{`${
                          tutorial.course === "python"
                            ? taskPythonBasics
                            : taskMLBasics
                        }/${tutorial.totalTasks} Aufgaben bearbeitet`}</div>
                      )}
                    </div>
                  </div>
                </Link>
              ))}
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default IntroProgramming;
