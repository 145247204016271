import http from "./httpService";
// import { schools } from '../schools.json'

const { REACT_APP_WEB_API_URL: web_api_url } = process.env;

export async function addClass(classname, class_code, school, is_ai_school) {
  return await http.post(`${web_api_url}/class`, {
    classname,
    class_code,
    school,
    is_ai_school,
  });
}

export async function deleteClass(classcode) {
  return await http.post(`${web_api_url}/delete-class/${classcode}`);
}

export async function registerStudent(username, fullname, class_code) {
  return await http.post(`${web_api_url}/class/student`, {
    username,
    fullname,
    class_code,
  });
}

export async function removeStudent(class_code, username) {
  return await http.post(`${web_api_url}/class/remove/${username}`, {
    class_code,
  });
}

export async function getClasses(username) {
  return await http.get(`${web_api_url}/classes`);
}

/** Fetches unique school entries from server.*/
export async function getSchoolsInDB() {
  return await http.get(`${web_api_url}/schools`);
}

/** Compiles unique school entries from server and the local JSON file.*/
export async function getAllSchools() {
  const schoolsInJSON = require("../schools.json").schools;
  // console.log("JSON: " + schoolsInJSON.length);

  let allSchools = [];
  await getSchoolsInDB()
    .then(({ data }) => {
      const schoolsFromDB = data["schools"];
      // console.log("DB: " + schoolsFromDB);
      schoolsFromDB.forEach((school) => {
        if (!schoolsInJSON.includes(school)) {
          allSchools.push(school);
        }
      });
      allSchools = allSchools.concat(schoolsInJSON);
      allSchools = allSchools.sort();
      console.log(allSchools.length);
    })
    .catch((error) => {
      console.log(error);
      console.log("Server error: Defaulting to schools from JSON");
      allSchools = schoolsInJSON;
    });
  return allSchools;
}

export async function getClassDetails(classcode) {
  return await http.get(`${web_api_url}/class/${classcode}`);
}

export async function signUpForAISchool(classcode) {
  return await http.post(`${web_api_url}/class/ai-school-signup/${classcode}`);
}

export const generateCode = (length, style = "an") => {
  var result = "";
  if (style === "an")
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  else var characters = "0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
};

/** Returns the current school year for the given date.
 *  The school year turn is hard coded to the 31st July each year.*/
export const getSchoolYear = (date) => {
  if (!date) {
    return null;
  }
  const turn = new Date(String(date.getFullYear()) + "-07-31");
  if (date < turn) {
    return turn.getFullYear() - 1;
  } else {
    return turn.getFullYear();
  }
};

export default {
  addClass,
  registerStudent,
  removeStudent,
  getClasses,
  getClassDetails,
  signUpForAISchool,
  deleteClass,
  getSchoolsInDB,
  getAllSchools,
  generateCode,
  getSchoolYear,
};
