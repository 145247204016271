import React, { Component } from "react";
import authService from "../../services/authService";
import certificateService from "../../services/certificateService";
import userService from "../../services/userService";
import Button from "../common/button";
import Loading from "../common/loading";

class Certificate extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      url: "",
      promptUserName: false,
      error: false,
      isLoading: true,
      success: false,
      user: undefined,
      userFullName: "",
    };
  }
  componentDidMount() {
    console.log("mount");
    this.initCertificateGeneration();
  }

  initCertificateGeneration = () => {
    let curUser = authService.getCurrentUser();

    if (!curUser) {
      console.log("handle error");
      return;
    }

    userService.getUser().then(({ data }) => {
      let tempUser = data;
      console.log(data);

      if (tempUser.fullname) {
        this.getCertificate();
      } else {
        this.setState({
          promptUserName: true,
          isLoading: false,
          user: tempUser,
        });
      }
    });
  };

  getCertificate = () => {
    certificateService
      .getCertificate()
      .then(({ data }) => {
        this.setState({
          success: data.success,
          url: data.url,
        });
      })
      .catch((err) => {
        if (this._isMounted) {
          this.setState({
            error: "Something unexpected happened",
          });
        }
      })
      .finally((e) => {
        this.setState({
          isLoading: false,
        });
      });
  };

  updateUserName = () => {
    const { user, userFullName } = this.state;
    this.setState({
      isLoading: true,
    });
    userService
      .editUser(
        userFullName,
        user.postNumber,
        user.school,
        user.is_ai_school,
        user.events_check
      )
      .then(({ data }) => {
        if (data.fullname) {
          this.setState({
            promptUserName: false,
          });
          this.getCertificate();
        }
      })
      .catch((e) => {
        console.log("error in update", e);
      });
  };

  render() {
    const { success, url, error, promptUserName, isLoading } = this.state;

    if (isLoading) {
      return <Loading />;
    } else
      return (
        <React.Fragment>
          <section className="main-content">
            <h1
              className="homepage header1 font-main-header text-center"
              style={{ marginTop: "2rem" }}
            >
              Zertifikat
            </h1>
            {success ? (
              <h3 className="homepage header2 font-sub-header text-center mb-0">
                <a
                  className="red"
                  href={url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Hier
                </a>{" "}
                kannst du ein Zertifikat für deine erbrachte Leistung
                generieren.
              </h3>
            ) : (
              <>
                {promptUserName ? (
                  <div className="container-fluid home-wrapper">
                    <div className="container-limit my-limit">
                      <div>
                        Dein Profil beinhaltet nicht deinen vollständigen Namen
                        (Vornamen und Nachnamen). Bitte trage hier deinen
                        vollständigen Namen ein und drücke ‘update’ um
                        fortzufahren. Dein Nutzerprofil wird angepasst. Du
                        kannst deinen Namen nur einmal verändern - bitte achte
                        auf die korrekte Schreibweise!
                      </div>
                      <div className="button-div">
                        <input
                          className="form-control"
                          placeholder="Vollständiger Name"
                          value={this.state.userFullName}
                          type="text"
                          onChange={(e) => {
                            if (e.target.value.length <= 50)
                              this.setState({
                                userFullName: e.target.value,
                              });
                          }}
                        />
                        <Button
                          className="btn btn-primary ml-3 width-auto"
                          disabled={this.state.userFullName ? false : true}
                          onClick={this.updateUserName}
                          href="#"
                          label="Update"
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
                    {error === false && (
                      <h3 className="homepage header2 font-sub-header text-center mt-0">
                        Du musst erst den Kurs abschließen, bevor du Dein
                        Zertifikat herunterladen kannst.
                      </h3>
                    )}
                    {error === "Something unexpected happened" && (
                      <h3 className="homepage header2 font-sub-header text-center mt-0">
                        Es ist ein Fehler aufgetreten. Bitte schreib uns eine
                        Nachricht!
                      </h3>
                    )}
                  </>
                )}
              </>
            )}
          </section>
        </React.Fragment>
      );
  }
}

export default Certificate;
