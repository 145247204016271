import * as React from "react";

const SvgMembers = (props) => {

  const {handleElementClick, category} = props

  const onElementClick = (event) => {
    let id = event.currentTarget.id
    handleElementClick(id, category)
  }

  return (
  <svg
    viewBox="0 0 1502 780"
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    style={{
      fillRule: "evenodd",
      clipRule: "evenodd",
      strokeLinejoin: "round",
      strokeMiterlimit: 2,
    }}
    // width="1em"
    // height="1em"
    {...props}
  >
    <path
      d="M673.525 174.822a49.005 49.005 0 0 0-4.202 9.314c-4.135 12.419-3.677 25.963-1.225 38.821 1.815 9.521 4.697 18.87 5.494 28.529 1.362 16.515-3.428 32.862-8.456 48.653-6.418 20.152-11.198 39.116-3.885 59.855 15.972 45.292 75.872 61.608 116.902 42.344 43.631-20.485 50.507-77.688 49.085-120.62-1.233-37.237-6.071-87.91-35.962-113.989-29.423-25.671-95.845-30.325-117.751 7.093Z"
      style={{
        fill: "#35475f",
        fillRule: "nonzero",
      }}
      transform="matrix(-.0295 4.57664 -5.71419 -.03683 2366.37 -2996.978)"
    />
    <path
      d="m2805.79 1534.02-.37-.01c-3.07-.05-5.43-2.56-5.14-5.44l20.44-204.2 17.84 1.59-29.36 205.3c-.23 1.6-1.69 2.79-3.41 2.76ZM3002.68 1534.02l.37-.01c3.06-.05 5.43-2.56 5.14-5.44l-20.44-204.2-17.84 1.59 29.36 205.3c.22 1.6 1.69 2.79 3.41 2.76ZM2661.55 1534.02l-.38-.01c-3.06-.05-5.43-2.56-5.14-5.44l20.44-204.2 17.85 1.59-29.36 205.3c-.23 1.6-1.7 2.79-3.41 2.76Z"
      style={{
        fill: "#b5453c",
        fillRule: "nonzero",
      }}
      transform="matrix(.81714 0 0 .81714 -1116.303 -618.361)"
    />
    <g id="interaction2"
       onClick={onElementClick}
       style={{cursor:'pointer'}}
       pointerEvents="visiblePainted">
      <path
        d="m2996.59 1294.66 36.29-179.23c4.66-24.26-15.45-46.45-41.56-45.86l-69.99 1.6c-24.15.55-45.15 16.72-51.86 39.92l-53.1 183.57h180.22Z"
        style={{
          fill: "#93cae8",
          fillRule: "nonzero",
        }}
        transform="matrix(-.81714 0 0 .81714 2914.613 -629.644)"
      />
      <path
        d="m2974.63 1294.66 36.29-179.23c.92-4.81.87-9.53 0-14.01-3.54-18.15-20.62-32.32-41.56-31.85l-69.99 1.6c-24.15.55-45.14 16.72-51.85 39.92l-53.1 183.57h180.21Z"
        style={{
          fill: "#93cae8",
          fillRule: "nonzero",
        }}
        transform="matrix(-.81714 0 0 .81714 2914.613 -629.644)"
      />
      <path
        d="M2882.1 1015.73s-6.63 26.07-12.34 49.9c-2.3 9.6-4.45 18.83-5.97 25.86-1.55 7.21-2.43 12.1-2.09 12.64 4.97 8.07-4.11 57.25-11.29 75.11-.63 1.58-1.5 3.44-2.55 5.49-10.04 19.69-36.78 58.32-36.78 58.32s-7.54.79-12.47-1.07c-4.11-1.56-6.93-6.59-6.93-6.59 2.39-3.85 12.07-49.3 24.51-74.78 5.33-10.91 13.17-112.06 23.48-125.67 8.6-11.37 42.43-19.21 42.43-19.21Z"
        style={{
          fill: "#88b5d4",
          fillRule: "nonzero",
        }}
        transform="matrix(-.81714 0 0 .81714 2914.613 -629.644)"
      />
      <path
        d="M2861.7 1104.13c4.97 8.07-4.11 57.25-11.29 75.11-.63 1.58-1.5 3.44-2.55 5.5-9.6-42.45 6.3-102.58 6.3-102.58 3.97 1.57 7.14 4.84 9.63 9.33-1.55 7.21-2.43 12.1-2.09 12.64Z"
        style={{
          fill: "#649fc7",
          fillRule: "nonzero",
        }}
        transform="matrix(-.81714 0 0 .81714 2914.613 -629.644)"
      />
      <path
        d="M2965.96 1251.37s-11.18 22.42 10.3 39.55c0 0 9.31 6.37 10.74-1.9 1.43-8.28-.22-5.4 2.03-17 2.24-11.61-.63-23.62-7.31-28.84l-15.76 8.19Z"
        style={{
          fill: "#ffb27d",
          fillRule: "nonzero",
        }}
        transform="matrix(-.81714 0 0 .81714 2914.613 -629.644)"
      />
      <path
        d="M2871.78 977.831s12.89 19.601 10.32 37.889l19.08 45.98 34.43-52.63s-18.18-19.799-24.97-46.786l-38.86 15.547Z"
        style={{
          fill: "#cf9284",
          fillRule: "nonzero",
        }}
        transform="matrix(-.81714 0 0 .81714 2914.613 -629.644)"
      />
      <path
        d="M2866.12 994.555s46.96 2.033 47.82-26.158c.85-28.191 13.54-46.945-21.58-49.422-28.89-2.037-38.49 10.007-41.06 19.348-2.58 9.34-2.58 54.721 14.82 56.232Z"
        style={{
          fill: "#cf9284",
          fillRule: "nonzero",
        }}
        transform="matrix(-.81714 0 0 .81714 2914.613 -629.644)"
      />
      <path
        d="M2981.09 1244.79c-10.09.94-23.98 2.1-40.84 3.16-3.36.21-6.84.42-10.44.62-50.88 2.85-71.11-5.39-71.11-5.39s-1.35-1.09-.92-7.07c2.21-30.77 8.74-39.86 7.95-45.71-1.77-13.14-33.65-120.4-33.65-120.4s-.62-33.88 37.06-49.78c3.89-1.64 8.17-3.09 12.9-4.29 1.66 4.39 6.18 11.71 17.75 10.36 17.11-1.99 29.29-12.08 34.51-17.25.94 0 1.32.03 1.32.03 1.27 3.6 2.27 7.36 3.15 11.18 3.09 13.32 4.9 27.33 13.07 37.8 8.07 10.35 14.97 20.54 20.86 30.52 4.91 8.3 9.11 16.45 12.71 24.39 33.35 73.72 7.55 130.71-4.32 131.83Z"
        style={{
          fill: "#88b5d4",
          fillRule: "nonzero",
        }}
        transform="matrix(-.81714 0 0 .81714 2914.613 -629.644)"
      />
      <path
        d="M2981.09 1244.79c-10.09.94-23.98 2.1-40.84 3.16-4.91-82.83 28.84-162.38 28.84-162.38 1.27.89 2.47 1.89 3.61 3 5.88 5.71 9.98 14.18 12.71 24.39 10.05 37.55 1.63 98.57-4.32 131.83Z"
        style={{
          fill: "#649fc7",
          fillRule: "nonzero",
        }}
        transform="matrix(-.81714 0 0 .81714 2914.613 -629.644)"
      />
      <path
        d="M2850.98 967.666s-9.14 36.264 6.38 41.374c15.51 5.11 56.7 1.02 56.68-43.448 0 0-21.61 12.89-31.94 12.565-10.32-.326-16.99-6.284-26.53-1.63 0 0-3.49-.232-4.59-8.861Z"
        style={{
          fill: "#303040",
          fillRule: "nonzero",
        }}
        transform="matrix(-.81714 0 0 .81714 2914.613 -629.644)"
      />
      <path
        d="M2935.61 1009.07s32.83.74 50.17 23.3c13.29 17.29 27.34 110.07 27.34 124.04 0 13.98-14.96 91.07-23.34 99.45 0 0-5.42 1.4-12.66 0-7.68-1.48-12.2-5.93-12.2-5.93s4.17-85.83 4.17-93.88c0-11.66-36.44-83.01-36.44-83.01s-18.9-33.96 2.96-63.97Z"
        style={{
          fill: "#88b5d4",
          fillRule: "nonzero",
        }}
        transform="matrix(-.81714 0 0 .81714 2914.613 -629.644)"
      />
      <path
        d="M1094.59 755.717s-13.46-1.015-29.36-7.897c-12.9-5.586-36.22 4.563-36.15 4.605l-2.58 1.107c-20.1 9.749-21.91 41.536-15.43 48.88 7.81 8.837 17.39 25.094 17.39 25.094s3.18-1.058 1.75-8.842c-1.43-7.785 5.3-7.795 13.79-11.348 8.49-3.552 1.39-13.451 8.82-19.479 7.42-6.028 34.68-2.531 34.32-7.131 10.11-3.701 7.46-23.953 7.45-24.989Z"
        style={{
          fill: "#303040",
          fillRule: "nonzero",
        }}
        transform="matrix(.7867 0 0 .70696 -275.666 -423.581)"
      />
    </g>
    <path
      d="M2986.47 1327.35h-276.93c-5.56 0-10.12-4.55-10.12-10.11v-12.46c0-5.56 4.56-10.12 10.12-10.12h287.05v22.58c0 5.56-4.56 10.11-10.12 10.11Z"
      style={{
        fill: "#b5453c",
        fillRule: "nonzero",
      }}
      transform="matrix(.81714 0 0 .81714 -1116.303 -618.361)"
    />
    <path
      d="M2856.51 1327.35h-176.53c-4.49 0-8.15-3.66-8.15-8.14v-16.4c0-4.48 3.66-8.15 8.15-8.15h176.53c4.49 0 8.15 3.67 8.15 8.15v16.4c0 4.48-3.66 8.14-8.15 8.14Z"
      style={{
        fill: "#b5453c",
        fillRule: "nonzero",
      }}
      transform="matrix(.81714 0 0 .81714 -1116.303 -618.361)"
    />
    <path
      d="m2461.94 1294.66 36.3-179.23c4.66-24.26-15.45-46.45-41.56-45.86l-70 1.6c-24.15.55-45.14 16.72-51.85 39.92l-53.1 183.57h180.21Z"
      style={{
        fill: "#93cae8",
        fillRule: "nonzero",
      }}
      transform="matrix(.81714 0 0 .81714 -1142.303 -618.361)"
    />
    <path
      d="m2461.94 1294.66 36.3-179.23c4.66-24.26-15.45-46.45-41.56-45.86l-70 1.6c-24.15.55-45.14 16.72-51.85 39.92l-53.1 183.57h180.21Z"
      style={{
        fill: "#93cae8",
        fillRule: "nonzero",
      }}
      transform="matrix(.81714 0 0 .81714 -677.892 -610.941)"
    />
    <g id="interaction3"
       onClick={onElementClick}
       style={{cursor:'pointer'}}
       pointerEvents="visiblePainted">
      <path
        d="m2439.99 1294.66 36.29-179.23c4.66-24.26-15.45-46.45-41.56-45.86l-70 1.6c-24.14.55-45.14 16.72-51.85 39.92l-53.1 183.57h180.22Z"
        style={{
          fill: "#93cae8",
          fillRule: "nonzero",
        }}
        transform="matrix(.81714 0 0 .81714 -1142.303 -618.361)"
      />
      <path
        d="M2293.56 918.286s8.23-25.425 44.42-24.267c0 0 7.24-13.607 24.61-11.29 17.37 2.315 26.92 65.138 10.42 80.481-16.5 15.344-26.74 15.803-26.74 15.803l-52.71-58.36v-2.367Z"
        style={{
          fill: "#8c715d",
          fillRule: "nonzero",
        }}
        transform="matrix(.81714 0 0 .81714 -1142.303 -618.361)"
      />
      <path
        d="M2308.88 1029.08c-.15.08 16.09 7.47 27.81 3.6 11.03-3.64 20.75-20.24 20.59-20.27-4.79-.69-9.65-5.41-11.01-33.397l-2.12.534-35.64 9.009s1.41 11.292 1.99 21.964c.49 8.97.39 17.5-1.62 18.56Z"
        style={{
          fill: "#ffb27d",
          fillRule: "nonzero",
        }}
        transform="matrix(.81714 0 0 .81714 -1142.303 -618.361)"
      />
      <path
        d="M2308.51 988.556s0 2.323 1.33 12.824c20.16-5.08 29.04-11.765 35.31-19.415l-36.64 6.591Z"
        style={{
          fill: "#ed975d",
          fillRule: "nonzero",
        }}
        transform="matrix(.81714 0 0 .81714 -1142.303 -618.361)"
      />
      <path
        d="M2300.9 989.978s47.14 7.674 51.46-21.752c4.33-29.426 13.33-48.254-16.58-54.398-29.91-6.143-37.43 4.223-41.26 13.694-3.83 9.47-5.77 59.701 6.38 62.456Z"
        style={{
          fill: "#ffb27d",
          fillRule: "nonzero",
        }}
        transform="matrix(.81714 0 0 .81714 -1142.303 -618.361)"
      />
      <path
        d="M2300.86 912.596s21.4 33.787 40.51 41.789c19.11 8.003 28.49 3.94 28.49 3.94s-8.69-13.18-10.95-30.879c-.67-5.217-4.07-9.689-8.95-11.645-13.71-5.491-40.24-14.152-49.1-3.205Z"
        style={{
          fill: "#8c715d",
          fillRule: "nonzero",
        }}
        transform="matrix(.81714 0 0 .81714 -1142.303 -618.361)"
      />
      <path
        d="M2319.66 915.861s-12.55 6.433-17.71 12.461c-4.99 5.817-10.75 21.601-10.34 28.69 0 0-6.19-33.971 6.7-45.649 13.67-12.383 21.35 4.498 21.35 4.498Z"
        style={{
          fill: "#8c715d",
          fillRule: "nonzero",
        }}
        transform="matrix(.81714 0 0 .81714 -1142.303 -618.361)"
      />
      <path
        d="M2271.18 1100.97c1.43 95.81-33.88 68.99-23.4 78.08 2.52 2.19 12.86 18.5 51.84 20.44 29.4 1.46 82.42-12.42 84.38-17.85 6.78-18.78 2-24.62 7.65-53.38.59-3.06 1.32-6.37 2.17-9.98 4.72-19.75 9.31-26.47-7.42-58.2-15.16-28.75-33-52.1-34.42-51.26-40.01 23.75-41.38 3.84-41.38 3.84s-40.12 41.58-39.42 88.31Z"
        style={{
          fill: "#f4f4f4",
          fillRule: "nonzero",
        }}
        transform="matrix(.81714 0 0 .81714 -1142.303 -618.361)"
      />
      <path
        d="M2310.6 1012.66s-40.95 6.94-45.78 24.67c-4.84 17.72-52.09 151.87-52.09 151.87s50.39 1.38 60.06-8.29c9.67-9.67 22.99-117.26 22.99-117.26l14.82-50.99Z"
        style={{
          fill: "#bcc8b2",
          fillRule: "nonzero",
        }}
        transform="matrix(.81714 0 0 .81714 -1142.303 -618.361)"
      />
      <path
        d="M2299.62 1175.46s-6.08 7.83-8.07 8.77c-1.99.93-3.27.7-3.27.7l4.09-7.37 7.25-2.1ZM2301.02 1167.39l-17.66 8.57s.75 1.23 2.93 1.13c2.17-.09 7.66-2.1 7.66-2.1l7.07-7.6Z"
        style={{
          fill: "#ffb27d",
          fillRule: "nonzero",
        }}
        transform="matrix(.81714 0 0 .81714 -1142.303 -618.361)"
      />
      <path
        d="m2310.24 1006.41.36 6.25c-4.65 16-12.52 65.46-22.32 103.72l-6.86-54.79 5.64-4.6-2.11-7.75 18.46-35.05 6.83-7.78Z"
        style={{
          fill: "#a8b89a",
          fillRule: "nonzero",
        }}
        transform="matrix(.81714 0 0 .81714 -1142.303 -618.361)"
      />
      <path
        d="M2310.6 1269.42c63.18 21.14 113.21 6.93 113.21 6.93l-10.33-61.06-5.63-33.29-12.08-71.48-20.64-67.98-18.05-10.93-15.77 78.91-30.71 158.9Z"
        style={{
          fill: "#bcc8b2",
          fillRule: "nonzero",
        }}
        transform="matrix(.81714 0 0 .81714 -1142.303 -618.361)"
      />
      <path
        d="M2386.77 1117.94c1.06 1.67 1.1 34.13-29.49 42.08-30.59 7.94 44.8 15.67 44.8 15.67s24.31-18.65 23.96-23.23c-.36-4.58-39.27-34.52-39.27-34.52Z"
        style={{
          fill: "#a8b89a",
          fillRule: "nonzero",
        }}
        transform="matrix(.81714 0 0 .81714 -1142.303 -618.361)"
      />
      <path
        d="M2331 1188.64s41.91 23.99 82.48 26.65l-5.63-33.29-76.85 6.64Z"
        style={{
          fill: "#ed7d2b",
          fillRule: "nonzero",
        }}
        transform="matrix(.81714 0 0 .81714 -1142.303 -618.361)"
      />
      <path
        d="M2334.68 1171.27s-28.98-6.45-33.66-3.88c-4.68 2.57-16.11 11.94-17.54 15.2-1.43 3.26 10.41-2.47 14.27-3.51 2.49-.67 9.06 1.62 12.31 2.7 13.68 4.56 22.06 2.21 22.06 2.21l2.56-12.72Z"
        style={{
          fill: "#ffb27d",
          fillRule: "nonzero",
        }}
        transform="matrix(.81714 0 0 .81714 -1142.303 -618.361)"
      />
      <path
        d="M2351.98 1008.82s41.02 2.4 54.5 21.19c18.96 26.45 56.58 139.17 35.02 154.2-24.28 16.93-110.51 4.43-110.51 4.43s-2.87-13.41 1.13-19.9c3.99-6.48 68.87-16.96 71.86-19.96 3-2.99-37.43-58.39-37.43-67.37 0-8.98-14.57-72.59-14.57-72.59Z"
        style={{
          fill: "#bcc8b2",
          fillRule: "nonzero",
        }}
        transform="matrix(.81714 0 0 .81714 -1142.303 -618.361)"
      />
      <path
        d="m2349.62 1003.55-8.31 106.97 19.02-40.96-3.26-7.29s6.96-11.98 6.96-13.39c0-1.41-1.98-38.91-1.98-38.91l-12.43-6.42Z"
        style={{
          fill: "#a8b89a",
          fillRule: "nonzero",
        }}
        transform="matrix(.81714 0 0 .81714 -1142.303 -618.361)"
      />

    </g>
    <g id="interaction5"
     onClick={onElementClick}
     style={{cursor:'pointer'}}
     pointerEvents="visiblePainted">
      <path
        d="m2793.53 1235.39-1.01 5.07s-25.05-.09-30.91 26.75c0 0-1.59 11.17 6.47 8.78 8.05-2.38 6.8-8.08 18.2-11.2 11.4-3.13 18.83-5.52 20.56-13.82l1.87-9.24-15.18-6.34Z"
        style={{
          fill: "#ffb27d",
          fillRule: "nonzero",
        }}
        transform="matrix(.81714 0 0 .81714 -1119.05 -594.535)"
      />
      <path
        d="M1885.66 808.284c-5.46-22.476 2.46-32.944 9.6-38.517 3.39-8.273 7.26-14.236 11.22-16.484 7.21-4.101 15.86-4.549 21.91-4.191-1.56-1.608-4.62-2.193-4.62-2.193 3.75-.558 12.12 3.162 12.12 3.162 52.16-20.1 60.36 56.705 59.26 74.549-1.09 17.842-.94 38.858 26.2 52.916 51.78 26.825-17.21 127.914-17.21 127.914 1.32-14.421-65.22-90.516-99.93-113.305-8.45-12.44-11.45-34.416-11.68-36.145 1.46 17.424 4.44 27.198 7.11 32.701-15.82-14.561-18.17-49.525-13.98-80.407Z"
        style={{
          fill: "#9a3f37",
          fillRule: "nonzero",
        }}
        transform="matrix(.72491 0 0 .72491 -133.853 -430.285)"
      />
      <path
        d="M1906.27 893.269c-.18.078 16.76 8.871 29.49 5.387 11.98-3.267 19.81-18.802 19.65-18.837-5.08-1.007-6.54-7.98-6.43-37.941l-2.3.452-38.56 7.637s.88 12.137.9 23.571c.03 9.6-.56 18.706-2.75 19.731Z"
        style={{
          fill: "#ffb27d",
          fillRule: "nonzero",
        }}
        transform="matrix(.72491 0 0 .72491 -133.853 -430.285)"
      />
      <path
        d="M1908.12 849.967s.88 12.137.9 23.571c21.58-.608 32.99-20.296 37.66-31.208l-38.56 7.637Z"
        style={{
          fill: "#ed975d",
          fillRule: "nonzero",
        }}
        transform="matrix(.72491 0 0 .72491 -133.853 -430.285)"
      />
      <path
        d="M1866.86 1036.46c.32 58.9-8.64 84.17-8.64 84.17s53 26.93 115.99 7.1c.74-.23 46.49-11.67 46.49-11.67-8.1-2.31-15.99-42.37-17.02-44.56-13.3-28.56-12.17-41.08-13.74-49.86-2.93-16.39-2.99-33.355-1.53-49.351 3.84-42.346 15.27-77.498 15.27-77.498-17.16-3.905-33.5-10.445-51.37-17.145-4.11 5.117-16.4 10.149-27.3 9.905-12.54-.277-16.61-5.648-15.96-11.512-13.37 7.44-24.04 14.468-24.04 14.468-11.36 19.74-34.41 38.909-36.08 69.189-.41 7.446 17.92 75.104 17.93 76.764Z"
        style={{
          fill: "#f4f4f4",
          fillRule: "nonzero",
        }}
        transform="matrix(.72491 0 0 .72491 -133.853 -430.285)"
      />
      <path
        d="M1973.32 1043.02c5.41 7.66 15.78 54.21 41.59 60.06-13.3-33.18-23.4-47.72-24.97-57.92-2.93-19.04-2.99-38.74-1.53-57.326-8.64-15.142-15.09-25.525-15.09-25.525-.68-2.449-13.73-42.804-17.91-6.732-4.18 36.082 11.86 78.873 17.91 87.443Z"
        style={{
          fill: "#5f9aa8",
          fillRule: "nonzero",
        }}
        transform="matrix(.72491 0 0 .72491 -133.853 -430.285)"
      />
      <path
        d="M1900.91 860.793s51.06.843 51.1-30.964c.03-31.807 6.65-53.12-25.95-55.002-32.6-1.883-38.95 10.247-41.55 20.861-2.59 10.614 3.12 64.066 16.4 65.105Z"
        style={{
          fill: "#ffb27d",
          fillRule: "nonzero",
        }}
        transform="matrix(.72491 0 0 .72491 -133.853 -430.285)"
      />
      <path
        d="M1902.8 774.048s13.98 37.315 35.44 42.833c21.45 5.517 30.76-.232 30.76-.232s-16.46-13.073-19.87-35.896c0 0-35.78-24.466-46.33-6.705Z"
        style={{
          fill: "#9a3f37",
          fillRule: "nonzero",
        }}
        transform="matrix(.72491 0 0 .72491 -133.853 -430.285)"
      />
      <path
        d="M1906.26 777.077s-10.44 8.331-12.98 16.431c-2.55 8.099-3.69 24.773-8.55 24.206 0 0-10.53-25.363 1.3-39.711 12.55-15.214 20.23-.926 20.23-.926Z"
        style={{
          fill: "#9a3f37",
          fillRule: "nonzero",
        }}
        transform="matrix(.72491 0 0 .72491 -133.853 -430.285)"
      />
      <path
        d="m1820.57 1077.34-24.12 73.53 13.75 5.51 39.88-64.96-29.51-14.08Z"
        style={{
          fill: "#ffb27d",
          fillRule: "nonzero",
        }}
        transform="matrix(.72491 0 0 .72491 -133.853 -430.285)"
      />
      <path
        d="M1885.59 886.176s-28.44 11.286-36.67 28.879c-8.23 17.592-20.69 135.605-25.65 147.725-4.97 12.12-8.7 21.71-8.7 21.71s14.34 12.08 28.76 12.97c14.42.89 42.26-211.284 42.26-211.284Z"
        style={{
          fill: "#f3cd83",
          fillRule: "nonzero",
        }}
        transform="matrix(.72491 0 0 .72491 -133.853 -430.285)"
      />
      <path
        d="M1909.05 876.039s-29.54 72.982-30.41 87.753c-.87 14.77-3.48 241.538-3.48 241.538s1.74 10.42-18.24 6.08c-19.99-4.35-25.2-8.69-25.2-8.69s19.12-139.88 13.9-188.54c-5.21-48.651 39.97-128.004 39.97-128.004l23.46-10.137ZM1955.09 1200.98s7.54 27.22 33.6 23.75c26.07-3.48 63.13-19.12 63.13-19.12s-11.14-35.19-24.99-81.43c-16.76-55.98-37.52-128.146-47.3-173.664-2.75-12.754-4.63-23.406-5.32-31.035l-21.9-41.835s-31.97 72.244-33.71 90.49c-1.73 18.245 36.49 232.844 36.49 232.844Z"
        style={{
          fill: "#f3cd83",
          fillRule: "nonzero",
        }}
        transform="matrix(.72491 0 0 .72491 -133.853 -430.285)"
      />
      <path
        d="m1981.12 965.417-28.81-87.771c.08.037 36.32 10.583 54.41 19.724 0 0 11.31 14.58 18.31 48.218 0 0 7.39 80.762-9.12 110.302 0 0-52.13 10.42-135.53-18.25v-19.11s91.22 4.78 109.9 6.95l-9.16-60.063Z"
        style={{
          fill: "#ffb27d",
          fillRule: "nonzero",
        }}
        transform="matrix(.72491 0 0 .72491 -133.853 -430.285)"
      />
      <path
        d="M1956.25 1006.36c1.16 29.54 8.12 86.89 70.09 117.58.16.08.33.17.49.24-16.76-55.98-37.52-128.146-47.3-173.664-11.39 17.011-23.91 39.584-23.28 55.844Z"
        style={{
          fill: "#efbc58",
          fillRule: "nonzero",
        }}
        transform="matrix(.72491 0 0 .72491 -133.853 -430.285)"
      />
      <path
        d="M1952.31 877.646s53.18 3.607 65.92 31.989c12.74 28.382 16.8 122.795.58 150.595 0 0-22.33 6.36-61.82-1.02-.49-.09-.97-.19-1.46-.28 0 0-12.6-30.85-1.3-36.93 0 0 33.3 1.74 29.83-9.26-3.48-11.01-31.75-135.094-31.75-135.094Z"
        style={{
          fill: "#f3cd83",
          fillRule: "nonzero",
        }}
        transform="matrix(.72491 0 0 .72491 -133.853 -430.285)"
      />
      <path
        d="M1799.88 1148.86s-22.03 12.17-24.93 21.44c-2.89 9.27-.9 28.69 2.59 28.4 3.5-.29 8.72-20.87 11.61-22.32 2.9-1.44 6.96-5.79 6.96-2.6 0 3.18-2.9 8.98-1.45 9.85 1.45.87 6.08-8.11 9.27-9.85 3.19-1.74 4.53-16.07 7.63-19.92 3.09-3.84-11.68-5-11.68-5Z"
        style={{
          fill: "#ffb27d",
          fillRule: "nonzero",
        }}
        transform="matrix(.72491 0 0 .72491 -133.853 -430.285)"
      />
      <path
        d="M1886.91 1097.91h-89.15l-38.14-136.679h89.14l38.15 136.679Z"
        style={{
          fill: "#0cbabe",
          fillRule: "nonzero",
        }}
        transform="matrix(.72491 0 0 .72491 -133.853 -430.285)"
      />
      <path
        d="M1880.38 1018.53s-19.7-12.13-27.96-10.27c-8.26 1.87-29.32 17.12-19.19 20.42 10.13 3.31 18.33-.16 22.22 2.24 3.9 2.4 6.77 6.78 24.93 6.72 18.15-.06 0-19.11 0-19.11Z"
        style={{
          fill: "#ffb27d",
          fillRule: "nonzero",
        }}
        transform="matrix(.72491 0 0 .72491 -133.853 -430.285)"
      />
    </g>
    <path
      d="m2271.15 1534.02-.37-.01c-3.07-.05-5.44-2.56-5.15-5.44l20.45-204.2 17.84 1.59-29.36 205.3c-.23 1.6-1.7 2.79-3.41 2.76ZM2468.03 1534.02l.38-.01c3.06-.05 5.43-2.56 5.14-5.44l-20.45-204.2-17.84 1.59 29.36 205.3c.23 1.6 1.7 2.79 3.41 2.76Z"
      style={{
        fill: "#b5453c",
        fillRule: "nonzero",
      }}
      transform="matrix(.81714 0 0 .81714 -1116.303 -618.361)"
    />
    <path
      d="M2451.83 1327.35H2174.9c-5.57 0-10.12-4.55-10.12-10.11v-12.46c0-5.56 4.55-10.12 10.12-10.12h287.04v22.58c0 5.56-4.55 10.11-10.11 10.11Z"
      style={{
        fill: "#b5453c",
        fillRule: "nonzero",
      }}
      transform="matrix(.81714 0 0 .81714 -1116.303 -618.361)"
    />
    <path
      d="M2321.87 1327.35h-176.54c-4.48 0-8.15-3.66-8.15-8.14v-16.4c0-4.48 3.67-8.15 8.15-8.15h176.54c4.48 0 8.15 3.67 8.15 8.15v16.4c0 4.48-3.67 8.14-8.15 8.14ZM2556.45 1534.02l-.38-.01c-3.06-.05-5.43-2.56-5.14-5.44l20.44-204.2 17.84 1.59-29.35 205.3c-.23 1.6-1.7 2.79-3.41 2.76ZM2753.33 1534.02l.37-.01c3.07-.05 5.43-2.56 5.15-5.44l-20.45-204.2-17.84 1.59 29.36 205.3c.23 1.6 1.69 2.79 3.41 2.76ZM2412.2 1534.02l-.38-.01c-3.06-.05-5.43-2.56-5.14-5.44l20.45-204.2 17.84 1.59-29.36 205.3c-.23 1.6-1.7 2.79-3.41 2.76Z"
      style={{
        fill: "#b5453c",
        fillRule: "nonzero",
      }}
      transform="matrix(.81714 0 0 .81714 -1116.303 -618.361)"
    />
    <g id="interaction4"
     onClick={onElementClick}
     style={{cursor:'pointer'}}
     pointerEvents="visiblePainted"> 
      <path
        d="m2747.24 1294.66 36.29-179.23c4.66-24.26-15.45-46.45-41.56-45.86l-69.99 1.6c-24.15.55-45.14 16.72-51.85 39.92l-53.11 183.57h180.22Z"
        style={{
          fill: "#93cae8",
          fillRule: "nonzero",
        }}
        transform="matrix(.81714 0 0 .81714 -1140.303 -618.361)"
      />
      <path
        d="m2725.28 1294.66 36.29-179.23c4.66-24.26-15.45-46.45-41.55-45.86l-70 1.6c-24.15.55-45.14 16.72-51.85 39.92l-53.1 183.57h180.21Z"
        style={{
          fill: "#93cae8",
          fillRule: "nonzero",
        }}
        transform="matrix(.81714 0 0 .81714 -1140.303 -618.361)"
      />
      <path
        d="M2602.22 995.96s-50.99-.432-63.26 16.78c-12.27 17.21-28.59 126.41-25.14 140.6 8.01 32.9 40.32-12.74 54.9-31.61 14.58-18.87 33.5-125.77 33.5-125.77Z"
        style={{
          fill: "#5369a5",
          fillRule: "nonzero",
        }}
        transform="matrix(.81714 0 0 .81714 -1140.303 -618.361)"
      />
      <path
        d="m2515.02 1123.46-13.72 110.04h39.29l10.72-85.83-36.29-24.21Z"
        style={{
          fill: "#5369a5",
          fillRule: "nonzero",
        }}
        transform="matrix(.81714 0 0 .81714 -1140.303 -618.361)"
      />
      <path
        d="M2526.3 1233.5s21.21-111.49 19.97-147.41c0 0 29.27 102.87 24.48 123.5-4.79 20.63-38.64 39.16-44.45 23.91Z"
        style={{
          fill: "#425484",
          fillRule: "nonzero",
        }}
        transform="matrix(.81714 0 0 .81714 -1140.303 -618.361)"
      />
      <path
        d="m2697.14 1147.3 6.99 97.69c.27 3.67-2.08 7.02-5.62 8.04-7.83 2.28-16.86 3.86-34.27 7.47-16.36 3.39-38.36 6.4-65.4 6.57-43 .27-59.28-14.55-59.28-14.55-1.86.03.97-8.97 3.52-51.76.87-14.49 1.7-32.87 2.3-56.09.55-20.9.91-45.72.95-75.17.04-35.43 54.88-73.543 55.89-73.543l.01.018c0 5.105 8.04 14.515 20.42 15.325 14.7.96 33.07-9.64 33.07-18.109 0-.012 2.56 2.495 3.54 4.148 5.79 9.771 24.05 48.211 32.13 87.021 1.19 5.75 2.12 11.82 2.86 18.21l.03.29c.14 1.17.27 2.34.39 3.52l.06.63c.11 1.07.22 2.16.31 3.25.03.31-.22.86.08.94 2.95.73 11.02 27.69 1.68 27.45-2.84-.08.23 5.72.34 8.65l.01.22-.01-.22Z"
        style={{
          fill: "#5369a5",
          fillRule: "nonzero",
        }}
        transform="matrix(.81714 0 0 .81714 -1140.303 -618.361)"
      />
      <path
        d="m2601.7 988.177.03 5.271s-1.44 16.482 18.86 18.892 35.68-6.43 35.13-19.149c-1.83-9.849-3.84-16.613-3.97-24.763-.05-3.028.16-6.242.72-9.871l-39.33-.786-11.59 1.894.15 28.512Z"
        style={{
          fill: "#af5945",
          fillRule: "nonzero",
        }}
        transform="matrix(.81714 0 0 .81714 -1140.303 -618.361)"
      />
      <path
        d="m2601.55 959.665.15 20.39c4.18 1.943 11.84 2.083 17.8 1.017 17.8-3.185 26.3-9.265 32.41-18.886-.05-3.029 0 0 .56-3.629l-39.33-.786-11.59 1.894Z"
        style={{
          fill: "#a0513f",
          fillRule: "nonzero",
        }}
        transform="matrix(.81714 0 0 .81714 -1140.303 -618.361)"
      />
      <path
        d="M2655.48 952.228c-4.56 24.561-36.25 23.754-49.95 22.21-3.85-.428-6.28-.92-6.28-.92-4.34-1.161-6.77-8.006-7.89-16.973-2.24-17.744.58-43.793 3.58-50.455 4.51-10.024 13.01-20.866 44.93-13.044 31.92 7.829 21.47 27.713 15.61 59.182Z"
        style={{
          fill: "#af5945",
          fillRule: "nonzero",
        }}
        transform="matrix(.81714 0 0 .81714 -1140.303 -618.361)"
      />
      <path
        d="M2585.15 882.712s14.05-1.081 30.61-8.285c13.45-5.847 30.42.593 35.93 3.494a8.262 8.262 0 0 0-1.35-3.314s2.48 1.753 2.84 4.205c.26.187.37.317.3.36 0 0 2.81-.512 4.61.298 0 0-1.26-.18-1.71.63l-.2.223c20.97 10.136 22.91 43.291 16.16 50.962-8.12 9.23-18.89 28.077-18.89 28.077s-2.52-2.972-1.05-11.094c1.48-8.122-5.53-8.122-14.39-11.814s-1.48-14.029-9.23-20.306c-7.76-6.275-36.18-2.584-35.81-7.383-10.56-3.844-7.82-24.973-7.82-26.053Z"
        style={{
          fill: "#0f2a43",
          fillRule: "nonzero",
        }}
        transform="matrix(.81714 0 0 .81714 -1140.303 -618.361)"
      />
      <path
        d="M2680.74 1067.27s25.07 87.33-53.96 98.12c0 0 95.71-7.15 95.71-8.35 0-1.2-41.75-89.77-41.75-89.77Z"
        style={{
          fill: "#425484",
          fillRule: "nonzero",
        }}
        transform="matrix(.81714 0 0 .81714 -1140.303 -618.361)"
      />
      <path
        d="M2655.72 993.191s53.21 4.331 62.78 15.699c9.57 11.36 37.81 137.59 29.01 162.11-5.38 15.01-73.48 4.05-109.61 5.08l-5.69-10.17s68.08-27.51 68.08-32.3c0-11.78-44.57-140.419-44.57-140.419Z"
        style={{
          fill: "#5369a5",
          fillRule: "nonzero",
        }}
        transform="matrix(.81714 0 0 .81714 -1140.303 -618.361)"
      />
      <path
        d="M2632.21 1165.91s-17.34-2.09-21.83 0c-4.48 2.1-14.95 8.38-14.95 10.17 0 1.8 42.47 0 42.47 0l-5.69-10.17Z"
        style={{
          fill: "#ffb27d",
          fillRule: "nonzero",
        }}
        transform="matrix(.81714 0 0 .81714 -1140.303 -618.361)"
      />
    </g>
    <g id="interaction1"
     onClick={onElementClick}
     style={{cursor:'pointer'}}
     pointerEvents="visiblePainted">
      <path
        d="m2747.24 1294.66 36.29-179.23c4.66-24.26-15.45-46.45-41.56-45.86l-69.99 1.6c-24.15.55-45.14 16.72-51.85 39.92l-53.11 183.57h180.22Z"
        style={{
          fill: "#93cae8",
          fillRule: "nonzero",
        }}
        transform="matrix(-.81714 0 0 .81714 2429.64 -618.361)"
      />
      <path
        d="M699.933 788.951c-9.754-5.121-24.595-5.814-27.006-6.138-28.852-3.877-46.217 38.047-47.239 50.75-1.882 23.416-14.269 26.986-31.704 41.65-34.96 29.404-24.708 63.571-5.591 76.266 11.469 7.618 31.686 0 31.686 0 .285.199 55.289-30.749 82.402-45.678 40.646-5.778 22.307-103.798-2.548-116.85Z"
        style={{
          fill: "#37589a",
          fillRule: "nonzero",
        }}
        transform="matrix(.96015 0 0 .96015 -378.866 -633.074)"
      />
      <path
        d="M679.756 893.094c.119.077-13.34 4.673-22.34.687-8.467-3.742-14.881-17.672-14.755-17.679 3.852-.178 8.079-3.545 11.336-25.64l1.64.588 27.575 9.923s-1.998 8.848-3.29 17.272c-1.088 7.073-1.672 13.849-.166 14.849Z"
        style={{
          fill: "#f5c0a8",
          fillRule: "nonzero",
        }}
        transform="matrix(.96015 0 0 .96015 -378.866 -633.074)"
      />
      <path
        d="M683.212 860.973s-1.998 8.848-3.29 17.272c-15.839-2.851-22.055-18.631-24.285-27.195l27.575 9.923Z"
        style={{
          fill: "#f3b092",
          fillRule: "nonzero",
        }}
        transform="matrix(.96015 0 0 .96015 -378.866 -633.074)"
      />
      <path
        d="M687.319 869.755s-37.728-5.064-34.213-28.511c3.515-23.446 1.013-39.891 25.25-37.649 24.237 2.242 27.565 11.888 28.296 20 .73 8.112-9.428 46.873-19.333 46.16Z"
        style={{
          fill: "#f5c0a8",
          fillRule: "nonzero",
        }}
        transform="matrix(.96015 0 0 .96015 -378.866 -633.074)"
      />
      <path
        d="M705.277 810.743s-24.152 20.813-40.58 22.491c-16.428 1.678-22.647-3.595-22.647-3.595s9.683-8.04 15.51-21.015c1.717-3.824 5.346-6.449 9.523-6.809 11.73-1.013 33.967-1.475 38.194 8.928Z"
        style={{
          fill: "#37589a",
          fillRule: "nonzero",
        }}
        transform="matrix(.96015 0 0 .96015 -378.866 -633.074)"
      />
      <path
        d="M690.179 808.879s8.08 7.812 10.625 13.606c2.455 5.592 3.191 18.966 1.236 24.277 0 0 12.589-24.478 5.461-36.37-7.557-12.611-17.322-1.513-17.322-1.513Z"
        style={{
          fill: "#37589a",
          fillRule: "nonzero",
        }}
        transform="matrix(.96015 0 0 .96015 -378.866 -633.074)"
      />
      <path
        d="M711.525 948.671c-1.141 76.369-.313 60.409-8.667 67.659-2.013 1.75-7.672 2.79-15.12 3.28-23.418 1.55-64.584-2.31-66.15-6.63-5.401-14.979-1.588-19.631-6.094-42.557a194.573 194.573 0 0 0-1.734-7.951c-3.758-15.746-7.414-21.103 5.916-46.4 12.089-22.915 24.648-41.513 25.778-40.842 31.895 18.934 34.467 3.02 34.467 3.02s32.163 33.169 31.604 70.421Z"
        style={{
          fill: "#fff",
          fillRule: "nonzero",
        }}
        transform="matrix(.96015 0 0 .96015 -378.866 -633.074)"
      />
      <path
        d="M679.922 878.245s32.819 5.564 36.673 19.694c3.853 14.131 26.532 97.2 26.532 97.2s-16.684 2.997-24.391-4.71c-7.708-7.708-26.819-71.508-26.819-71.508l-11.995-40.676Z"
        style={{
          fill: "#fff",
          fillRule: "nonzero",
        }}
        transform="matrix(.96015 0 0 .96015 -378.866 -633.074)"
      />
      <path
        d="M687.738 1019.61c-23.418 1.55-64.584-2.31-66.15-6.63-5.401-14.979-1.588-19.631-6.094-42.557l11.295-31.805s-1.868 35.417 8.577 44.733c10.434 9.316 42.765 17.979 47.842 25.769 2.45 3.73 4.061 7.48 4.53 10.49Z"
        style={{
          fill: "#ebf3ff",
          fillRule: "nonzero",
        }}
        transform="matrix(.96015 0 0 .96015 -378.866 -633.074)"
      />
      <path
        d="M660.907 1004.71s23.103-5.144 26.831-3.09c1.478.81 3.802 2.48 6.14 4.35 3.559 2.85 7.15 6.19 7.839 7.76 1.141 2.6-8.299-1.97-11.37-2.79-1.988-.54-7.222 1.28-9.814 2.15-10.904 3.63-17.585 1.76-17.585 1.76l-2.041-10.14Z"
        style={{
          fill: "#f5c0a8",
          fillRule: "nonzero",
        }}
        transform="matrix(.96015 0 0 .96015 -378.866 -633.074)"
      />
      <path
        d="M645.434 875.213s-31.021 1.912-41.76 16.89c-15.117 21.084-45.106 110.937-27.919 122.927 19.355 13.49 88.088 3.53 88.088 3.53s2.288-10.69-.895-15.86c-3.182-5.177-54.898-13.531-57.285-15.918-2.387-2.386 29.836-46.544 29.836-53.705 0-7.16 9.935-57.864 9.935-57.864Z"
        style={{
          fill: "#fff",
          fillRule: "nonzero",
        }}
        transform="matrix(.96015 0 0 .96015 -378.866 -633.074)"
      />
      <path
        d="M688.856 1008.05s4.841 6.24 6.428 6.99c1.587.74 2.612.56 2.612.56l-3.262-5.88-5.778-1.67ZM687.738 1001.62l14.074 6.83s-.599.98-2.332.9c-1.733-.08-6.104-1.68-6.104-1.68l-5.638-6.05Z"
        style={{
          fill: "#f5c0a8",
          fillRule: "nonzero",
        }}
        transform="matrix(.96015 0 0 .96015 -378.866 -633.074)"
      />
      <path
        d="M695.284 1024.6h111.331l33.491-83.279h-111.16l-33.662 83.279Z"
        style={{
          fill: "#b48cda",
          fillRule: "nonzero",
        }}
        transform="matrix(.96015 0 0 .96015 -378.866 -633.074)"
      />
      <path
        d="M807.92 1021.36H660.907v3.24h145.708l1.305-3.24Z"
        style={{
          fill: "#233862",
          fillRule: "nonzero",
        }}
        transform="matrix(.96015 0 0 .96015 -378.866 -633.074)"
      />
      <path
        d="M778.512 982.961c.839-2.927-.854-5.3-3.782-5.3-2.927 0-5.98 2.373-6.82 5.3-.839 2.928.853 5.301 3.781 5.301 2.927 0 5.981-2.373 6.821-5.301Z"
        style={{
          fill: "#fff",
          fillRule: "nonzero",
        }}
        transform="matrix(.96015 0 0 .96015 -378.866 -633.074)"
      />
    </g>
    <path
      d="M2737.12 1327.35h-276.93c-5.56 0-10.12-4.55-10.12-10.11v-12.46c0-5.56 4.56-10.12 10.12-10.12h287.05v22.58c0 5.56-4.55 10.11-10.12 10.11Z"
      style={{
        fill: "#b5453c",
        fillRule: "nonzero",
      }}
      transform="matrix(.81714 0 0 .81714 -1116.303 -618.361)"
    />
    <path
      d="M2607.17 1327.35h-176.54c-4.48 0-8.15-3.66-8.15-8.14v-16.4c0-4.48 3.67-8.15 8.15-8.15h176.54c4.48 0 8.14 3.67 8.14 8.15v16.4c0 4.48-3.66 8.14-8.14 8.14Z"
      style={{
        fill: "#b5453c",
        fillRule: "nonzero",
      }}
      transform="matrix(.81714 0 0 .81714 -1116.303 -618.361)"
    />
    <path
      d="M3475.92 1000.28c0-9.462-9.6-17.141-21.43-17.141H2271.81c-11.83 0-21.43 7.679-21.43 17.141v232.64c0 9.46 9.6 17.14 21.43 17.14h1182.68c11.83 0 21.43-7.68 21.43-17.14v-232.64Z"
      style={{
        fill: "#0083d3",
      }}
      transform="matrix(1.01647 0 0 1.27132 -2160.18 -904.403)"
    />
    <path
      style={{
        fill: "#005f9a",
        fillRule: "nonzero",
      }}
      d="M2092.18 1179.53h974.111v23.32H2092.18z"
      transform="matrix(1.31716 0 0 .81714 -2649.51 -618.361)"
    />
  </svg>
)};

export default SvgMembers;
