import React, { useEffect, useState } from "react";
import authService from "../../services/authService";
import Button from "../common/button";
import ViewModeAlert from "../viewModeAlert";

const introVideo = (props) => {
  const [showViewModeMessage, setShowViewModeMessage] = useState(false);
  useEffect(() => {}, []);

  let user = authService.getCurrentUser();
  const toggleViewModeMessage = () => {
    setShowViewModeMessage(!showViewModeMessage);
  };

  const onRegister = () => {
    props.history.push({
      pathname: "/auth",
      state: { prevPath: props.location.pathname },
    });
    setShowViewModeMessage(false);
  };

  return (
    <>
      <section className="main-wrapper info-panel">
        <div className="main-header primary-color-lightgreen-foreground">
          Willkommen zu Ethisch fragwürdig!
        </div>
        <div className="container-limit my-limit">
          <div className="video-wrapper">
            <iframe
              title="entry-video"
              height="720"
              widht="640"
              src="https://player.vimeo.com/video/969804184?h=a59e67ea88"
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
          <div className="submit-parent-wrapper">
            <div className="button-wrapper">
              <Button
                onClick={(e) => {
                  if (user)
                    props.history.push({
                      pathname: "/ethics/ethically-questionable/introduction",
                    });
                  else {
                    setShowViewModeMessage(true);
                  }
                }}
                href="#"
                className="thirdBtn"
                label="Weiter"
              />
            </div>
          </div>
        </div>
      </section>
      {showViewModeMessage && (
        <ViewModeAlert
          onRegister={onRegister}
          onCancel={toggleViewModeMessage}
        />
      )}
    </>
  );
};

export default introVideo;
