import React, { Component } from 'react';
import information from './info.json'

function Info() {
    return (
        <section className="womix__horizontal-module">
            <div className="womix__text-module">
                <div className="info_main">
                    <h2 className="womix__gradient">Erweitere Deine Fähigkeiten</h2>
                    <p>Für die Herausforderungen der Zukunft sind Kompetenzen wie Problemlösefähigkeit und der Umgang mit neuen Technologien so gefragt wie nie zuvor. Dieser Kurs bietet den idealen Einstieg für Interessierte und Fortgeschrittene in das breite Feld des maschinellen Lernens.
                    </p>

                    <div className="info-details">
                         <img id='woher-kommt' className="tile-image" src={`/images/icons/paths/milestones.png`} />
                         <p>
                         {information.info.milestones}
                         </p>

                         <img id='wie-baut' className="tile-image" src={`/images/icons/paths/programming.png`} />
                         <p>
                         {information.info.programming}
                         </p>
                     
                         <img id='wie-geht' className="tile-image" src={`/images/icons/paths/ethics.png`} />
                         <p>
                         {information.info.ethics}
                         </p>

                    </div>
                </div>

                {/* <div className="">
                    <h2 className="womix__gradient"> Deine Fähigkeiten</h2>
                    <p> Für die Herausforderungen der Zukunft sind Kompetenzen wie Problemlösefähigkeit
                    und der Umgang mit neuen Technologien so gefragt wie nie zuvor. Dieser Kurs bietet
                    den idealen Einstieg für Interessierte und Fortgeschrittene
                     in das breite Feld des maschinellen Lernens. Die Aufgaben bauen schrittweise aufeinander
                     auf. Erste Erfahrungen mit Programmierung und
                     Freude am Umgang mit Zahlen sind von Vorteil.
                    </p>
                </div>
                <div className="">
                    <h2 className="womix__gradient"> KI und Gesellschaft </h2>
                    <p> Mit KI lassen sich viele Probleme lösen. KI stellt jedoch auch ganz neue Anforderungen
                     an unsere Gesellschaft und birgt auch Risiken. Damit KI sinnvoll eingesetzt wird,
                     ist eine gesellschaftliche Diskussion notwendig. Mit unserer Sparte "KI und Gesellschaft"
                     möchten wir den Teilnehmenden die Möglichkeit geben, sich kritisch mit KI auseinander zu setzen und
                      zu diskutieren.
                    </p>
                </div> */}
            </div>
        </section>
    )
}

export default Info;