import React from "react";
import Joi from "joi-browser";
import Form from "../common/form";
import auth from "../../services/authService";
import { Redirect } from "react-router-dom";
import logo from "../../img/logo_KI-Kurs.png";
import userService from "../../services/userService";
import moment from "moment";
import "react-day-picker/lib/style.css";
import "react-datepicker/dist/react-datepicker.css";

class Register extends Form {
  constructor(props) {
    super(props);
    this.recaptchaRef = React.createRef();
    this.handleIsAISchool = this.handleIsAISchool.bind(this);
  }

  state = {
    data: {
      username: "",
      student: false,
      teacher: false,
      others: false,
      password: "",
      confirmPassword: "",
      school: "",
      postNumber: "",
      passwordValid: false,
      terms: false,
      fullName: "",
      //birthday: '',
      email: "",
      parentEmail: "",
      showParent: false,
      is_ai_school: false,
      ageBelow18: false,
      ageAbove18: true,
      eventsCheck: false,
    },

    errors: {},
    registerError: "",
    inputSchoolError: "",
    loading: false,
    showOlderWarning: true,
  };

  getDate = (years) => {
    return moment().subtract(years, "years")._d;
  };

  schema = {
    username: Joi.string()
      .regex(/^[a-zA-Z0-9]+$/)
      .required()
      .min(3)
      .max(20)
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            case "any.empty":
              err.message = "Der Benutzername sollte nicht leer sein!";
              break;
            case "string.regex":
              err.message =
                "Der Benutzername sollte nur eine Kombination aus Buchstaben und Zahlen ohne Leerzeichen enthalten";
              break;
            case "string.min":
              err.message = "Benutzername muss 3 bis 20 Zeichen lang sein";
              break;
            case "string.max":
              err.message = "Benutzername muss 3 bis 20 Zeichen lang sein";
              break;
            default:
              break;
          }
        });
        return errors;
      }),
    is_ai_school: Joi.boolean().optional(),
    password: Joi.string().required(),
    confirmPassword: Joi.string().allow("").optional(),
    //postNumber: Joi.string().allow('').optional().max(5),
    passwordValid: Joi.boolean().required().invalid(false),
    terms: Joi.boolean().required().invalid(false),
    fullName: Joi.when("is_ai_school", {
      is: false,
      then: Joi.string().allow("").optional(),
      otherwise: Joi.string().required(),
    }),

    student: Joi.when("is_ai_school", {
      is: false,
      then: Joi.boolean().optional(),
      otherwise: Joi.boolean().required(),
    }),

    teacher: Joi.when("is_ai_school", {
      is: true,
      then: Joi.when("student", {
        is: false,
        then: Joi.boolean().required(),
      }),
      otherwise: Joi.boolean().optional(),
    }),

    others: Joi.when("is_ai_school", {
      is: true,
      then: Joi.when("student", {
        is: false,
        then: Joi.when("teacher", {
          is: false,
          then: Joi.boolean().required(),
        }),
        otherwise: Joi.boolean().optional(),
      }),
    }),

    school: Joi.when("is_ai_school", {
      is: false,
      then: Joi.string().allow("").optional(),
      otherwise: Joi.when("student", {
        is: false,
        then: Joi.when("teacher", {
          is: false,
          then: Joi.string().allow("").optional(),
        }),
        otherwise: Joi.string().required(),
      }),
    }),

    postNumber: Joi.when("is_ai_school", {
      is: false,
      then: Joi.string().allow("").optional(),
      otherwise: Joi.when("others", {
        is: false,
        then: Joi.string().allow("").optional().max(5),
        otherwise: Joi.string().required().max(5),
      }),
    }),
    email: Joi.string().email().required(),
    eventsCheck: Joi.boolean().optional(),
    showParent: Joi.boolean().optional(),
    ageBelow18: Joi.boolean().required(),
    ageAbove18: Joi.when("ageBelow18", {
      is: false,
      then: Joi.boolean().required(),
      otherwise: Joi.boolean().optional(),
    }),

    parentEmail: Joi.when("ageBelow18", {
      is: true,
      then: Joi.string().email().required(),
      otherwise: Joi.string().email().allow("").optional(),
    }),
  };

  handleConfirmPasswordChange = ({ currentTarget: input }) => {
    const schema = {
      password: Joi.string().required(),
      confirmPassword: Joi.string().valid(Joi.ref("password")).required(),
    };
    let fieldName = input.name;

    const { data, errors } = this.state;
    const { password, confirmPassword } = data;

    let obj;

    if (fieldName === "password") {
      obj = {
        password: input.value,
        confirmPassword,
      };
      data.password = input.value;
    } else {
      obj = {
        password,
        confirmPassword: input.value,
      };
      data.confirmPassword = input.value;
    }

    const { error } = Joi.validate(obj, schema);
    delete errors["confirmPassword"];
    delete errors["password"];
    if (!error) {
      data.passwordValid = true;
    } else {
      data.passwordValid = false;
      error.details.forEach((err) => {
        errors[err.path[0]] = "error";
      });
    }
    this.setState({
      data,
      errors,
    });
  };

  addOverflow = (add) => {
    if (add)
      document
        .getElementsByClassName("content-login")[0]
        .classList.add("overflow");
    else
      document
        .getElementsByClassName("content-login")[0]
        .classList.remove("overflow");
  };

  submitForm = (e) => {
    e.preventDefault();
    const { errors, data } = this.state;
    const { options } = this.props;
    const {
      username,
      password,
      confirmPassword,
      school,
      postNumber,
      student,
      teacher,
      others,
      fullName,
      email,
      parentEmail,
      is_ai_school,
      ageBelow18,
      ageAbove18,
      eventsCheck,
    } = data;

    const validateErrors = this.validate();
    this.setState({ errors: validateErrors || errors });
    if (validateErrors) return;
    if (is_ai_school && !options.includes(data.school)) {
      data.school = "";
      this.setState({
        data,
        registerError: "Nur Schulen aus der Liste auswählen",
        loading: false,
      });
      return;
    }

    // If all checks passed then set the load to true

    this.setState({
      registerError: "",
      loading: true,
    });

    userService
      .register(
        username,
        password,
        confirmPassword,
        school,
        postNumber,
        student,
        teacher,
        others,
        fullName,
        email,
        parentEmail,
        is_ai_school,
        ageBelow18,
        ageAbove18,
        eventsCheck
      )
      .then(() => {
        window.location.reload();
      })
      .catch((err) => {
        let error = "Es ist ein unerwarteter Fehler aufgetreten.";
        if (err.response && err.response.status === 400) {
          error = err.response.data.error;
        }

        // data.captcha = '';
        this.setState({
          registerError: error,
          loading: false,
          data,
        });
      });
  };

  handleBirthdayChange = ({ currentTarget: input }) => {
    const { data } = this.state;

    setTimeout(() => {
      const errors = { ...this.state.errors };
      data.birthday = input.value;
      if (
        moment(input.value) < moment(this.getDate(13)) &&
        moment(input.value) > moment(this.getDate(18))
      ) {
        data.showParent = true;
      } else {
        data.showParent = false;
      }

      let showOlderWarning = false;
      if (moment(input.value) < moment(this.getDate(20))) {
        this.props.onShowOlderWarning(true);
        showOlderWarning = true;
      } else {
        this.props.onShowOlderWarning(false);
      }

      let showYoungerWarning = false;
      if (moment(input.value) > moment(this.getDate(13))) {
        this.props.onShowYoungerWarning(true);
        showYoungerWarning = true;
      } else {
        this.props.onShowYoungerWarning(false);
      }

      this.setState({
        data,
        errors,
        showOlderWarning,
        showYoungerWarning,
      });
    }, 600);
  };

  //Handle is_ai_school property

  handleIsAISchool(event) {
    const { data } = this.state;
    if (!event.target.checked) {
      data.fullName = "";
      data.postNumber = "";
      data.school = "";
      data.teacher = false;
      data.student = false;
      data.others = false;
    } else {
      data.student = event.target.checked;
      data.teacher = false;
      data.others = false;
    }
    data.is_ai_school = event.target.checked;

    this.setState({
      data,
    });
  }

  handleAgeBelow18Change = () => {
    const { data } = this.state;
    setTimeout(() => {
      const errors = { ...this.state.errors };
      data.ageBelow18 = true;
      data.showParent = true;
      data.ageAbove18 = false;
      this.setState({
        data,
        errors,
      });
    }, 600);
  };

  handleAgeAbove18Change = () => {
    const { data } = this.state;
    setTimeout(() => {
      const errors = { ...this.state.errors };
      data.ageAbove18 = true;
      data.showParent = false;
      data.parentEmail = "";
      data.ageBelow18 = false;
      this.setState({
        data,
        errors,
      });
    }, 600);
  };

  handleIsStudentChange = () => {
    const { data } = this.state;

    setTimeout(() => {
      const errors = { ...this.state.errors };
      data.student = true;
      data.teacher = false;
      data.others = false;

      this.setState({
        data,
        errors,
      });
    }, 600);
  };

  handleIsTeacherChange = () => {
    const { data } = this.state;

    setTimeout(() => {
      const errors = { ...this.state.errors };
      data.student = false;
      data.teacher = true;
      data.others = false;

      this.setState({
        data,
        errors,
      });
    }, 600);
  };

  handleIsOthersChange = () => {
    const { data } = this.state;

    setTimeout(() => {
      const errors = { ...this.state.errors };
      data.student = false;
      data.teacher = false;
      data.others = true;

      this.setState({
        data,
        errors,
      });
    }, 600);
  };

  render() {
    const { activeTab, options } = this.props;
    let optionsHTML = [];
    let idx = 0;
    options.forEach((school) => {
      optionsHTML.push(<option key={idx}>{school}</option>);
      idx++;
    });

    if (auth.getCurrentUser()) return <Redirect to="/home" />;
    const { data, errors, loading, registerError } = this.state;
    const {
      username,
      student,
      teacher,
      others,
      password,
      confirmPassword,
      school,
      postNumber,
      terms,
      email,
      fullName,
      parentEmail,
      is_ai_school,
      ageBelow18,
      ageAbove18,
      eventsCheck,
    } = data;
    var is_disabled = true;
    var gray_out = ["radio-description grayout", "grayout"];
    if (is_ai_school) {
      is_disabled = false;
      gray_out = ["radio-description", "", "", ""];
    }

    return (
      <div
        className={`content-login-wrapper team-content ${
          activeTab === "register" ? "active" : ""
        }`}
      >
        <div className="login-logo register">
          <img className="register" alt="KI-Kurs logo" src={logo} />
        </div>
        <div className="verlinkung-container mx-auto">
          <div className="primary-color-blue-foreground text-center bwki-text">
            Hier geht's stattdessen zu den KI-Wettbewerben:
          </div>

          <div className="verlinkung-wrapper">
            <a className="verlinkung" href="https://bw-ki.de/app/auth/">
              Deutschland
            </a>
            <a className="verlinkung" href="https://bwki.asai.ac.at/">
              Österreich
            </a>
            <a className="verlinkung" href="https://www.ki-wettbewerb.ch">
              Schweiz
            </a>
          </div>
        </div>

        {/* New UI changes adapting bootstrap */}
        <form className="reg-style-wrapper">
          <div className="row">
            <div className="form-group col-md-6">
              <input
                name="username"
                value={username}
                onChange={this.handleChange}
                type="text"
                placeholder={"Benutzername"}
                className={`form-control ${errors.username ? "invalid" : ""}`}
              />
              {errors.username && (
                <small className="form-text primary-color-blue-foreground mt-1">
                  {errors.username}
                </small>
              )}
            </div>

            <div className="form-group col-md-6">
              <input
                name="password"
                value={password}
                onChange={this.handleConfirmPasswordChange}
                type="password"
                placeholder="Passwort"
                className={`form-control ${errors.password ? "invalid" : ""}`}
              />
            </div>
          </div>
          <div className="row">
            <div className="form-group col-md-6">
              <input
                name="email"
                value={email}
                onChange={this.handleChange}
                type="email"
                placeholder="Email"
                className={`form-control ${errors.email ? "invalid" : ""}`}
              />
            </div>
            <div className="form-group col-md-6">
              <input
                name="confirmPassword"
                value={confirmPassword}
                onChange={this.handleConfirmPasswordChange}
                type="password"
                placeholder="Passwort wiederholen"
                className={`form-control ${
                  errors.confirmPassword ? "invalid" : ""
                }`}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-3">
              <h6>Bist du unter 18?</h6>
            </div>
            <div className="form-check-inline">
              <label htmlFor="ageBelow18" className="form-check-label">
                <input
                  type="radio"
                  id="ageBelow18"
                  name="ageBelow18"
                  checked={ageBelow18}
                  value={ageBelow18}
                  onChange={this.handleAgeBelow18Change}
                  className="form-check-input"
                />
                Ja
              </label>
            </div>
            <div className="form-check-inline">
              <label htmlFor="ageAbove18" className="form-check-label">
                <input
                  type="radio"
                  id="ageAbove18"
                  name="ageAbove18"
                  checked={ageAbove18}
                  value={ageAbove18}
                  onChange={this.handleAgeAbove18Change}
                  className="form-check-input"
                />
                Nein
              </label>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              {data.showParent && (
                <p className="olderWarning">
                  Dann benötigen wir die Zustimmung deiner Eltern für deine
                  Teilnahme am Kurs.
                </p>
              )}
            </div>
          </div>
          <div className="row mt-3">
            <div className="form-group col-md-6">
              <input
                name="parentEmail"
                value={parentEmail}
                onChange={this.handleChange}
                type="text"
                disabled={!data.showParent}
                placeholder="Email des Erziehungsberechtigten"
                className={`form-control ${
                  errors.parentEmail ? "invalid" : ""
                }`}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 mt-3 checkbox-wrapper">
              <div className="chechbox-terms">
                <label className="switch">
                  <input
                    type="checkbox"
                    value={is_ai_school}
                    id="is_ai_school"
                    name="is_ai_school"
                    checked={is_ai_school}
                    onChange={this.handleIsAISchool}
                  />
                  <span className="slider round"></span>
                </label>
                <span>
                  Wenn du am Rennen um den Titel der
                  <a href="#" onClick={this.props.showPanel.bind(this)}>
                    {" "}
                    KI-Schule des Jahres{" "}
                  </a>
                  teilnehmen möchtest, klicke hier und fülle die folgenden
                  Felder aus.
                </span>
              </div>
              <div>
                <span className="primary-color-blue-foreground">
                  <b>
                    Achtung für alle SchweizerInnen: Die KI-Schule des Jahres
                    kann in diesen Jahr noch nicht in der Schweiz ausgelost
                    werden
                  </b>{" "}
                </span>
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="form-group col-md-6">
              <input
                name="fullName"
                value={fullName}
                onChange={this.handleChange}
                type="text"
                placeholder="Vor- & Nachname"
                className={`form-control ${errors.fullName ? "invalid" : ""}`}
                disabled={is_disabled}
              />
            </div>
          </div>
          <div className="row"></div>
          <div className="row">
            <div className="col-md-3">
              <h6 className={gray_out[0]}>Ich bin</h6>
            </div>
            <div className="col-md-12">
              <div className="form-check-inline">
                <label
                  htmlFor="student"
                  className={`form-check-label ${gray_out[1]}`}
                >
                  <input
                    type="radio"
                    id="student"
                    name="student"
                    checked={student}
                    value={student}
                    onChange={this.handleIsStudentChange}
                    className="form-check-input"
                    disabled={is_disabled}
                  />
                  SchülerIn
                </label>
              </div>
              <div className="form-check-inline">
                <label
                  htmlFor="teacher"
                  className={`form-check-label ${gray_out[1]}`}
                >
                  <input
                    type="radio"
                    id="teacher"
                    name="teacher"
                    checked={teacher}
                    value={teacher}
                    onChange={this.handleIsTeacherChange}
                    className="form-check-input"
                    disabled={is_disabled}
                  />
                  Lehrer:in
                </label>
              </div>
              {!is_ai_school && (
                <div className="form-check-inline">
                  <label
                    htmlFor="none"
                    className={`form-check-label ${gray_out[1]}`}
                  >
                    <input
                      type="radio"
                      id="none"
                      name="none"
                      checked={others}
                      value={others}
                      onChange={this.handleIsOthersChange}
                      className="form-check-input"
                      disabled={is_disabled}
                    />
                    keins davon
                  </label>
                </div>
              )}
            </div>
          </div>
          {others && (
            <div className="row mt-2">
              <div className="form-group col-md-6">
                <input
                  name="postNumber"
                  value={postNumber}
                  onChange={this.handleChange}
                  type="text"
                  placeholder="PLZ"
                  className={`form-control ${
                    errors.postNumber ? "invalid" : ""
                  }`}
                  disabled={is_disabled}
                />
              </div>
            </div>
          )}
          {!others && (
            <div className="row mt-2">
              <div className="col-md-6 form-group">
                <input
                  list="schools"
                  name="school"
                  value={school}
                  onChange={this.handleChange}
                  type="text"
                  placeholder={"Schule"}
                  className={`form-control ${errors.school ? "invalid" : ""}`}
                  disabled={is_disabled}
                />
                <datalist id="schools">{optionsHTML}</datalist>
              </div>
              <div className="col-md-6">
                <small className="form-text primary-color-blue-foreground form-text">
                  Deine Schule ist nicht in der Liste? Wähle sonstige und tippe
                  den Namen der Schule nach der Anmeldung im Benutzerprofil ein.
                </small>
              </div>
            </div>
          )}
          <div className="row mt-2">
            <div className="col-md-12">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  onChange={this.handleChange}
                  value={eventsCheck}
                  name="eventsCheck"
                />
                <label className="form-check-label" htmlFor="check-events">
                  Ja, ich möchte zu weiteren Aktivitäten des BWKI informiert
                  werden
                </label>
              </div>
            </div>
          </div>
          <div className="row mt-2">
            {/* <div className='col-md-6 '>
                            <ReCAPTCHA ref={this.recaptchaRef} sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY} onChange={e => this.handleCaptcha(e, 'captcha')} onExpired={e => this.handleCaptcha('', 'captcha')} />
                        </div> */}
            <div className="col-md-1">
              <div className="mt-1 checkbox-wrapper">
                <div className="chechbox-terms">
                  <label className="switch">
                    <input
                      name="terms"
                      value={terms}
                      onChange={this.handleChange}
                      type="checkbox"
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>
            </div>
            <div className="col-md-9 ml-2">
              <small className="text-justify">
                Mit deiner Anmeldung erkennst du unsere{" "}
                <a
                  href="https://www.bw-ki.de/datenschutz"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Datenschutzbedingungen
                </a>{" "}
                und die{" "}
                <a
                  href="https://www.bw-ki.de/teilnahmebedingungen"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Teilnahmebedingungen
                </a>{" "}
                an
              </small>
            </div>
          </div>
        </form>
        {registerError && (
          <div className="error text-center">
            <span className="text-danger">{registerError}</span>
          </div>
        )}
        {/* { olderWarning && <p className='olderWarning'>Personen über 20 Jahren können sich zwar registrieren und die Web-Applikation für Lernzwecke nutzen, werden aber in dem Bundeswettbewerb selbst nicht berücksichtigt.</p>}
                {youngerWarning && <p className='olderWarning'>Du musst mindestens 13 Jahre alt sein, um dich registireren zu können.</p>} */}
        <div className="button-login register">
          <button
            type="button"
            disabled={loading ? true : this.validate()}
            onClick={this.submitForm}
          >
            Jetzt mitmachen
          </button>
        </div>
      </div>
    );
  }
}

export default Register;
