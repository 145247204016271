import React, { Component } from "react";
import authService from "../../services/authService";
import { Link, Redirect } from "react-router-dom";
import * as alert from "../../alert.json";
import Button from "../common/button";
import classService from "../../services/classService";

class VirtualClassroom extends Component {
  state = {
    isStudent: false,
    showAlert: false,
    showCountdown: true,
    countdown: "",
    showSchoolCountdown: true,
    schoolCountdown: "",
    showPanel: false,
    showInfoPanel: false,
    panel: 1,
    classrooms: [],
  };

  componentDidMount() {
    const isStudent = authService.isUserStudent();
    let classrooms = "";
    classService
      .getClasses()
      .then(({ data }) => {
        this.setState({
          isStudent: isStudent,
          classrooms: data,
        });
        classrooms = [...data];
      })
      .catch((err) => {
        let error = "Es ist ein unerwarteter Fehler aufgetreten.";
        if (err.response && err.response.status === 400) {
          error = err.response.data.error;
        } else if (err.response && err.response.status === 403) {
          error = err.response.data.error;
        }
        this.setState({
          isStudent: isStudent,
        });
      });
  }

  removeAlert = () => {
    this.setState({
      showAlert: false,
    });
  };

  toggleModal = (item) => {
    this.setState({
      showPanel: !this.state.showPanel,
      panel: 1,
    });
  };

  toggleInfoModal = (item) => {
    this.setState({
      showInfoPanel: !this.state.showInfoPanel,
      panel: 1,
    });
  };

  handleClassClick = (e, index) => {
    let classrooms = this.state.classrooms;
    let classroom = classrooms[index];
  };
  render() {
    const { showPanel, panel, isStudent, showInfoPanel } = this.state;
    const user = authService.getCurrentUser();
    let viewMode = true;
    // if (!user && viewMode) this.props.history.push('/auth')
    if (user) viewMode = false;
    if (isStudent) return <Redirect to="/not-found" />;

    return (
      <React.Fragment>
        <section className="content homepage">
          <div>
            <h1 className="homepage header1 font-main-header text-center">
              Virtueller Klassenraum Überblick
            </h1>
          </div>
          <div className="container-fluid home-wrapper text-center">
            <div className="homepage header2">
              <p>
                Als LehrerIn kann man beliebig viele Klassenräume anlegen,
                SchülerInnen aufnehmen und anschließend den Lernfortschritt
                verfolgen. Die SchülerInnen können dem Klassenraum mit einem
                Klassencode und einem Benutzernamen, die im folgenden Schritt
                angelegt werden, beitreten.
              </p>
            </div>
            <div className="btn-group-vertical btn-group-lg">
              <div>
                <Button
                  className="btn button-room-bold my-2"
                  label="Neuen Klassenraum anlegen"
                  href={"/classroom/new-room"}
                ></Button>
              </div>

              <div>
                <Button
                  className="btn button-room-bold my-2"
                  label="Meine Klassenräume"
                  onClick={this.toggleModal}
                ></Button>
              </div>
              <br />
            </div>

            {showPanel && (
              <div className="container-fluid">
                <div className="text-center">
                  <div className="navigator back"></div>
                  <div className="container-room">
                    <div className="list-group text-center">
                      {this.state.classrooms.map((classroom, index) => (
                        <Link
                          className="list-group-item list-group-item-primary list-group-item-action button-room"
                          to={`/classroom/overview/code=${classroom.class_code}`}
                        >
                          {classroom.room_name}
                        </Link>
                      ))}
                    </div>
                    <div className="close" onClick={this.toggleModal}></div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default VirtualClassroom;
