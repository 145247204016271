import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Markdown from "../common/markdown";
import { women } from "./pioneer-women.json"
import authService from "../../services/authService";

function PioneerWomenTimeline(props) {

  useEffect(() => {
    let user = authService.getCurrentUser()
    if (!user) {
      props.history.push({ pathname: '/auth', state: { prevPath: props.location.pathname } })
    }

    return () => { }
  }, [])
  const [selectedItem, setSelectedItem] = useState();
  const [showSources, setShowSources] = useState(false);
  const unselectedOpacity = 0.4;
  const selectedOpacity = 1.0;
  const prevButtonLink = "/milestones/pioneer-women";
  const nextButtonLink = "/milestones/pioneer-women/quiz";

  let portrait_image_url = "/images/pioneer-women/empty_portrait.svg";
  let nameplate_image_url = "/images/pioneer-women/empty_nameplate.svg";

  let text =
    "Welche Pionierfrau steckt hinter welcher Errungenschaft? Klicke unten auf der Zeitleiste auf ein Objekt, um mehr über die jeweilige Pionierin und ihre Arbeit zu erfahren.";

  let geometryData = {
    allen: { x: 3153.945, y: 41.091, width: 421.03, height: 480.939 },
    eniac: { x: 1428.285, y: 7.388, width: 297.651, height: 514.641 },
    clarke: { x: 1937.08, y: 96.218, width: 305.012, height: 425.811 },
    feinler: { x: 6550.45, y: 34.684, width: 250.01, height: 487.345 },
    goldberg: { x: 6171.717, y: 34.556, width: 348.001, height: 486.766 },
    granville: { x: 3760.988, y: 19.785, width: 341.17, height: 502.244 },
    hamilton: { x: 5639.434, y: 12.935, width: 365.003, height: 509.094 },
    hopper: { x: 966.94, y: 8.057, width: 261.441, height: 513.266 },
    keller: { x: 4668.562, y: 90.223, width: 321.403, height: 431.806 },
    lamarr: { x: 2402.107, y: 41.684, width: 230.722, height: 479.638 },
    li: { x: 8133.923, y: 77.209, width: 281.079, height: 444.113 },
    lovelace: { x: 32.395, y: 15.912, width: 381.569, height: 504.288 },
    vonluxburg: { x: 8928.232, y: 73.331, width: 258.0, height: 447.992 },
    morik: { x: 6993.284, y: -0.001, width: 237.005, height: 521.324 },
    murati: { x: 9897.126, y: 43.163, width: 310.217, height: 478.867 },
    nwakanma: { x: 8581.488, y: 21.486, width: 196.714, height: 499.837 },
    picard: { x: 7742.804, y: 61.33, width: 255.702, height: 459.993 },
    rhodes: { x: 2765.762, y: 38.864, width: 298.411, height: 482.459 },
    spaerckjones: { x: 4202.692, y: 87.555, width: 293.845, height: 433.768 },
    wilkes: { x: 5122.051, y: 130.961, width: 385.272, height: 391.069 },
    schmid: { x: 9384.669, y: 32.885, width: 275.044, height: 489.144 },
    perlman: { x: 7347.011, y: 20.127, width: 259.316, height: 501.196 }
  };

  const get_pedestal_image_url = key => {
    return `/images/pioneer-women/${key}_object.svg`;
  };

  const onItemClick = event => {
    if (selectedItem) {
      selectedItem.setAttribute("opacity", unselectedOpacity);
    }
    event.currentTarget.setAttribute("opacity", selectedOpacity);
    setSelectedItem(event.currentTarget);
  };

  if (selectedItem) {
    let id = selectedItem.getAttribute("id");
    text = women.filter((woman) => woman["id"] == id)[0]["description"];
    portrait_image_url = `/images/pioneer-women/${id}_portrait.svg`;
    nameplate_image_url = `/images/pioneer-women/${id}_nameplate.svg`;
  }
  // console.log(portrait_image_url);
  const openSources = () => {
    setShowSources(true);
  }
  const closeSources = () => {
    setShowSources(false);
  }

  return (
    <div
      className="content pioneer-women-timeline"
    >
      <div className="pioneer-women-timeline" id="museum-frames">
        <object className="pioneer-women-timeline" id="woman-portrait" data={portrait_image_url} type="image/svg+xml">
          <img src={portrait_image_url} />
        </object>
        <object className="pioneer-women-timeline" id="woman-nameplate" data={nameplate_image_url} type="image/svg+xml">
          <img src={nameplate_image_url} />
        </object>
        <div className="pioneer-women-timeline" id="text-frame-background">
          <div className="pioneer-women-timeline" id="text-frame-content"
          >
            <Markdown children={text} />
          </div>
        </div>
      </div>
      <div className="pioneer-women-timeline horizontal-scroll">
        <div className="pioneer-women-timeline" id="scrollable-timeline">
          <svg
            id="svg107985"
            clip-rule="evenodd"
            fill-rule="evenodd"
            stroke-miterlimit="1"
            version="1.1"
            viewBox="0 0 10242 585"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g
              id="layer1"
              fill="none"
              opacity="1"
              stroke="#000"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="3.6472"
            >
              <image
                id="timeline"
                x="0"
                y="518.121"
                width="10241.775"
                height="66.688"
                href="/images/pioneer-women/timeline_without_objects.svg"
              />
              {Object.entries(geometryData).map(([k, v]) => {
                return (
                  <image
                    onClick={onItemClick}
                    opacity={unselectedOpacity}
                    id={k}
                    x={v["x"]}
                    y={v["y"]}
                    width={v["width"]}
                    height={v["height"]}
                    href={get_pedestal_image_url(k)}
                  />
                );
              })}
            </g>
          </svg>
        </div>
      </div>
      <div className="submit-parent-wrapper">
        <div className="pioneer-women-timeline sources-icon">
          <img onClick={openSources} src="/images/memory/sources_icon.svg" />
        </div>
        <div className="button-wrapper">
          <Link to={prevButtonLink}>
            <button className="firstBtn">Zurück</button>
          </Link>
          <Link to={nextButtonLink}>
            <button className="thirdBtn">Zum Quiz</button>
          </Link>
        </div>
      </div>

      {showSources &&
        <div className="overlay-panel-container">
          <div className="overlay-panel">
            <div className="close" style={{ gridRow: "1" }} onClick={closeSources}></div>
            <div className="definition-header" style={{ gridRow: "1" }}>Quellenangaben</div>
            <div className="section-wrapper" style={{ gridRow: "2" }}>
              {women.map((person) => (
                person.sourceinfo && person.sourceinfo.map((source) => (

                  <div className="section">
                    <p>
                      <span className="section-title">{person.name}</span>:
                      {source.license_url == '' ? <span>{source.license_type}</span> : <span><a target="_blank" href={source.license_url}>{source.license_type}</a></span>}
                      {source.source_name && <a target="_blank" href={source.source_url}>{source.source_name}</a>}
                      {source.image_url && <span> &ndash; <a target="_blank" href={source.image_url}>{person.name}</a></span>}, neu gezeichnet
                    </p>
                  </div>
                ))
              ))}
            </div>
          </div>
        </div>
      }
    </div>
  );
}

export default PioneerWomenTimeline;
