import React, { Component } from "react";
import authService from "../services/authService";
import { Link } from "react-router-dom";
import * as alert from "../alert.json";

class programming extends Component {
  state = {
    subPaths: [],
  };

  componentDidMount() {
    let subPaths = [
      {
        title: "Einstieg Programmierung",
        description:
          "Hier lernst du schrittweise die Grundlagen der Programmierung in Python – die Basics für dein erstes KI-Projekt.",
        subHeader: "KI-Grundlagen",
        link: "/programming/intro",
        image: "python.svg",
      },
      {
        title: "Tutorials",
        description:
          "Lerne in anwendungsbezogenen Tutorials, wie man ein KI-Programm entwickelt und es in einen Prototypen umsetzen kann.",
        subHeader: "Baue deine erste KI",
        link: "/programming/tutorials",
        image: "tutorials.svg",
      },
      {
        title: "Übungsplatz",
        description:
          "Teste deine erlernten KI-Skills und versuche dich an zahlreichen Programmieraufgaben oder deinem ersten eigenen KI-Programm.",
        subHeader: "Wende dein KI-Wissen an",
        link: "/programming/practice",
        image: "uebungsplatz.svg",
      },
      // {
      //     title:"Tutorials",
      //     description:"Wendet eure Programmierkenntnisse an, baut eure erste KI und entwickelt erste Prototypen zur Anwendung.",
      //     subHeader:"Baue deine erste KI",
      //     link:"/programming/tutorials",
      //     image:"tutorials.svg"
      // }
    ];

    this.setState({ subPaths: subPaths });
  }

  removeAlert = () => {
    this.setState({
      showAlert: false,
    });
  };
  render() {
    const user = authService.getCurrentUser();
    let viewMode = true;
    // if (!user && viewMode) this.props.history.push('/auth')
    if (user) viewMode = false;
    return (
      <React.Fragment>
        <section className="content">
          {/* <h1 className="homepage header"></h1>
                    <h3 className="homepage sub-header"></h3> */}

          <div className="container-fluid home-wrapper">
            <div className="subpath-tiles">
              {this.state.subPaths.length > 0 &&
                this.state.subPaths.map((subPath, index) => (
                  <Link key={index} to={subPath.link} className="subpath-tile">
                    <div className="tile-wrapper">
                      <img
                        className="image"
                        src={`/images/icons/subpaths/programming/${subPath.image}`}
                      />
                      <div className="row2 primary-color-lightblue-background">
                        <div className="tile-sub-header">
                          {subPath.subHeader}
                        </div>
                        <div className="tile-header">{subPath.title}</div>
                        <div className="tile-description">
                          {subPath.description}
                        </div>
                      </div>
                    </div>
                  </Link>
                ))}
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default programming;
