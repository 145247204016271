import React from "react";
import Form from "../common/form";
import Button from "../common/button";
import classService, { generateCode } from "../../services/classService";

class AddStudents extends Form {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      errors: [],
      success: "",
      classroom: { room_name: "", code: "" },
      students: [
        {
          fullname: "",
          identifier: "",
          username: "",
          toggleRemove: false,
        },
      ],
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      classroom: {
        room_name: nextProps.response.room_name,
        code: nextProps.response.code,
      },
    });
  }

  componentDidMount() {
    if (this.props.response.room_name) {
      console.log(this.props);
      this.setState({
        classroom: {
          room_name: this.props.response.room_name,
          code: this.props.response.code,
        },
      });
    }
  }

  genStudentUsername = (event, index) => {
    let students = [...this.state.students];
    let student = students[index];
    let fullname = event.target.value;
    if (!student.identifier) student.identifier = generateCode(3, "n");
    student.fullname = fullname;
    student.username =
      fullname
        .split(/\s/)
        .reduce((response, word) => (response += word.slice(0, 5)), "")
        .toLowerCase() + student.identifier;
    this.setState({ students });
  };

  addStudent = (event, index) => {
    let students = this.state.students.concat([
      {
        fullname: "",
        identifier: "",
        username: "",
        classcode: "",
        toggleRemove: false,
      },
    ]);
    let student = students[index];
    student.toggleRemove = true;
    // console.log(student)
    const classcode = this.state.classroom.code;

    // TODO move classcode away from student
    classService
      .registerStudent(student.username, student.fullname, classcode)
      .then(
        this.setState({
          students,
        })
      )
      .catch((err) => {
        let error = "Es ist ein unterwarter Fehler aufgetreten.";
        if (err.response && err.response.status === 400) {
          error = err.response.data.error;
          console.log("Generating new username");
          student.identifier = generateCode(3, "n");
          student.username =
            student.fullname.replace(" ", "").toLowerCase() +
            student.identifier;
          return this.addStudent(0, index);
        }
        this.setState({
          errors: this.state.errors.concat([error]),
        });
      });
  };

  updateUsername = (event, index) => {
    let students = [...this.state.students];
    let student = students[index];
    let username = event.target.value;
    if (!student.identifier) student.identifier = generateCode(3, "n");
    student.username = username;
    this.setState({ students });
  };

  removeStudent = (e, index) => {
    const classcode = this.state.classroom.code;
    let studentsList = this.state.students;
    let student = studentsList[index];

    classService
      .removeStudent(classcode, student.username)
      .then(() => {
        var backupStudents = [...studentsList];
        if (studentsList.length == 1) {
          studentsList = studentsList.concat([
            {
              fullname: "",
              identifier: "",
              username: "",
              classcode: "",
              toggleRemove: false,
            },
          ]);
        }
        studentsList.splice(index, 1);
        this.setState({
          students: studentsList,
        });
      })
      .catch((err) => {
        let error = "Es ist ein unterwarter Fehler aufgetreten.";
        if (err.response && err.response.status === 400) {
          error = err.response.data.error;
        }
        this.setState({
          errors: this.state.errors.concat([error]),
        });
      });
  };

  render() {
    const { data, errors, showPanel, success, classcode, students } =
      this.state;
    return (
      <React.Fragment>
        {this.state.students.map((student, index) => (
          <div className="form-row col-md-12" key={index}>
            <div className="form-group col-md-4">
              <input
                className="form-control"
                name="fullname"
                value={student.fullname}
                placeholder="Name des Schülers"
                type="text"
                onChange={(e) => this.genStudentUsername(e, index)}
                disabled={student.toggleRemove}
              />
            </div>
            <div className="form-group col-md-4">
              <input
                className="form-control"
                name="studentusername"
                value={student.username}
                placeholder="Benutzername"
                type="text"
                onChange={(e) => this.updateUsername(e, index)}
                disabled={student.toggleRemove}
              />
            </div>
            <div
              className="form-group col-md-4 text-center mt-auto"
              hidden={student.toggleRemove ? true : false}
            >
              <Button
                onClick={(e) => this.addStudent(e, index)}
                className="btn btn-primary my-b width-auto"
                label="Schüler bestätigen"
                disabled={student.username ? false : true}
              ></Button>
            </div>
            <div
              className="form-group col-md-4 text-center mt-auto"
              hidden={student.toggleRemove ? false : true}
            >
              <Button
                onClick={(e) => this.removeStudent(e, index)}
                className="btn btn-primary my-b width-auto"
                label="Schüler löschen"
              ></Button>
            </div>
          </div>
        ))}
      </React.Fragment>
    );
  }
}

export default AddStudents;
