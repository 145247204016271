import React, { Component } from 'react';
import authService from '../services/authService';
import { Link } from 'react-router-dom';
import * as alert from '../alert.json';

class milestones extends Component {
    state = {
        subPaths:[]
       
    }

    componentDidMount() {

        let subPaths = [{
            title:"Definition",
            description:"Hier klären wir, was sich hinter dem Begriff KI versteckt und helfen euch ein Gefühl dafür zu entwickeln, wie sich KI einen Weg in unseren Alltag gebahnt hat.",
            subHeader:"Was ist KI?",
            link:"/milestones/definition",
            image:"definition.svg"
        },
        {
            title:"Meilensteine",
            description:"Wir nehmen euch mit auf eine Reise durch die Geschichte der KI. Wer wann welchen Beitrag zu der Entwicklung der KI geleistet hat, könnt ihr hier erfahren.",
            subHeader:"Wann, Wer, Was?",
            link:"/milestones/memory",
            image:'milestone.svg'
            // image:"milestone.svg"
        },
        {
            title:"IT-Pionierfrauen",
            description:"Besucht unsere Ausstellung einiger bekannter Pionierinnen aus dem MINT-Bereich, die mit ihren Errungenschaften noch heute unsere Gesellschaft prägen.",
            subHeader:"Frauenpower!",
            link:"/milestones/pioneer-women",
            image:"pioneer-women.svg"
        }]

        this.setState({subPaths:subPaths})
    
    }

    removeAlert = () => {
        this.setState({
            showAlert: false
        })
    }
    render() {
        const user = authService.getCurrentUser();
        let viewMode=false;
        // if (!user) this.props.history.push('/auth')
        if (user)viewMode=true;
        return (
            <React.Fragment>
                <section className="content">

                    {/* <h1 className="homepage header"></h1>
                    <h3 className="homepage sub-header"></h3> */}

                    <div className="container-fluid home-wrapper">
                        <div className="subpath-tiles">
                        
                            {this.state.subPaths.length > 0 && this.state.subPaths.map( (subPath,index) => (
                                <Link to={subPath.link} key={index} className="subpath-tile">
                                    <div className="tile-wrapper">
                                        <img className="image negative-margin" src={`/images/icons/subpaths/milestones/${subPath.image}`}/>
                                        <div className="row2 primary-color-blue-background">
                                            <div className="tile-sub-header">{subPath.subHeader}</div>
                                            <div className="tile-header">{subPath.title}</div>
                                            <div className="tile-description">{subPath.description}</div>
                                        </div>
                                    </div>
                                </Link>))
                            }
                                               
                          
                        </div>
                    </div>

                    
                </section>
            </React.Fragment>
        );
    }
}

export default milestones;
