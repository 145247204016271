import React, { Component } from 'react';
import authService from '../../services/authService';
import { Link } from 'react-router-dom';
import memorys from '../../json/memory.json'
import questionService from '../../services/questionService';

class MemoryTimeline extends Component {
    state = {
        showMemoryDescription:true,
        timelines: [],
        memory:null
    }

    componentDidMount() {
    const user = authService.getCurrentUser();
        if (!user) this.props.history.push('/auth');

        questionService.getMultipleQuestionsByType('milestones_memory').then(({ data: response }) => {
            var finishedList=[];
            var quizList = response.filter(response => response.quiz_id == 6 || (response.quiz_id == 1 && response.num_attempts > 2)) ;
            quizList.forEach(entry => finishedList.push(entry.chapter_id));
            this.setState({timelines: finishedList})
        });
    }

    openMemory = (memoryItem) => {
            this.setState({
                showMemoryDescription:true,
                memory:{
                    title:memoryItem.name,
                    image:memoryItem.image_2
                }
            })
    }

    closeMemory = () =>{
        this.setState({
            showMemoryDescription:false,
            memory:null
        })
    }

    toMilestone = () => {
        this.props.history.push({
          pathname: "/milestones/memory",
        })
       }

    render() {
        const {memory,timelines, showMemoryDescription} = this.state;

        console.log(timelines);

        return (
            <React.Fragment>
                <section className="main-content">

                        {/* {memorys.allMemorys && memorys.allMemorys.map( (memoryItem,index) => */}
                        {memorys.allMemorys && memorys.allMemorys.filter(memory => timelines.includes(memory.chapter.toString())).map( (memoryItem,index) =>

                             {
                                return (
                                <>
                                <div className="memory-timeline">
                                <img className="" src={`/images/memory/${memoryItem.timeline_image}`}/>

                                <div className="tiles">
                                {memoryItem.pairs && memoryItem.pairs.map((memoryImage, i) =>
                                {
                                return  (
                                <img key={i} onClick={this.openMemory.bind(this,memoryImage)} src={`/images/memory/${memoryImage.image_1}`}/>
                                )
                                })
                                }
                                </div>
                                </div>
                                </>

                                )
                             })
                        }
                        <div className='timeline-bottom'>
                            <button className='memory-game-button' onClick={this.toMilestone}> Zurück </button>
                        </div>
                </section>

                {this.state.showMemoryDescription && this.state.memory &&
                    <div className="overlay-panel-container">
                        <div className="overlay-panel">
                            <div className="close" style={{gridRow:"1"}} onClick={this.closeMemory}></div>
                            <div className="definition-header" style={{gridRow:"1"}}>{memory.title}</div>
                            <div className="section-wrapper memory-tile" style={{gridRow:"2"}}>
                            <img className="" src={`/images/memory/${memory.image}`}/>
                            </div>
                        </div>
                    </div>}

            </React.Fragment>
        );
    }
}

export default MemoryTimeline;